import React from 'react';

const JobCopilotTestimonial = ({ quote, authorName, authorPosition, authorImage }) => {
  return (
    <div className="testimonial-wrapper">
      <div className="testimonial">
        <div className="quote">
          {quote}
        </div>
        <div className="author">
          <div className="avatar">
            <img src={authorImage} alt={authorName} />
          </div>
          <div className="info">
            <h5>{authorName}</h5>
            <p>{authorPosition}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCopilotTestimonial; 