import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import * as FormatTools from '../../utils/format';
import { REDUCED_HOURS_LOOKUP_2 } from '../../utils/filters';

class CompanyHeader extends React.Component {

    render() {

        var { company, jobs } = this.props;

        var { response: companyData } = company;

        // Tags
        var reduced = ""

        if (companyData.reduced_hours_description) {
            reduced = <li className={""}>{companyData.reduced_hours_description}</li>
        } else if (companyData.reduced_hours == "Offered") {
            reduced = <li className={"offered"}>4 {companyData.hours_full_time ? " x " + Math.round(companyData.hours_full_time / 4.0) + "hr" : ""} days</li>
        } else if (companyData.reduced_hours == "Offered 4.5 days") {
            reduced = <li className={"offered-4-5-days"}>Half day Fridays</li>
        } else if (companyData.reduced_hours == "Offered every 2nd week") {
            reduced = <li className={"offered-every-2nd-week"}>4 day week every 2nd week</li>
        } else if (companyData.reduced_hours == "Offered summer") {
            reduced = <li className={"offered-summer"}>4 day week during Summer</li>
        } else if (companyData.reduced_hours == "Generous PTO") {
            reduced = <li className={"generous-pto"}>5 day week ({companyData.vacation ? companyData.vacation + " days " : "amazing"} PTO)</li>
        } else if (companyData.reduced_hours == "Flex Fridays") {
            reduced = <li className={"flex-fridays"}>5 day week (flex fridays)</li>
        } else if (companyData.reduced_hours == "Unlimited PTO") {
            reduced = <li className={"unlimited-pto"}>5 day week (unlimited PTO)</li>
        }  else if (companyData.reduced_hours == "Flexible hours") {
            reduced = <li className={"flexible-hours"}>5 day week (very flexible hours)</li>
        }  else if (companyData.reduced_hours == "Would consider") {
            reduced = <li className={"would-consider"}>4 days @ 80% salary</li>
        } else if (companyData.reduced_hours == "9 day fortnight") {
            reduced = <li className={"nine-day-fortnight"}>9 day fortnight</li>
        } else if (companyData.reduced_hours == "Part time") {
            reduced = <li className={"part-time"}>Open to part time</li>
        }


        var cur_employees = "";

        if (companyData.employees) {

            if (companyData.employees > 1000) {
                cur_employees = "1,000+";
            } else if (companyData.employees > 500) {
                cur_employees = "500-1,000";
            } else if (companyData.employees > 500) {
                cur_employees = "100-500";

            } else if (companyData.employees > 100) {
                cur_employees = "100-500";

            } else if (companyData.employees > 50) {
                cur_employees = "50-100";

            } else if (companyData.employees >= 10) {
                cur_employees = "10-50";

            } else if (companyData.employees >= 7) {
                cur_employees = "~10";
            } else {
                cur_employees = "";
            }
        }

        var jobsBtn = "";

        var openJobs = companyData.num_live_jobs;
        /*
                if (jobs && !jobs.loading && jobs.itemIds) {
                    for (var i = 0; i < jobs.itemIds.length; i++) {
                        if (!jobs.items[jobs.itemIds[i]].is_expired) {
                            openJobs++;
                        }
                    }
                }*/

        var remote = "";
        if (companyData.remote_level == "100% remote") {
            remote = <li className='company-remote'>100% remote</li>;
        } else if (companyData.remote_level == "Offers remote") {
            remote = <li className='company-remote'>Hybrid remote</li>;
        } else {
            remote = <li className='company-remote'>Onsite</li>;
        }

        let hq = "";
        if (companyData.info && companyData.info.city && companyData.info.country) {
            hq = <li className='company-hq'><FontAwesomeIcon icon={faMapMarkerAlt} /> {companyData.info.city}, {companyData.info.country}</li>;
        }

        /*    if (openJobs > 0) {
                jobsBtn = <li>
                    <button className='btn btn-link btn-md' onClick={() => document.querySelector('[id="remote-jobs"]').scrollIntoView()}>{openJobs + " open job" + (openJobs > 1 ? "s" : "")}</button>
                </li>
            }*/


        var shortDescription = companyData.short_description ? companyData.short_description : null;

        if (shortDescription) {
            if (!shortDescription.endsWith(".")) {
                shortDescription += ".";
            }

        }

        /*
        REDUCED_HOURS_LOOKUP_2
        */


        return (
            <div className="row  company-header">


                <div className="col-sm-9 cols company-header-left">
                    <div className='row'>
                        <ul className='breadcrumbs'>
                            <li><Link to={"/companies"}>Great work-life balance companies</Link></li>
                            <li><Link to={"/companies/" + FormatTools.Slugify(companyData.reduced_hours)}>{REDUCED_HOURS_LOOKUP_2[companyData.reduced_hours]}</Link></li>
                        </ul>

                    </div>
                    <div className='row header-top'>
                        {companyData.logo_url  ?
                            <LazyLoadImage
                                threshold={100}
                                height={40}
                                width={40}
                                alt={companyData.name}
                                src={companyData.logo_url}
                                className="company-image"
                            />
                            : ""}



                        <h1 className="company-name">{companyData.name}</h1>
                    </div>

                    {shortDescription ? <h2 className="company-desc">{shortDescription}</h2> : ""}




                    <ul className='tags tags-lg'>
                        {reduced}
                        {cur_employees ? <li>{cur_employees + " employees"}</li> : ""}
                        {remote}
                        {hq}
                        {jobsBtn}

                    </ul>
                </div >

                {openJobs > 0 ?
                    <div className="col-sm-3 cols company-header-right">
                        <button className='btn btn-huge btn-success margin-bottom' onClick={() => document.querySelector('[id="remote-jobs"]').scrollIntoView()}>View jobs...</button>
                    </div> : ""}
            </div>

        )
    }
}

export default CompanyHeader;