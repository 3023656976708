import React, { Component } from 'react';
import JobCopilotTestimonial from './JobCopilotTestimonial';

class JobCopilotWhyUse extends Component {
  render() {
    return (
      <div className="job-copilot-why-use">
        <div className="content">
          <h2>Why use our Job Copilot?</h2>
          <h6>Find better work-life balance jobs with a fraction of the effort.</h6>


          <div className="why-use-jobcopilot-items">
            <div className="why-use-jobcopilot-item">

              <div className="info">
                <h4>Get More Interviews.</h4>
                <div className="underline"></div>
                <p>
                  The average person needs to apply to <strong>50 – 100 jobs just to get 1 interview</strong> – that takes a lot of time
                  if you do it manually!
                </p>
                <p>
                  With our Job Copilot, you can send up to 50 job applications per day! That multiplies the number of
                  interviews you get every month.
                </p>
              </div>
            </div>

            <div className="why-use-jobcopilot-item">


              <div className="info">
                <h4>Never Miss an Opportunity.</h4>
                <div className="underline"></div>
                <p>
                  Your copilot looks for new jobs posted on job boards across the world (including 4DayWeek.io) and applies directly on the company career pages.
                </p>
                <p>
                  No more missing out on job opportunities or applying too late to be considered. Stop stressing about
                  finding newly posted jobs; your AI job copilot finds them for you!
                </p>
              </div>
            </div>

            <JobCopilotTestimonial 
              quote={<>I've been using the tool for 2 weeks now and have had <b>outstanding results</b>. I've already been contacted for <b>2 first-round interviews</b>. I can 100% say the tool works. There's no way I'd go job hunting again without it!</>}
              authorName="Zach Zumpano"
              authorPosition="Account Manager"
              authorImage="https://media.fourdayweek.io/files/zach.jpg"
            />

            <div className="why-use-jobcopilot-item">

              <div className="info">
                <h4>Auto-Apply to Suitable Jobs.</h4>
                <div className="underline"></div>
                <p>
                  You can configure your copilot to only apply to jobs you want by using a combination of multiple
                  filters and options. You can also tell your copilot to skip irrelevant roles.
                </p>
              </div>
            </div>

            <div className="why-use-jobcopilot-item">

              <div className="info">
                <h4>Save Hours Every Week.</h4>
                <div className="underline"></div>
                <p>
                  Tired of spending hours searching for jobs and filling repetitive forms? Let your copilot do that for
                  you. Your time is priceless—use it for what really matters.
                </p>
              </div>
            </div>

            <div className="why-use-jobcopilot-item">

              <div className="info">
                <h4>Apply with Confidence.</h4>
                <div className="underline"></div>
                <p>
                  4DayWeek Copilot exclusively applies to verified jobs on official company career pages. This ensures
                  you're only pursuing legitimate opportunities from real employers.
                </p>
                <p>No more worrying about fake listings—your job search is safe and secure with us.</p>
              </div>
            </div>
          </div>


        </div>
      </div>
    );
  }
}

export default JobCopilotWhyUse;
