import React from 'react';

import { Unslug, FormatNumber } from '../../utils/format';


class CompanyDepartments extends React.Component {

    render() {



        var { info } = this.props;

        if(!info || info.industry == "staffing & recruiting"){
            return "";
        }

        let { departmental_head_count, estimated_num_employees } = info;

        if (!departmental_head_count) {
            return "";
        }



        // departments

        const departments = Object.entries(departmental_head_count)
            .map(([name, num]) => ({ name, num }))
            .sort((a, b) => b.num - a.num);

        if (departments.length == 0) {
            return "";
        }

        let sum = 0;
        for (let i = 0; i < departments.length; i++) {
            sum += departments[i].num;
        }


        let extra = "";
        let extraCount = 0;
        let ret = <ul>{departments.map((d) => {

            if ((d.num / sum) < 0.03) {
                extraCount += 1;
                extra = <li>
                    <div className="department-bar" style={{ width: "3%" }}></div>
                    <div className="department-name">+{extraCount} more teams</div>
                    <div className="department-number"></div>
                </li>
                return "";
            }

            return (<li>
                <div className="department-bar" style={{ width: ((d.num / sum) * 100) + "%" }}></div>
                <div className="department-name">{Unslug(d.name)}</div>
                <div className="department-number">{d.num}</div>
            </li>);
        }
        )}
        {extra}
        </ul>;





        return (
            <div className="company-side-box company-remote ">
                <h3>Our Team</h3>
                
                {estimated_num_employees ? <p>We're a team of {estimated_num_employees ? FormatNumber(estimated_num_employees) :  ""} across {departments.length} departments:</p> : ""}

                {ret}
            </div>

        )
    }
}


export default CompanyDepartments;