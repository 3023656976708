
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as itemActions from '../../store/item/actions';
import * as listActions from '../../store/list/actions';
import { ConvertLookupToOptions, GenerateLookups, LEVELS_LOOKUP, LOCATIONS_LOOKUP, POPULAR_LOCATIONS_LOOKUP, REDUCED_HOURS_LOOKUP, REMOTE_OPTIONS_LOOKUP, RENAME_SKILLS } from '../../utils/filters';
import * as URLTools from '../../utils/url';
import LabelField from '../fields/LabelField';
import CoocList from './CoocList';

class JobFiltering extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lookups: GenerateLookups({
                location: LOCATIONS_LOOKUP,
                reduced_hour: REDUCED_HOURS_LOOKUP,
                remote_option: REMOTE_OPTIONS_LOOKUP,
                level: LEVELS_LOOKUP,
            }),
            inputValues: {
                locations:  ConvertLookupToOptions(POPULAR_LOCATIONS_LOOKUP).concat(ConvertLookupToOptions(LOCATIONS_LOOKUP)),
                reduced_hours: ConvertLookupToOptions(REDUCED_HOURS_LOOKUP),
                remote_options: ConvertLookupToOptions(REMOTE_OPTIONS_LOOKUP),
                levels: ConvertLookupToOptions(LEVELS_LOOKUP),
            },
            extra: []

        }
    }



    onSubmit(values) {
        if (values.reduced_hour && Array.isArray(values.reduced_hour) && values.reduced_hour.length > 0) {
            values.reduced_hour = values.reduced_hour.map(obj => obj.value).join(',');
        }


        // Prefix
        let prefix = "/jobs";
        if(values && values.remote_option){
            if(values.remote_option == "remote"){
                prefix = "/remote-jobs";
            } else if(values.remote_option == "onsite"){
                prefix = "/onsite-jobs";
            } else if(values.remote_option == "hybrid"){
                prefix = "/hybrid-jobs";
            }
        }


        let redir = URLTools.GenerateFiltersURL(values, prefix, ["remote_option"]);

        this.props.history.push(redir);
    }


    componentDidMount() {
        var { data, cooc } = this.props;
        if (data && !data.init) {
            this.props.getJobsFilters();
        }

        if(!cooc || !cooc.requested){
            this.props.getJobsCooc();
        }

    }



    render() {

        var { location, cooc } = this.props;
        let { lookups, inputValues, extra } = this.state;

        let defaults = URLTools.GetQueryParams(location.pathname + location.search, lookups, "filter");


        var jobOptions = [];

        let { data } = this.props;


        if (data && data.itemIds) {
            for (var i = 0; i < data.itemIds.length; i++) {
                var cur = data.items[data.itemIds[i]];
                var label = cur.label && RENAME_SKILLS[cur.value] ? cur.type == "category" ? "All " + RENAME_SKILLS[cur.value] : RENAME_SKILLS[cur.value] : cur.label;

                var typeIcon = "";
                if (cur.type == "category") {
                    typeIcon = <FontAwesomeIcon icon="sitemap" />;
                } else if (cur.type == "role") {
                    typeIcon = <FontAwesomeIcon icon="briefcase" />;
                } else if (cur.type == "filter") {
                    typeIcon = <FontAwesomeIcon icon="tag" />;
                }

                jobOptions.push({ 'label': <><span className="filter-type">{typeIcon} </span> {label} <span className="number">{cur.num}</span></>, 'value': cur.value });
            }

        } else {
            jobOptions.push({ 'label': <><span className="filter-type">{""} </span> loading</>, 'value': cur.value });
        }

        for(var i = 0; i < extra.length; i++){
            jobOptions.push({ 'label': <><span className="filter-type">{""} </span> {extra[i]} <span className="number">1</span></>, 'value': extra[i] });
        }


        return (
            <div className='row'>

                <div className="row filters job-filters">
                    <div className="row search">

                        <Form
                            onSubmit={(values) => this.onSubmit(values)}
                            mutators={{
                                ...arrayMutators,

                            }}
                            initialValues={defaults}
                            render={({ handleSubmit, submitting, submitted, errors, pristine, values, form, form: {
                                mutators: { push, pop }
                            } }) => (
                                <>
                                    <form onSubmit={handleSubmit} data-sparkloop-upscribe='false'>
                                        <div className="form-wrapper">

                                            <div className="row">

                                                <div className="col-sm-10 no-padding">

                                                    <div className='row'>
                                                        <div className="col-sm-7 no-padding skill">
                                                            <LabelField inputType="select" id="filter"

                                                                isClearable={true} singleCol={true} options={jobOptions}
                                                                isSearchable={true}
                                                                isCreatable={true}
                                                                placeholder={values && values.filter ? values.filter : "Search by job title or skill..."} />

                                                            <FontAwesomeIcon icon={"search"} className="search-icon" />

                                                            <OnChange name="filter">
                             
                                                                {(value, previous) => { 
                                                                    this.setState({ extra: this.state.extra.concat(value) });
                                                                    handleSubmit() 
                                                                
                                                                }}
                                                            </OnChange>

                                                        </div>

                                                        <div className="col-sm-5 no-padding location">
                                                            <LabelField inputType="select" id="location" label={<FontAwesomeIcon icon={"globe"} />}  singleCol={true} isClearable={true} options={inputValues.locations} placeholder={"Location..."} />

                                                            <OnChange name="location">
                                                                

                                                                {(value, previous) => { handleSubmit() }}
                                                            </OnChange>
                                                        </div>
                                                    </div>

                                                    <div className='row extra-filters'>
                                                        <LabelField inputType="multiselect" id="reduced_hour" singleCol={true} isClearable options={inputValues.reduced_hours} className="wide" placeholder={"Hours worked"} />
                                                        <OnChange name="reduced_hour">
                                                            {(value, previous) => { handleSubmit() }}
                                                        </OnChange>
                                                        <LabelField inputType="select"  id="remote_option" singleCol={true} isClearable options={inputValues.remote_options} placeholder={"Remote"} />
                                                        <OnChange name="remote_option">
                                                            {(value, previous) => { handleSubmit() }}
                                                        </OnChange>
                                                        <LabelField inputType="select"  id="level" singleCol={true} isClearable options={inputValues.levels} placeholder={"Seniority"} />
                                                        <OnChange name="level">
                                                            {(value, previous) => { handleSubmit() }}
                                                        </OnChange>
                                                    </div>




                                                </div>



                                                <div className="col-sm-2 no-padding buttons">
                                                    <button className="btn btn-success btn-huge cta">Search...</button>
                                                </div>

                                            </div>



                                        </div>

                                        <CoocList selected={values.filter} cooc={cooc} />

                                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                                    </form>
                                </>
                            )}
                        />

                    </div>


                </div>




            </div >
        );
    }
}





const mapStateToProps = (state) => {
    return {
        data: state.list.jobs_filters,
        cooc: state.item.cooc,
    };
};


const mapDispatchToProps = (dispatch, ownProps) => ({

    getJobsFilters: () => {
        dispatch(listActions.fetchItems("jobs", "filters"));
    },
    getJobsCooc: (data) => {
        dispatch(itemActions.getItem("jobs", data, "cooc", "cooc"));
    },
    resetItems: () => {
        dispatch(listActions.resetItems("jobs", ownProps.page));
    },
});

JobFiltering = connect(mapStateToProps, mapDispatchToProps)(JobFiltering);



export default withRouter(JobFiltering);