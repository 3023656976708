
import { withRouter } from 'react-router-dom';
import React from 'react';

class ScrollToTop extends React.Component {

    constructor() {
        super();
        this.state = {
            pages: ["/jobs", "/", ""], // Include home by default
            positions: {}
        };
    }

    componentWillUpdate(nextProps) {
        var lastPage = this.props.location.pathname
        var nextPage = nextProps.location.pathname;

        if (lastPage == nextPage) {
            return;
        }

        var top = document.body.scrollTop
        var temp = Object.assign({}, this.state);
        if (this.isValidPage(lastPage)) {
            temp.positions[lastPage] = top;
        }
        this.setState(temp);

    }

    componentDidUpdate(prevProps) {
        var { positions } = this.state;

        var lastPage = prevProps.location.pathname
        var nextPage = this.props.location.pathname;
        if (lastPage == nextPage || !this.isValidPage(nextPage) || (lastPage.startsWith("/jobs") && nextPage.startsWith("/jobs"))) {
            return;
        }

        if (this.props.history.action == "POP") {
            setTimeout(function () {
                document.body.scrollTop = positions[nextPage];
            }, 1);
        } else if (this.props.history.action == "PUSH") {
            document.body.scrollTop = 0
        }

    }

    isValidPage(page) {
        for (var i = 0; i < this.state.pages.length; i++) {
            let cur = this.state.pages[i];
            if (page == "/" || page.startsWith(cur)) {
                return true;
            }
        }
        return false;
    }


    render() {
        return this.props.children
    }
}

export default withRouter(ScrollToTop)