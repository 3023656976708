import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import posthog from 'posthog-js';

const vibrantColors = [
    "#EF2D56", "#007905", "#007303", "#d22f2a", "#c91611", "#E21752",
    "#00A8E8", "#00BFA6", "#9D00FF", "#7A00CC", "#FF00FF", "#FF007F",
    "#FF4500", "#FF6347"
];

class JobTileCompanyImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bgColor: this.getRandomColor(),
            variant: null
        };
    }

    componentDidMount() {
        posthog.onFeatureFlags(() => {
            const variant = posthog.getFeatureFlag('ab_test_job_tile_background_color');
         //   console.log('Feature flag variant:', variant);
            this.setState({ variant });
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.localImg != nextProps.localImg;
    }

    getRandomColor() {
        const randomIndex = Math.floor(Math.random() * vibrantColors.length);
        return vibrantColors[randomIndex];
    }

    render() {
        var { data, localImg } = this.props;
        const { bgColor, variant } = this.state;


        if (!data || !data.company) {
            return "";
        }

        let url = data.company.logo_url;

        if(localImg){
            url = localImg;
        }


        var img = <LazyLoadImage
            className="job-tile-logo"
            alt={`${data.company.name} logo`}
            threshold={10000}
            width={60}
            height={60}
            src={url}
        />;

        var none = (
            <div className='job-tile-logo-wrapper'>
                <div className="job-tile-missing-logo" style={{ backgroundColor: variant === 'test' ? bgColor : '#ccc' }}>
                    {data.company && data.company.name && data.company.name.length > 0 ? data.company.name[0] : ""}
                </div>
            </div>
        );

        if (!url) {
            return none;
        }

        if (data.company.reduced_hours === "Part time" && !data.company.featured) {
            return (
                <div className='job-tile-logo-wrapper no-link'>
                    {img}
                </div>
            );
        }

        var company_url = "/company/" + data.company.slug + "/jobs";
        return (
            <Link to={company_url}>
                <div className='job-tile-logo-wrapper'>
                    {img}
                </div>
            </Link>
        );
    }
}

export default JobTileCompanyImage;
