import React from 'react';
//import 'react-lazy-load-image-component/src/effects/blur.css'; // for blur effect

class HomeFeaturedIn extends React.Component {

  render() {
    // Hardcoded media logos, you can replace the image paths accordingly
    const mediaLogos = [
      { name: 'Bloomberg', imageName: 'featured-logos/bloomberg.png', altText: 'Bloomberg' },
      { name: 'The Guardian', imageName: 'featured-logos/guardian.png', altText: 'The Guardian' },
      { name: 'Time', imageName: 'featured-logos/time.png', altText: 'Time' },
      //{name: 'Business Insider', imageName: 'featured-logos/business-insider.png', altText: 'Business Insider'},
      //  {name: 'Fortune', imageName: 'featured-logos/fortune.png', altText: 'Fortune'},
      { name: 'Yahoo', imageName: 'featured-logos/yahoo.png', altText: 'Yahoo' }
    ];

    return (
      <section className='row featured-in'>

        <h3 className='number-one'>The <span className="text-accent">#1 Job Board</span> for Jobs with a <span className="text-accent">Better Work-Life Balance</span>.</h3>

        <p>As seen in...</p>
        <ul className='row'>
          {
            mediaLogos.map((logo, index) => (
              <li key={index}>
                <img
                  src={`/assets/${logo.imageName}`}
                  alt={`${logo.altText}`}
                  height={32}
                  className="featured-logo"
                />
              </li>
            ))
          }
        </ul>
      </section>
    );
  }
}

export default HomeFeaturedIn;
