import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ApplicationWrapper from '../components/application-form/ApplicationWrapper';
import CompanyBenefits from '../components/company-page/CompanyBenefits';
import CompanyDepartments from '../components/company-page/CompanyDepartments';
import CompanyLocation from '../components/company-page/CompanyLocation';
import CompanyProfile from '../components/company-page/CompanyProfile';
import CompanyTypicalWeek from '../components/company-page/CompanyTypicalWeek';
import CompanyVacation from '../components/company-page/CompanyVacation';
import JobDescription from '../components/job-page/JobDescription';
import JobPosted from '../components/job-page/JobPosted';
import JobShareButtons from '../components/job-page/JobShareButtons';
import JobSkills from '../components/job-page/JobSkills';
import JobTags from '../components/job-page/JobTags';
import Loader from '../components/misc/Loader';
import JobLocationWarning from '../components/tiles/job/JobLocationWarning';
import * as itemActions from '../store/item/actions';
import * as vars from '../store/vars';
import * as FormatTools from '../utils/format';
import { withRouter } from 'react-router-dom';

import JobExpired from './JobExpired';
import { NotificationManager } from 'react-notifications';


class Job extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /* SSR start */
    static page = "jobs"
    static schema = "job";

    static loadDataSSR(url) {
        var temp = url.split("/")
        var fullUrl = FormatTools.StripTrailingSlash("/" + temp[temp.length - 2] + "/" + temp[temp.length - 1])
        return itemActions.getItemSSR(Job.page, { url: fullUrl, include_company: "true", live: true });
    }

    static parseDataSSR(data) {
        var s = vars.getDefaultState();
        s.item.get = {
            requested: true,
            loading: false,
            response: data
        };
        return s;
    }
    /* SSR end */


    componentDidMount() {

        let { location } = this.props;

        if (!this.props.job.requested || !this.props.job.response || (this.props.match.url != this.props.job.response.url)) {
            this.props.getData({ url: FormatTools.StripTrailingSlash(this.props.match.url), include_company: "true", live: true });
        }

        if (location.search.indexOf("startedapplication=true") != -1) {
            NotificationManager.info('', "Welcome back, here's the job you last viewed", 6000);
        }

    }

    componentWillUnmount() {
        this.props.reset();
    }


    render() {
        var { job } = this.props;

        if (!job.requested || !job.response || (job.response && !job.response.error && !job.response.company)) {

            return <Loader />;
        } else if (job.loading) {
            return <Loader />
        } else if (job.response && job.response.error) {
            return <JobExpired />
        }

        var { response: d } = job;

        var remote = d.is_remote ? "Remote " : "";

        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        var posted = new Date(d.posted * 1000);

        // Meta
        var metaTitle = `${remote}${d.title} at ${d.company.name}`;

        if (metaTitle.length < 35) {
            if (!d.is_remote && d.location_city && d.location_country) {
                metaTitle += " | " + d.location_city + ", " + d.location_country + " ";
            } else if (!d.is_remote && d.location_country) {
                metaTitle += " | " + d.location_country + " ";
            }
        }

        if (metaTitle.length < 40) {
            metaTitle += ` (${monthNames[posted.getMonth()]} ${posted.getFullYear()})`;
        }

        if (metaTitle.length < 52) {
            metaTitle += " - 4dayweek.io"
        } else if (metaTitle.length < 57) {
            metaTitle += " · 4dayweek.io"
        }

        var metaDesc = d.company.name + " is hiring a " + (remote) + d.title + " on a 4 day work week. Are you ready to join the team? Submit your job application today.";
        var metaImg = "https://media.fourdayweek.io/files/social-share.png";

        if (d.company.social_image_url) {
            metaImg = d.company.social_image_url;
        }
        var metaUrl = "https://4dayweek.io" + d.url;

        // Job listing
        var { title, company, apply_url, url, posted, role, category } = job.response;



        var incorrect_data_url = "/contact?message=" + encodeURIComponent("Hi there,\n\nThere is incorrect data on " + metaUrl + "\n\n...")
        var job_expired_url = "/contact?message=" + encodeURIComponent("Hi there,\n\nUnfortunately the following job has expired:\n\n" + metaUrl + "\n\nThanks")

        var companyName = "";

        let logoUrl = "";

        if (d.company.logo_url) {
            logoUrl = d.company.logo_url;
        } else if (d.info && d.info.logo_url) {
            logoUrl = d.info.logo_url;
        }

        var companyLogo = <LazyLoadImage
            threshold={100}
            height={40}
            width={40}
            alt={company.name}
            src={logoUrl}
            className="company-logo"
        />;

        if (company.reduced_hours == "Part time" && !company.featured) {
            companyName = company.name;
        } else {
            companyName = <Link to={"/company/" + company.slug + "/jobs"} className="btn btn-link success btn-huge">{company.name}</Link>;
            companyLogo = <Link to={"/company/" + company.slug + "/jobs"} >{companyLogo}</Link>;
        }


        let subHeading = "";

        if (company.four_day_reference_text) {
            subHeading = company.four_day_reference_text;
        } else if (d.info && d.info.short_description) {
            subHeading = d.info.short_description;
        }

        return (
            <div className="job-page">
                <HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />


                <div className="row job-header-wrapper">

                    <div className="row job-header-content">

                        <div className='col-sm-8 cols'>

                            <div className='row'>
                                <ul className='breadcrumbs'>
                                    <li><Link to={"/jobs/"+FormatTools.Slugify(category)}>{category} Jobs</Link></li>
                                    <li><Link to={"/jobs/"+FormatTools.Slugify(role)}>{role}</Link></li>
                                </ul>

                            </div>

                            <h1>{title}</h1>

                            <div className="company-info">
                                <div className="title-logo">
                                    {logoUrl ?
                                        companyLogo : ""}

                                </div>
                                <h2 className="title-sub">{companyName}</h2>
                                {subHeading ? <h2 className="title-reference">{subHeading}</h2> : ""}
                            </div>


                            {/*   <Link to={"/jobs/" + FormatTools.Slugify(d.role)} className='btn btn-link success btn-huge margin-left hide-mobile'>{d.role}</Link>*/}


                            <JobTags {...d} />

                        </div>
                        <div className='col-sm-4 cols'>
                            <JobPosted {...d} />

                            <ApplicationWrapper job={job.response} position="job_page_top" />
                        </div>

                    </div>

                </div>

                <div className='row job-content-wrapper'>


                    <div className={'job-listing row'} key={"listing-" + url}>{/*onClick={(e) => this.props.onClick(e)}>*/}


                        <div className="row job-content ">

                            {/* Left column */}
                            <div className="col-sm-8 cols  hero-left">
                                <JobLocationWarning {...d} />
                                <JobDescription {...d} />
                                <ApplicationWrapper job={job.response} position="job_page_middle" />
                            </div>


                            {/* Right column */}


                            <div className="col-sm-4 cols company-meta hero-right  align-center job-company">

                                <div className="company-meta-boxes">

                                    <CompanyProfile company={d.company} info={d.info} />
                                    <CompanyTypicalWeek company={d.company} info={d.info} isJob={true} />
                                    <CompanyVacation company={d.company} info={d.info} />
                                    <CompanyLocation company={d.company} info={d.info} />
                                    <CompanyBenefits company={d.company} info={d.info} />
                                    <CompanyDepartments company={d.company} info={d.info} />
                                    <JobSkills data={d} />
                                    {/*}    <JobStack data={d} />*/}


                                </div>

{/*}
                                <div className='row'>
                                    {d.category ?
                                        <p className="row margin-top align-center margin-bottom">
                                            <Link to={"/jobs/" + FormatTools.Slugify(d.category)} className="btn btn-light btn-md cta">View more {d.category} jobs</Link>
                                        </p>

                                        : ""}
                                    {d.role ?
                                        <p className="row align-center view-more-button">
                                            <Link to={"/jobs/" + FormatTools.Slugify(d.category)} className="btn btn-light btn-md cta">View more {d.role} jobs</Link>
                                        </p>

                                        : ""}
                                </div>
                                */}

                                <JobShareButtons url={metaUrl} />
                                <p className="row margin-top align-center">
                                    <Link to={incorrect_data_url} target="_blank" rel="noindex nofollow" className="btn btn-link btn-sm">Report incorrect data</Link>
                                </p>
                                <p className="row margin-top align-center">
                                    <Link to={job_expired_url} target="_blank" rel="noindex nofollow noopener noreferrer" className="btn btn-link btn-sm">Let us know if the job has expired</Link>
                                </p>




                            </div>

                        </div>

                    </div>

                </div>
                <div className='application-apply-footer'>
                    <ApplicationWrapper job={job.response} position="job_page_bottom" />
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        job: state.item.get,
    };
};


const mapDispatchToProps = (dispatch, ownProps) => ({
    getData: (data) => {
        dispatch(itemActions.getItem("jobs", data));
    },
    reset: (data) => {
        dispatch(itemActions.resetItem());
    },
});

Job = connect(mapStateToProps, mapDispatchToProps)(Job);

export default withRouter(Job);