import React from 'react';
import FieldBase64 from './FieldBase64';

class FieldFileInput extends React.Component {
	isAcceptable(file) {
		const { accepted } = this.props;
		const fileType = file.file.type;
		const fileSize = file.file.size;

		// Check if the file type is accepted
		if (accepted && !accepted.includes(fileType)) {
			alert("That file type is not accepted. We only accept " + accepted.join(", ") + ". Please try again...");
			return false;
		}

		// Check if the file size exceeds the limit (e.g., 5 MB)
		const maxFileSize = 10 * 1024 * 1024; // 5 MB in bytes
		if (fileSize > maxFileSize) {
			alert("File size exceeds the limit of 10 MB. Please try again...");
			return false;
		}

		return true;
	}

	onDone(d) {
		const { multiple } = this.props;
		const list = [];

		if (!d) {
			return;
		}

		if (multiple) {
			for (let i = 0; i < d.length; i++) {
				const cur = d[i];
				if (this.isAcceptable(cur)) {
					list.push(cur.base64);
				}
			}
		} else {
			if (this.isAcceptable(d)) {
				list.push(d.base64);
			}
		}

		this.props.onChange(list);
	}

	render() {
		const { input, multiple, accepted, icon } = this.props;

		let acceptStr = "";
		if (accepted) {
			acceptStr = accepted.join(",");
		}

		return (
			<>
				<FieldBase64
					{...input}
					{...this.props}
					multiple={multiple}
					onDone={(d) => this.onDone(d)}
					accept={acceptStr}
				/>
			</>
		);
	}
}

export default FieldFileInput;