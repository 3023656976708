

export function ToTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
};

export function ReplaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
};

export function Unslug(str) {
  if(!str){
    return null;
  }
  return ReplaceAll(ReplaceAll(str, '-', ' '), "_", " ");
};

export function Slugify(str) {
  if(!str){
    return null;
  }
  str = str.toLowerCase();
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to   = "aaaaeeeeiiiioooouuuunc------";
  for (var i=0, l=from.length ; i<l ; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
}

export function FormatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return num;
}


export function GetFirstSentence(str) {
  // The regex can be adjusted as per the requirement
  const match = str.match(/(.*?[.!?])\s/);
  return (match ? match[1] : str);
}



export function AddUTM(url) {
  var utm = "";

  if(!url){
    return url;
  }

  var utmObj = {
    "utm_source": "4dayweek.io",
    "utm_medium": "4dayweek.io",
    "utm_campaign": "4dayweek.io",
    "ref": "4dayweek.io",
    "source": "4dayweek.io"
  }

  for (var key in utmObj) {
    var cur = utmObj[key];
    if ((url.toLowerCase().indexOf("?") == -1) || (url.toLowerCase().indexOf("&"+key) == -1 && url.toLowerCase().indexOf("?"+key) == -1)) {
      utm += "&" + key + "=" + cur;
    }
  }

  if(url && url.indexOf("lever") > -1){
    utm += "&lever-origin=applied&lever-source%5B%5D=4dayweek.io&lever-requisition-name=4dayweek.io&lever-posting-owner-name=4dayweek.io&lever-source=4dayweek.io&lever-referer=4dayweek.io";
  } else if(url && url.indexOf("jobvite") > -1){
    utm += "&__jvst=Job Board&__jvsd=4dayweek.io";
  } 
  var apply_link = url + "";

  if (apply_link.indexOf("?") >= 0) {
      apply_link = apply_link + "&" + utm;
  } else {
      apply_link = apply_link + "?" + utm;
  }

  apply_link = apply_link.replace("?&", "?").replace("&&", "&");
  return apply_link;
};

export function StripTrailingSlash(url) {
  if(!url){
    return url;
  }
  return url.replace(/\/$/, '');
};

export function FormatAsMoney(amount) {
  // Convert the integer to a number with two decimal places
  let dollars = Math.round(amount / 100);

  // Add dollar sign and return the formatted string
  return `$${dollars}`;
}