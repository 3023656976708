
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default class FieldBase64 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploadedFiles: [],  // This will store uploaded files
    };
  }

  handleChange(e) {
    // get the files
    let files = e.target.files;

    // Process each file
    var allFiles = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been processed
        if (allFiles.length == files.length) {
          // Apply Callback function
          if (this.props.multiple) {
            this.props.onDone(allFiles);
            this.setState({ uploadedFiles: allFiles });
          } else {
            this.props.onDone(allFiles[0]);
            this.setState({ uploadedFiles: [allFiles[0]] });
          }
        }
      } // reader.onload
    } // for
  }

  render() {
    let { id, helper, icon } = this.props;
    const { uploadedFiles } = this.state;

    return (
      <div className="fileupload">
        <input
          id={id}
          key={id}
          type="file"
          required={this.props.isRequired}
          accept={this.props.accept}
          onChange={this.handleChange.bind(this)}
          multiple={this.props.multiple} />

        <label htmlFor={id}>
            {icon ? <div className="fileupload-icon"><FontAwesomeIcon icon={icon}/></div> : ""}
            <div className="fileupload-helper">{helper ? helper : "Upload file"}</div>
            <div className="fileupload-browse">Browse...</div>
        </label>

        { /* Display uploaded files */}
        <div className="uploaded-files">
          {
            uploadedFiles.map((file, index) =>
              <div key={index}>
                <p>{file.name}</p>
              </div>
            )
          }
        </div>

      </div>
    );
  }
}

FieldBase64.defaultProps = {
  multiple: false,
};