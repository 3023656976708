import LabelField from '../fields/LabelField';
import React from 'react';
class EditJobFormCompany extends React.Component {



    render() {


        var { form, data } = this.props;


        return (
            <div className="post-job-box job-post-form-company">

                <h3 className='post-job-heading'>Company Details</h3>



                <div className="row field-row">
                    <LabelField inputType="text" id="company_name" label={"Company name"} isRequired={true} singleCol={true} placeholder={"Your Company"} />
                </div>


                <div className="row field-row">
                    <LabelField inputType="url" id="company_url" label={"Company Website"} isRequired={true} singleCol={true} placeholder="https://yourcompany.com" />
                </div>


                <div className="row field-row">
                    <LabelField inputType="file" id="company_logo" label={"Company Logo"} isRequired={false} multiple={false} singleCol={true} accepted={['image/png', 'image/jpg', 'image/jpeg', 'image/gif']} helper="Upload your company logo (PNG, JPG, GIF)" onChange={form.mutators.changeCompanyLogo} />
                    {data && data.company && data.company.logo_url ? <div className="row"><img src={data.company.logo_url} className="company-logo" /></div> : ""}
                </div>








            </div>
        );
    }
}

export default EditJobFormCompany;