import HelmetMeta from '../components/global/HelmetMeta';
import React from 'react';
import ResumeReviewHero from '../components/resume-review/ResumeReviewHero';
import ResumeReviewAsSeenOn from '../components/resume-review/ResumeReviewAsSeenOn';
import ResumeReviewHowItWorks from '../components/resume-review/ResumeReviewHowItWorks';
import ResumeReviewWhyUse from '../components/resume-review/ResumeReviewWhyUse';
import ResumeReviewFeatures from '../components/resume-review/ResumeReviewFeatures';
import ResumeReviewUploader from '../components/resume-review/ResumeReviewUploader';
import ResumeReviewTrust from '../components/resume-review/ResumeReviewTrust';

class ResumeReview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        var metaTitle = "AI Resume Review - Get Expert Feedback Instantly";
        var metaDesc = "Free AI-powered resume review to help you land more interviews. Get actionable feedback on your resume in seconds.";
        var metaImg = "https://media.fourdayweek.io/files/social-share.png";
        var metaUrl = "https://4dayweek.io/resume-review";

        return (
            <div className="main-container resume-review-landing">
                <HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

                <ResumeReviewHero />
                <ResumeReviewAsSeenOn />
                <ResumeReviewHowItWorks />
                <ResumeReviewWhyUse />
                <ResumeReviewFeatures />
                <ResumeReviewUploader />
                <ResumeReviewTrust />
            </div>
        );
    }
}

export default ResumeReview; 