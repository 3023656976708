import ArticleTile from '../article-page/ArticleTile';
import React from 'react';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import Loader from '../misc/Loader';


class ArticlesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        var { data } = this.props;

        if (!data || data.loading) {
            return <Loader padded text="Fetching latest updates..." className="min-height" />
        }

        var articles = data.itemIds.map((d, index) => <ArticleTile data={data.items[d]}/>);

        return (

            <div className="row articles-list">
                {articles}

            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

ArticlesList = connect(mapStateToProps, mapDispatchToProps)(ArticlesList);

export default ArticlesList;
