// Local stoage helpers
export function IsLoggedIn(user){
    return user && user.requested && !user.loading && user.response && user.response.id_str;
}

export function GetNextAdvertId  (numAdverts) {
    let key = 'last_advert_id';
    let id = localStorage.getItem(key);

    if(!id) {
        localStorage.setItem(key, 0);
        return 0;
    }

    id = parseInt(id) + 1;

    if(id >= numAdverts) {
        localStorage.setItem(key, 0);
     
        return 0;
    }

    localStorage.setItem(key, id);

    return id;

};