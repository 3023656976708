import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBriefcase,
    faEnvelope,
    faFileAlt,
    faHandSparkles,
    faMapLocation,
    faMapLocationDot,
    faMapMarkerAlt,
    faPlusCircle,
    faPlusSquare,
    faRocket,
    faStar,
    faUserCheck,
    faUserPlus,
    faWandSparkles
} from '@fortawesome/free-solid-svg-icons';
import { faThinkPeaks } from '@fortawesome/free-brands-svg-icons';
import { IsLoggedIn } from '../../utils/user';

class MenuMobile extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    goToTop = () => {
        document.body.scrollTop = 0;
    }

    renderMenuItem = (item, currentPath) => {
        const isActive = currentPath === item.url ? ' active' : '';

        // Special case for header items (like "Tools")
        if (item.className && item.className.includes('header-item')) {
            return (
                <li key={item.key} className={item.separator ? `separator ${item.separator}` : ''}>
                    <div className={item.className}>
                        <FontAwesomeIcon icon={item.icon} /> {item.text}
                    </div>
                </li>
            );
        }

        // External links need to use regular <a> tags, not Link component
        if (item.external) {
            return (
                <li key={item.key} className={item.separator ? `separator ${item.separator}` : ''}>
                    <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        className={item.className || 'btn btn-ghost btn-xl'}
                    >
                        <FontAwesomeIcon icon={item.icon} /> {item.text}
                    </a>
                </li>
            );
        }

        // Internal links use React Router's Link component
        return (
            <li key={item.key} className={item.separator ? `separator ${item.separator}` : ''}>
                <Link
                    to={item.url}
                    onClick={() => this.goToTop()}
                    className={`${item.className || 'btn btn-ghost btn-xl'} ${isActive}`}
                >
                    <FontAwesomeIcon icon={item.icon} /> {item.text}
                </Link>
            </li>
        );
    }

    getMenuItems = (user) => {
        // Base menu items that always appear
        const mobileItems = [
            { key: "home", url: "/", icon: "home", text: "Home" },
            { key: "remote-jobs", url: "/jobs", icon: "laptop-code", text: "Jobs", separator: "large" },
            { key: "companies", url: "/companies", icon: "building", text: "Companies" },
            { key: "pros-and-cons", url: "/pros-and-cons", icon: faThinkPeaks, text: "Pros and Cons" }
            // { key: "advice", url: "/advice", icon: "clipboard-check", text: "Advice" },
        ];


        // Tools items
        mobileItems.push({
            key: "auto-apply",
            url: "/auto-apply",
            icon: faRocket,
            text: "Auto Apply",
            separator: "large",
        });

        mobileItems.push({
            key: "resume-builder",
            url: "https://aiapply.co/resume-builder?via=philip",
            icon: faPlusCircle,
            text: "AI Resume Builder",
            external: true
        });


        mobileItems.push({
            key: "cover-letter-builder",
            url: "https://4dayweek.jobcopilot.com/cover-letter-builder",
            icon: faFileAlt,
            text: "AI Cover Letter Builder",
            external: true
        });


        mobileItems.push({
            key: "resume-review",
            url: "https://4dayweekresumes.com/",
            icon: faUserCheck,
            text: "AI Resume Review",
            external: true
        });

        // Add user-specific menu items
        if (IsLoggedIn(user)) {
            // Items for logged in users
            mobileItems.push({
                key: "add-company",
                url: "/add-company",
                icon: "plus",
                text: "Submit a company",
                separator: "large"
            });

            mobileItems.push({
                key: "profile-edit",
                url: "/profile/edit",
                icon: "user-alt",
                text: "Edit profile",
                separator: "large"
            });

            // Company-specific items
            if (user && user.response && user.response.company_id) {
                mobileItems.push({
                    key: "edit-company",
                    url: "/dashboard/company",
                    icon: faBriefcase,
                    text: "Edit company profile",
                    separator: "large"
                });

                mobileItems.push({
                    key: "company-badge",
                    url: "/dashboard/company/widget",
                    icon: faStar,
                    text: "View company score"
                });
            }

            mobileItems.push({
                key: "post-a-job",
                url: "/post-a-job",
                icon: faPlusSquare,
                text: "Post a job"
            });

            // Items for company users
            if (user && user.response && user.response.role === "company") {
                mobileItems.push({
                    key: "posted-jobs",
                    url: "/dashboard/jobs",
                    icon: faRocket,
                    text: "My jobs"
                });
            }

            mobileItems.push({
                key: "logout",
                url: "/logout",
                icon: "sign-out-alt",
                text: "Logout",
                separator: "large"
            });
        } else {
            // Items for logged out users
            mobileItems.push({
                key: "post-a-job",
                url: "/post-a-job",
                text: "Post a job",
                icon: faPlusSquare,
                separator: "large"
            });

            mobileItems.push({
                key: "add-company",
                url: "/add-company",
                icon: "plus",
                text: "Submit a company"
            });

            mobileItems.push({
                key: "login",
                url: "/login",
                icon: faUserCheck,
                text: "Login",
                separator: "large"
            });

            mobileItems.push({
                key: "sign-up",
                url: "/sign-up",
                icon: faUserPlus,
                text: "Sign up"
            });
        }

        return mobileItems;
    }

    render() {
        const { user, location, onToggleMobile } = this.props;
        const currentPath = location.pathname.trim();
        const menuItems = this.getMenuItems(user);

        return (
            <ul
                className="nav navbar-nav tablet-menu padding-top padding-bottom"
                onClick={onToggleMobile}
            >
                {menuItems.map(item => this.renderMenuItem(item, currentPath))}
            </ul>
        );
    }
}

export default withRouter(MenuMobile);




