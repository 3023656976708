import React from 'react';
import { Link } from 'react-router-dom';
import * as FormatTools from '../../utils/format';

class CompanyTechnologies extends React.Component {

    render() {

        var { company: companyData } = this.props;
        let { stack } = companyData;


        if(!companyData.info){
            return "";
        }



        let { current_technologies } = companyData.info;


        let count = 0;
        var tech = current_technologies ? current_technologies.map((d) => {
            // If category not in [frontend, backend, mobile, devops, database, other] 
            if (!["Hosting", "CMS", "Customer Relationship Management", "Other", "Marketing Automation", "E-commerce Platforms"].includes(d.category)
                || ["Mobile Friendly"].includes(d.name)) {
                return "";
            }
            count += 1;
            return (<li>{d.name}</li>);
        }) : [];



        var stackTags = stack ? stack.map((d) => <li>
            <Link to={"/jobs/" + FormatTools.Slugify(d.value)} className="btn btn-link btn-sm">{d.label}</Link>

        </li>) : [];

        if (count== 0 && stackTags.length == 0) {
            return "";
        }


        return (
            <div className="company-side-box company-technologies">
                <h3>Expect to be working with</h3>
                <ul className="company-tags tags   tags-sm">
                    {stackTags}
                    {stackTags.length == 0 ? tech : ""}
                    <li>+ many more</li>
                </ul>
            </div>
        )
    }
}

export default CompanyTechnologies;