
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

class CompanyTypicalWeek extends React.Component {

    render() {


        var { company: companyData, isJob } = this.props;

        

        var { four_day_off, four_day_reference_text, four_day_reference_url, reduced_hours, featured, hours_full_time, vacation } = companyData;




        // Working hours
        var workingHours = "";

        if (four_day_reference_text) {
            workingHours = four_day_reference_text;
        } else {
            if (reduced_hours == "Offered") {
                workingHours = "All our employees work a 4 day work week";
                if (hours_full_time) {
                    workingHours += `, ${hours_full_time}hrs per week.`
                }

                workingHours += " at full pay.";
            } else if (reduced_hours == "Would consider") {
                workingHours = "We don't officially have a 4 day work week, but we'd be open to applications for a 4 day work week @ 80% salary."
            } else if (reduced_hours == "Offered every 2nd week") {
                workingHours = "We have a 4 day week every 2nd week."
            } else if (reduced_hours == "Offered summer") {
                workingHours = "We have a 4 day work week in the summer months."
            } else if (reduced_hours == "Offered 4.5 days") {
                workingHours = "We work a 4.5 day work week."
            } else if (reduced_hours == "Generous PTO") {

                if (vacation) {
                    workingHours = `We work 5 days per week but offer ${vacation} days of vacation per year.`;
                } else {
                    workingHours = `We work 5 days per week but offer a very generous vacation policy.`;
                }


            } else if (reduced_hours == "Flex Fridays") {
                workingHours = "We work 5 days per week, but Fridays are flexible. We don't have meetings and you can use the time for work, personal growth or just catching up on life's errands."
            } else if (reduced_hours == "Flexible hours") {
                workingHours = "We work 5 days per week, but are fully flexible with our hours. We have a culture of 'as long are you get the work done' allowing you to set a schedule which works best for you."
            } else if (reduced_hours == "Unlimited PTO") {
                workingHours = "We work 5 days per week but have an unlimited vacation policy - take off what you need.";
            } else if (reduced_hours == "9 day fortnight") {
                if (hours_full_time) {
                    workingHours = `We work a 9 day fortnight. That's 9 slightly longer days over 2 weeks (${hours_full_time * 2} hours total), with every second Friday off.`
                } else {
                    workingHours = `We work a 9 day fortnight. That's 9 slightly longer days of work over 2 weeks (i.e. compressed hours), with every second Friday off.`
                }
            } else {
                workingHours = "We hire for flexible part time roles."
            }
        }

        // Link
        let readMore = "";

        if (four_day_reference_url) {
            readMore = <div className='read-more'><a rel="nofollow noreferrer noopener" href={four_day_reference_url} target="_blank" className="btn btn-link btn-sm">More info</a></div>;
        }



        var output = "";

        if (reduced_hours == "Offered" || reduced_hours == "Would consider") {
            if (four_day_off && four_day_off != "Any") {
                var dayShort = four_day_off.substr(0, 3);
                output = ["Mon", "Tue", "Wed", "Thu", "Fri"].map((d) => <li>
                    <div className="week-icon">{d == dayShort ? "🏖️" : <FontAwesomeIcon icon={"check"} />}</div>
                    <h5>{d}</h5>
                </li>);
                output = <ul>{output}</ul>
            } else if (four_day_off == "Any") {
                output = <p className='typical-week-any'><FontAwesomeIcon icon={"check"} /> Our 4 day week = any day off</p>
            }
        } else if (reduced_hours == "Offered every 2nd week" || reduced_hours == "9 day fortnight") {
            output = ["Mon", "Tue", "Wed", "Thu", "Fri", "Mon", "Tue", "Wed", "Thu"].map((d) => <li>
                <div className="week-icon margin-top-small"><FontAwesomeIcon icon={"check"} /></div>
                <h5>{d}</h5>
            </li>);

            output = <>{output}<li><div className="week-icon">🏖️</div><h5>Fri</h5></li></>

            output = <ul className='no-margin'>{output}</ul>
        }

        var reference = "";

        if (four_day_reference_url) {
            if (four_day_reference_url.indexOf("twitter.com") > -1) {
                var spl = four_day_reference_url.split("/")
                var tweetId = spl[spl.length - 1];
                reference = <div className="row margin-top">
                    <p>More details 👇</p>
                    <div className="twitter-tweet-wrapper">
                        <TwitterTweetEmbed tweetId={tweetId} options={{ conversation: 'none' }} noBorders="true" />
                    </div>
                </div>
            }
        }
        return (
            <div className="company-side-box company-typical-week ">

                <h3>Working Week {readMore}</h3>

                <p>{workingHours}</p>
                {output}

                {reference}
            </div>

        )
    }
}

export default CompanyTypicalWeek;