import React from 'react';
import ApplicationWrapper from '../../application-form/ApplicationWrapper';
import JobLocationWarning from './JobLocationWarning';
import JobTileDescription from './JobTileDescription';
import JobTileListingTitle from './JobTileListingTitle';

class JobTileListing extends React.Component {


    render() {

        // Job listing
        var { title, company, url, posted, reduced_hours, is_expired, page } = this.props;

        return (

            <div className={'job-listing row'} key={"listing-" + url}>

                <div className="row job-content ">

                    <div className="job-title row">
                        <div className="col-sm-7 job-title-left  cols cols-sm">
                            <JobTileListingTitle {...this.props} />
                        </div>

                        <div className="col-sm-5 job-title-right cols cols-sm">
                            {/*}  <div className='show-mobile'>{fdwHtml}</div>*/}
                            <ApplicationWrapper job={this.props} position={"job_tile_middle"} />

                        </div>
                    </div>

                    {/*}   <JobTags {...this.props} />*/}
                    <JobLocationWarning {...this.props} />
                    <JobTileDescription {...this.props} />

                    <div className="row margin-top job-footer">
                        <ApplicationWrapper job={this.props} position={"job_tile_bottom"} />


                        {/*} {!is_expired ?
                            <div className="col-sm-3 cols cols-sm align-right">
                                <Link to={url} className="btn btn-huge btn-default padded-left more-info">More info...</Link>
                       </div> : ""} */}
                    </div>
                </div>

            </div >
        );
    }
}


export default JobTileListing;