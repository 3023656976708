import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const JobCopilotButton = ({ job }) => {
  // default to "Engineering" if no category found
  const category = job && job.category ? job.category : "";

  return (
    <div className="jobcopilot-ad">
      <div className="jobcopilot-ad-icon">
        <FontAwesomeIcon icon="magic-wand-sparkles" aria-hidden="true" />
      </div>

      <div className="jobcopilot-ad-content">
        <h4 className="jobcopilot-ad-content-title">
          Automatically Apply to Remote {category} Jobs!
        </h4>
        <p className="jobcopilot-ad-content-description">
          Let our copilot automatically search &amp; apply to remote jobs from all across the web.
        </p>
        {/* Points to /career-services/job-copilot */}
        <Link to="/auto-apply" className="btn jobcopilot-ad-content-cta">
          Try it now
        </Link>
      </div>
    </div>
  );
};

export default JobCopilotButton;
