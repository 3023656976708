import CountryTile from '../tiles/CountryTile';
import Loader from '../misc/Loader';
import React from 'react';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import CountryAdvertTile from '../../components/tiles/CountryAdvertTile';
import SubscriberInlineWrapper from '../subscriber-form/SubscriberInlineWrapper';


class CountriesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        var { data, adverts, max } = this.props;

        if (!data || !data.init || data.loading) {
            return <Loader padded text="Fetching 4 day work week countries..." className="min-height" />
        }


        var i = 0;
        let j = 0;
        var countries = data.itemIds.map((d) => {
            i += 1;

            if (max && i > max) {
                return "";
            }

            let ad = "";
            if (i == 4 || ((i % 10) == 0) && adverts.init && !adverts.loading) {
     
                ad = <CountryAdvertTile page="countries" pos={j} />
                j += 1;
                if (j > adverts.itemIds.length - 1) {
                    j = 0;
                }
            }

            // Subscribe form
            let subscribe = "";
            if ((i == 9) || (i % 40 == 0)) {
                subscribe = <SubscriberInlineWrapper className="subscriber-green" />
            }


            return (
                <>
                    {subscribe ? subscribe : ad}
                    <CountryTile data={data.items[d]} key={"country-" + data.items[d].id_str} />

                </>
            );

        });





        return (

            <ul className="row countries-list">
                {countries}
            </ul>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        adverts: state.list.adverts_list_home,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

CountriesList = connect(mapStateToProps, mapDispatchToProps)(CountriesList);

export default CountriesList;
