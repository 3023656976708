
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import StudiesList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class Studies extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_studies"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Studies.page, Studies.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Studies.page + "_" + Studies.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Studies.page,
			id: Studies.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "4 Day Work Week Studies";
		var metaDesc = "Browse our collection of blog posts discussing the latest research, pilots, and studies on the 4-day workweek. Understand its impact on productivity, well-being and more";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/studies";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<StudiesList data={data} settings={settings} />
				</section>



				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[Studies.page + "_" + Studies.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(Studies.page, Studies.id));
	},


});

Studies = connect(mapStateToProps, mapDispatchToProps)(Studies);

export default Studies;