
import HelmetMeta from '../../components/global/HelmetMeta';
import EditCompanyForm from '../../components/edit-company/EditCompanyForm';
import React from 'react';



class EditCompany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        let metaTitle = "Edit your company profile";
        let metaDesc = "Need to change something? Edit your company profile here.";
        var metaImg = "https://media.fourdayweek.io/files/social-share.png";
        let metaUrl = "https://4dayweek.io/dashboard/company";
        let canonical = "https://4dayweek.io/dashboard/company";


        return (
            <div className="company-edit">

                <HelmetMeta title={metaTitle} desc={metaDesc + "."} img={metaImg} url={metaUrl} canonical={canonical} />
                <section className="row  section-header">

                    <div className='row hero thin-width'>
                        <h1 className="smaller">{metaTitle}</h1>
                        <div className="row">
                            <h2 className="page-subtitle">{metaDesc}</h2>
                        </div>
                    </div>
                </section>


                <section className='row thin-width no-padding'>
                    <EditCompanyForm />



                </section>
            </div>
        );
    }
}

export default EditCompany