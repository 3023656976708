import React from 'react';
import { Link } from 'react-router-dom';
import * as FormatTools from '../../utils/format';

class JobSkills extends React.Component {

    render() {

        var { data } = this.props;


        if(data.skills && data.skills.length == 0 && data.stack && data.stack.length == 0){
            return "";
        }

        // Stack
        var stackTags = data.stack ? data.stack.map((d) => <li key={"stack-"+FormatTools.Slugify(d.value)}>
         <Link to={"/jobs/" + FormatTools.Slugify(d.value)} className="btn btn-link btn-sm">{d.label}</Link>
        </li>) : [];


        // Stack
        var toolsTags = data.tools ? data.tools.map((d) => <li key={"stack-"+FormatTools.Slugify(d.value)}>
         <Link to={"/jobs/" + FormatTools.Slugify(d.value)} className="btn btn-link btn-sm">{d.label}</Link>
        </li>) : [];



        // Skills
        var skillsTags = data.skills ? data.skills.map((d) => <li key={"skills-"+FormatTools.Slugify(d.value)}>
         {d.label}
        </li>) : [];

        if(skillsTags.length == 0 && stackTags.length == 0){
            return "";
        }


        return (
            <div className="company-side-box job-skills">
                <h3>Desirable Skills and Experience</h3>
                <ul className="company-tags tags   tags-sm">
                    {toolsTags}
                    {stackTags}
                    {skillsTags}

                </ul>
            </div>
        )
    }
}

export default JobSkills;