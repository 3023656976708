
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import RegionsList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class Regions extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_regions"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Regions.page, Regions.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Regions.page + "_" + Regions.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Regions.page,
			id: Regions.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "4 Day Week Pilots in States and Regions";
		var metaDesc = "Our regional insights provide a deep dive into state-led pilots and the transformative results achieved.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/regions";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<RegionsList data={data} settings={settings} />
				</section>




				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[Regions.page + "_" + Regions.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(Regions.page, Regions.id));
	},


});

Regions = connect(mapStateToProps, mapDispatchToProps)(Regions);

export default Regions;