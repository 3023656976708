
import React from 'react';
import { Link } from 'react-router-dom';
import { Slugify } from '../../utils/format';

class CoocList extends React.Component {

	constructor(props) {
		super();

		this.state = {
			selected: props.selected,
			cooc: props.cooc
		};
	}


	componentDidUpdate() {
		if (this.props.selected != this.state.selected) {
			this.setState({
				selected: this.props.selected
			});
		}

	}
	shouldComponentUpdate() {
		return true;
	}


	render() {

		var { cooc, selected} = this.props;

		if (!cooc || cooc.loading) {
			return <ul className="row cooc">
				<li>People also search:</li>
				<li>Loading...</li>
			</ul>
		}


		if (!cooc.response.lookup[selected]) {
			return <ul className="row cooc">
				<li>People also search:</li>
				<li><Link className="btn btn-link" to="/jobs/part-time">Part time</Link></li>
				<li><Link className="btn btn-link" to="/jobs/engineering">Engineering</Link></li>
				<li><Link className="btn btn-link" to="/jobs/marketing">Marketing</Link></li>
				<li><Link className="btn btn-link" to="/jobs/product">Product &amp; UX</Link></li>
				<li><Link className="btn btn-link" to="/jobs/sales">Sales &amp; Customer success</Link></li>
				<li><Link className="btn btn-link" to="/jobs/data-science">Data Science & Analytics</Link></li>
				<li><Link className="btn btn-link" to="/jobs/human-resources">Human Resources</Link></li>
				<li><Link className="btn btn-link" to="/jobs/finance">Finance</Link></li>
				<li><Link className="btn btn-link" to="/jobs/legal">Legal</Link></li>
			</ul>;
		}

		var lookup = cooc.response.lookup[selected];

		var tags = lookup.map((d) => <li><Link className='btn btn-link' to={"/jobs/" + Slugify(d.filter_2)}>{d.filter_2}</Link></li>);


		return (
			<ul className="row cooc">
				<li>People also search:</li>
				{tags}
			</ul>



		);
	}
}

export default CoocList;