
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';

class CritiqueNotificationTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: false,
        };
    }

    componentDidMount() {
        if (!localStorage.getItem("critique_id")) {
            this.setState({ closed: true });
        }
    }

    onClose() {
        localStorage.removeItem("critique_id");
        this.setState({ closed: true });

    }

    render() {
        let { closed } = this.state;
        let { critique } = this.props;

        if (closed) {
            return "";
        } else if(!critique || !critique.response ||  !critique.response.data || !critique.response.data.data || !critique.response.data.data.reviewReady){
            return "";
        }

        

        let url = critique.response.data.data.reviewLink;

        return (
            <div className={"critique-notification-tile-wrapper "} key={"critique-notification-tile"} href={url} rel="noopener noreferrer nofollow" target="_blank">
                <div className="row critique-notification-content">
                    <div className='critique-notification-title'>
                        <h4>Your AI powered resume review is ready ✨</h4>
                    </div>
                    <div className='critique-notification-cta'>
                        <a href={url} className='btn btn-light btn-lg cta' target='_blank' rel="noopener noreferrer nofollow">View now</a>
                    </div>

                    <button onClick={() => this.onClose()} className='critique-notification-close'><FontAwesomeIcon icon={faTimes} /></button>

                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        critique: state.item.critique,
    };
};


const mapDispatchToProps = (dispatch, ownProps) => ({


});

CritiqueNotificationTile = connect(mapStateToProps, mapDispatchToProps)(CritiqueNotificationTile);

export default CritiqueNotificationTile;