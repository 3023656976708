
import React from 'react';


export default class ApplicationFormSuccessEmail extends React.Component {


    render() {

        return (
        <div className='application-form-success padded'>
            <h4 className='bold no-margin padded-bottom-small'>Application sent!</h4>
            <p>Good luck! We're crossing our fingers for you 🤞</p>
            <p>If you make it to the next stage, please mention 4dayweek.io 😇</p>
        </div>
        );
    }
}
