import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../components/subscriber-form/SubscriberInlineWrapper';
import CountriesList from '../components/list/CountriesList';
import * as listActions from '../store/list/actions';
import * as listReducer from '../store/list/reducer';
import * as vars from '../store/vars';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';


class Countries extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			params: Countries.params,
			fetchedAll: false,
		};
	}

	/* SSR start */
	static params = {
		only_live: true
	}
	static page = "countries"
	static id = "list"


	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Countries.page, Countries.id, Countries.params);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Countries.page + "_" + Countries.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Countries.page,
			id: Countries.id,
			params: Countries.params,
			response: data
		});
		return s;
	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData(this.state.params);
		}
	}

	render() {
		var { data } = this.props;


		//	var metaTitle = "50+ Countries with a 4 Day Work Week in "+(new Date().getFullYear());
		var metaTitle = "Countries with a 4 Day Work Week";
		var metaDesc = "Although no country has fully adopted a 4 day work week, many of the countries below are experimenting with one or have a short average working week.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/countries";

		return (
			<div className="main-container  medium-width countries-page">
				<HelmetMeta title={metaTitle+ " | 4DayWeek.io"} desc={metaDesc} img={metaImg} url={metaUrl} />
				<section className="row thin">

					<SubscriberModalWrapper showForDelay={true} showForMouse={true} showForScroll={true} />
					<h1 className="smaller align-center">{metaTitle}</h1>
					<h2 className="page-subtitle">{metaDesc}</h2>
					{/*}	<ul className="work-life-balance-list">
						<li>Whether they have a 4 day work week</li>
						<li>Remote working</li>
						<li>Flexibility</li>
						<li>Openness to employee side projects</li>
						<li>Vacation policy</li>
		</ul>*/}
				</section>
				<section className="row no-padding-mobile thin">

					<CountriesList data={data} />
				</section>




				<section className="row thin margin-top-large">
					<SubscriberInlineWrapper className="subscriber-green"  />
				</section>

			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		data: state.list.countries_list,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: (params) => {
		dispatch(listActions.fetchItems("countries", "list", params));
	},
});

Countries = connect(mapStateToProps, mapDispatchToProps)(Countries);

export default Countries;