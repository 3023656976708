import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import HelmetMeta from '../../components/global/HelmetMeta';
import JobsList from '../../components/list/JobsList';
import * as listActions from '../../store/list/actions';
import { NotificationManager } from 'react-notifications';
import PostedJobsList from '../../components/list/PostedJobsList';

class PostedJobs extends React.Component {



	componentDidMount() {

		this.props.loadJobs();


		var { location } = this.props;

		if (location.search.indexOf("updated=true") != -1) {
			this.props.loadJobs();
			NotificationManager.success('', 'Your job post has been updated', 6000);
		}
	}

	render() {

		let title = "My Jobs";
		let description = "Your purchased job listings are below.";
		let metaImg = "https://media.fourdayweek.io/files/social-share.png";
		let metaUrl = ("https://4dayweek.io/dashboard/jobs").replace(/\/$/, "");
		let canonical = "";


		return (
			<div className="main-container  jobs-page">
				<HelmetMeta title={title} desc={description} img={metaImg} url={metaUrl} canonical={canonical} />
				<section className="row thin">

					<h1 className="smaller">{title}</h1>
					<h2 className="page-subtitle">{description}</h2>
				</section>



				<section className="row no-padding-mobile thin  ">
					<PostedJobsList {...this.props} />
				</section>

				<section className="row thin align-center margin-top">
					<h3>Looking to post another job?</h3>
					<Link to="/add-company" className="btn btn-success btn-huge margin-top">Post a job...</Link>
				</section>

			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
		data: state.list.dashboard_jobs,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	loadJobs: (params) => {
		dispatch(listActions.fetchItems("dashboard", "get_jobs", params, "dashboard_jobs"));
	},
	resetItems: () => {
		dispatch(listActions.resetItems("dashboard", "get_jobs"));
	},


});

PostedJobs = connect(mapStateToProps, mapDispatchToProps)(PostedJobs);

export default withRouter(PostedJobs);