import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { connect } from 'react-redux';
import CompanyBenefits from '../components/company-page/CompanyBenefits';
import CompanyDepartments from '../components/company-page/CompanyDepartments';
import CompanyHeader from '../components/company-page/CompanyHeader';
import CompanyLinks from '../components/company-page/CompanyLinks';
import CompanyLocation from '../components/company-page/CompanyLocation';
import CompanyRanking from '../components/company-page/CompanyRanking';
import CompanyShareButtons from '../components/company-page/CompanyShareButtons';
import CompanySideprojects from '../components/company-page/CompanySideprojects';
import CompanyTechnologies from '../components/company-page/CompanyTechnologies';
import CompanyTypicalDay from '../components/company-page/CompanyTypicalDay';
import CompanyTypicalWeek from '../components/company-page/CompanyTypicalWeek';
import CompanyVacation from '../components/company-page/CompanyVacation';
import JobsList from '../components/list/JobsList';
import Loader from '../components/misc/Loader';
import MarkdownRenderer from '../components/misc/MarkdownRenderer';
import SubscriberInlineWrapper from '../components/subscriber-form/SubscriberInlineWrapper';
import * as itemActions from '../store/item/actions';
import * as listActions from '../store/list/actions';
import * as vars from '../store/vars';
import * as FormatTools from '../utils/format';
import NotFound from './NotFound';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';


class Company extends React.Component {


	/* SSR start */
	static page = "companies"
	static schema = "company";
	static loadDataSSR(url) {
		var slug = url.split("/")
		slug = FormatTools.StripTrailingSlash(slug[slug.length - 2])
		return itemActions.getItemSSR(Company.page, { slug: slug, live: true });
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.item.get = {
			requested: true,
			loading: false,
			response: data
		};
		return s;
	}

	/* SSR end */

	componentDidMount() {
		// lol
		if (!this.props.company.requested || !this.props.company.response || (this.props.match.params.slug != this.props.company.response.slug)) {
			this.props.getCompany({ slug: this.props.match.params.slug, live: true }); // meh, just show the page (so backlinks dont break) but dont show jobs
		}
		this.props.getJobs({ company: this.props.match.params.slug, include_expired: true });
	}

	componentWillUnmount() {
		console.log("reseting...")
		this.props.reset();
	}


	render() {
		var { company, jobs } = this.props;

		var { response: companyData, loading: companyLoading, requested: companyRequested } = company;

		if (!companyRequested) {
			return <Loader />;
		} else if (companyLoading) {
			return <Loader />
		} else if (companyRequested && companyData && companyData.error) {
			return <NotFound />
		}

		// Meta description
		var jobRoles = [];
		for (var i = 0; i < jobs.itemIds.length; i++) {
			var curID = jobs.itemIds[i];
			var cur = jobs.items[curID].role;
			jobRoles.push(cur);
		}

		jobRoles = [...new Set(jobRoles)]

		var metaJobs = "";
		for (var i = 0; (i < jobRoles.length) && (i < 2); i++) {
			var curJobRole = jobRoles[i];
			metaJobs = metaJobs + curJobRole + ", "

		}

		if (jobRoles.length >= 3) {
			metaJobs = `${jobRoles[0]}s, ${jobRoles[1]}s & ${jobRoles[2]}s `;
		} else if (jobRoles.length >= 2) {
			metaJobs = `${jobRoles[0]}s & ${jobRoles[1]}s `;
		} else if (jobRoles.length >= 1) {
			metaJobs = `${jobRoles[0]}s `;
		}

		var numJobs = "";



		// Meta tags
		var metaTitle = `${companyData.name} Jobs - Remote 4 Day Week Jobs (${(new Date().getFullYear())})`;
		var metaDesc = companyData.name + " is hiring " + metaJobs + "on a 4 day work week. Are you ready to start your career at " + companyData.name + "? " + numJobs + "Submit your job application today!";// descSpl.slice(0, Math.min(descSpl.length, 3)).join("\n\n");;
		var metaImg = "https://media.fourdayweek.io/files/social-share-company.png";
		var metaUrl = "https://4dayweek.io/company/" + companyData.slug + "/jobs";


		// Jobs html
		var jobsHtml = <div className="row company-jobs">

			{!companyData.hide_jobs ?
				<div className="row">
					<h3>Open positions</h3>
					<div className='margin-top'>
						<JobsList data={jobs} noResultsMsg="Sorry, we aren't hiring at the moment." page={"company"} />
					</div>
				</div>
				: ""}
			<div className="row  margin-top">
				<SubscriberInlineWrapper companyName={companyData.name} className="subscriber-green" />
			</div>
		</div>


		return (
			<div className="company-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				{/* Left column */}


				<div className="row company-header-wrapper">



					<div className="row company-header-content">

						<SubscriberModalWrapper showForDelay={true} showForMouse={true} showForScroll={true} />
						<CompanyHeader {...this.props} />



					</div>
				</div>


				<div className="row company-content">
					<div className='col-sm-8 cols'>
						<div className="row">



							{companyData.description ?
								<div className="company-description">
									<MarkdownRenderer>{companyData.description}</MarkdownRenderer>
								</div> : ""}




						</div>

						<div className='company-jobs-wrapper' id="remote-jobs">
							{jobsHtml}
						</div>
					</div>

					<div className='col-sm-4 cols company-meta'>

						<div className="row company-meta-boxes">
							<h3 className='show-mobile margin-top-large'>{companyData.name} overview</h3>


							<CompanyTypicalWeek company={companyData} info={companyData.info} />
							<CompanyVacation company={companyData} info={companyData.info} />
							<CompanyRanking company={companyData} info={companyData.info} />
							{/*}	<CompanyDetails {...this.props} />*/}

							<CompanyTypicalDay company={companyData} info={companyData.info} />
							<CompanyLocation company={companyData} info={companyData.info} />
							<CompanyDepartments company={companyData} info={companyData.info} />
							<CompanySideprojects company={companyData} info={companyData.info} />
							<CompanyTechnologies company={companyData} info={companyData.info} />
							<CompanyBenefits company={companyData} info={companyData.info} />

						</div>
						<CompanyLinks {...this.props} />
						<CompanyShareButtons url={metaUrl} />


				<SubscriberInlineWrapper companyName={companyData.name} className="subscriber-green hide-desktop hide-tablet" />

					</div>


				</div>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		jobs: state.list.jobs_company,
		company: state.item.get,
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({
	getJobs: (params) => {
		dispatch(listActions.fetchItems("jobs", "company", params));
	},
	getCompany: (data) => {
		dispatch(itemActions.getItem("companies", data));
	},

	reset: (data) => {
		dispatch(itemActions.resetItem());
		dispatch(listActions.resetItems("jobs", "company"));
	},
});

Company = connect(mapStateToProps, mapDispatchToProps)(Company);

export default Company;