
export const RENAME_SKILLS = {
  //'ios': 'iOS Developer',
  '.net': '.NET Developer',
  'angular': 'Angular Developer',
  'java': 'Java Developer',
  'cloud': 'Cloud Developer',
  'express': 'ExpressJS',
  'python': 'Python',
  'react': 'React Developer',
  'aws': 'AWS Developer',
  'ruby': 'Ruby Developer',
  'php': 'PHP Developer',
  'android': 'Android Developer',
  'drupal': 'Drupal Developer',
  'javascript': 'Javascript',
  'database': 'Database Developer',
  'postgresql': 'PostgreSQL',
  'css': 'CSS',
  'wordpress': 'Wordpress Developer',
  'full-stack-engineer': 'Full Stack Developer',
  //  'engineering': 'Engineering',
  'qa-engineer': 'QA Tester',
  'ux': 'UX',
  'product': 'Product & UX',
  'human-resources': 'HR',
  //   'product': 'Product & UX',
  // 'data': 'Data & Analytics',
}

export const COUNTRIES = [
  { "label": "Afghanistan", "value": "Afghanistan" },
  { "label": "Albania", "value": "Albania" },
  { "label": "Algeria", "value": "Algeria" },
  { "label": "Andorra", "value": "Andorra" },
  { "label": "Angola", "value": "Angola" },
  { "label": "Antigua and Barbuda", "value": "Antigua and Barbuda" },
  { "label": "Argentina", "value": "Argentina" },
  { "label": "Armenia", "value": "Armenia" },
  { "label": "Australia", "value": "Australia" },
  { "label": "Austria", "value": "Austria" },
  { "label": "Azerbaijan", "value": "Azerbaijan" },
  { "label": "Bahamas", "value": "Bahamas" },
  { "label": "Bahrain", "value": "Bahrain" },
  { "label": "Bangladesh", "value": "Bangladesh" },
  { "label": "Barbados", "value": "Barbados" },
  { "label": "Belarus", "value": "Belarus" },
  { "label": "Belgium", "value": "Belgium" },
  { "label": "Belize", "value": "Belize" },
  { "label": "Benin", "value": "Benin" },
  { "label": "Bhutan", "value": "Bhutan" },
  { "label": "Bolivia", "value": "Bolivia" },
  { "label": "Bosnia and Herzegovina", "value": "Bosnia and Herzegovina" },
  { "label": "Botswana", "value": "Botswana" },
  { "label": "Brazil", "value": "Brazil" },
  { "label": "Brunei", "value": "Brunei" },
  { "label": "Bulgaria", "value": "Bulgaria" },
  { "label": "Burkina Faso", "value": "Burkina Faso" },
  { "label": "Burma", "value": "Burma" },
  { "label": "Burundi", "value": "Burundi" },
  { "label": "Cabo Verde", "value": "Cabo Verde" },
  { "label": "Cambodia", "value": "Cambodia" },
  { "label": "Cameroon", "value": "Cameroon" },
  { "label": "Canada", "value": "Canada" },
  { "label": "Central African Republic", "value": "Central African Republic" },
  { "label": "Chad", "value": "Chad" },
  { "label": "Chile", "value": "Chile" },
  { "label": "China", "value": "China" },
  { "label": "Colombia", "value": "Colombia" },
  { "label": "Comoros", "value": "Comoros" },
  { "label": "Congo (Brazzaville)", "value": "Congo (Brazzaville)" },
  { "label": "Congo (Kinshasa)", "value": "Congo (Kinshasa)" },
  { "label": "Costa Rica", "value": "Costa Rica" },
  { "label": "Cote d'Ivoire", "value": "Cote d'Ivoire" },
  { "label": "Croatia", "value": "Croatia" },
  { "label": "Cuba", "value": "Cuba" },
  { "label": "Cyprus", "value": "Cyprus" },
  { "label": "Czech Republic", "value": "Czech Republic" },
  { "label": "Denmark", "value": "Denmark" },
  { "label": "Djibouti", "value": "Djibouti" },
  { "label": "Dominica", "value": "Dominica" },
  { "label": "Dominican Republic", "value": "Dominican Republic" },
  { "label": "Ecuador", "value": "Ecuador" },
  { "label": "Egypt", "value": "Egypt" },
  { "label": "El Salvador", "value": "El Salvador" },
  { "label": "Equatorial Guinea", "value": "Equatorial Guinea" },
  { "label": "Eritrea", "value": "Eritrea" },
  { "label": "Estonia", "value": "Estonia" },
  { "label": "Ethiopia", "value": "Ethiopia" },
  { "label": "Fiji", "value": "Fiji" },
  { "label": "Finland", "value": "Finland" },
  { "label": "France", "value": "France" },
  { "label": "Gabon", "value": "Gabon" },
  { "label": "Gambia", "value": "Gambia" },
  { "label": "Georgia", "value": "Georgia" },
  { "label": "Germany", "value": "Germany" },
  { "label": "Ghana", "value": "Ghana" },
  { "label": "Greece", "value": "Greece" },
  { "label": "Grenada", "value": "Grenada" },
  { "label": "Guatemala", "value": "Guatemala" },
  { "label": "Guinea", "value": "Guinea" },
  { "label": "Guinea-Bissau", "value": "Guinea-Bissau" },
  { "label": "Guyana", "value": "Guyana" },
  { "label": "Haiti", "value": "Haiti" },
  { "label": "Honduras", "value": "Honduras" },
  { "label": "Hungary", "value": "Hungary" },
  { "label": "Iceland", "value": "Iceland" },
  { "label": "India", "value": "India" },
  { "label": "Indonesia", "value": "Indonesia" },
  { "label": "Iran", "value": "Iran" },
  { "label": "Iraq", "value": "Iraq" },
  { "label": "Ireland", "value": "Ireland" },
  { "label": "Israel", "value": "Israel" },
  { "label": "Italy", "value": "Italy" },
  { "label": "Jamaica", "value": "Jamaica" },
  { "label": "Japan", "value": "Japan" },
  { "label": "Jordan", "value": "Jordan" },
  { "label": "Kazakhstan", "value": "Kazakhstan" },
  { "label": "Kenya", "value": "Kenya" },
  { "label": "Kiribati", "value": "Kiribati" },
  { "label": "Kosovo", "value": "Kosovo" },
  { "label": "Kuwait", "value": "Kuwait" },
  { "label": "Kyrgyzstan", "value": "Kyrgyzstan" },
  { "label": "Laos", "value": "Laos" },
  { "label": "Latvia", "value": "Latvia" },
  { "label": "Lebanon", "value": "Lebanon" },
  { "label": "Lesotho", "value": "Lesotho" },
  { "label": "Liberia", "value": "Liberia" },
  { "label": "Libya", "value": "Libya" },
  { "label": "Liechtenstein", "value": "Liechtenstein" },
  { "label": "Lithuania", "value": "Lithuania" },
  { "label": "Luxembourg", "value": "Luxembourg" },
  { "label": "Macedonia", "value": "Macedonia" },
  { "label": "Madagascar", "value": "Madagascar" },
  { "label": "Malawi", "value": "Malawi" },
  { "label": "Malaysia", "value": "Malaysia" },
  { "label": "Maldives", "value": "Maldives" },
  { "label": "Mali", "value": "Mali" },
  { "label": "Malta", "value": "Malta" },
  { "label": "Marshall Islands", "value": "Marshall Islands" },
  { "label": "Mauritania", "value": "Mauritania" },
  { "label": "Mauritius", "value": "Mauritius" },
  { "label": "Mexico", "value": "Mexico" },
  { "label": "Micronesia", "value": "Micronesia" },
  { "label": "Moldova", "value": "Moldova" },
  { "label": "Monaco", "value": "Monaco" },
  { "label": "Mongolia", "value": "Mongolia" },
  { "label": "Montenegro", "value": "Montenegro" },
  { "label": "Morocco", "value": "Morocco" },
  { "label": "Mozambique", "value": "Mozambique" },
  { "label": "Namibia", "value": "Namibia" },
  { "label": "Nauru", "value": "Nauru" },
  { "label": "Nepal", "value": "Nepal" },
  { "label": "Netherlands", "value": "Netherlands" },
  { "label": "New Zealand", "value": "New Zealand" },
  { "label": "Nicaragua", "value": "Nicaragua" },
  { "label": "Niger", "value": "Niger" },
  { "label": "Nigeria", "value": "Nigeria" },
  { "label": "North Korea", "value": "North Korea" },
  { "label": "Norway", "value": "Norway" },
  { "label": "Oman", "value": "Oman" },
  { "label": "Pakistan", "value": "Pakistan" },
  { "label": "Palau", "value": "Palau" },
  { "label": "Palestine", "value": "Palestine" },
  { "label": "Panama", "value": "Panama" },
  { "label": "Papua New Guinea", "value": "Papua New Guinea" },
  { "label": "Paraguay", "value": "Paraguay" },
  { "label": "Peru", "value": "Peru" },
  { "label": "Philippines", "value": "Philippines" },
  { "label": "Poland", "value": "Poland" },
  { "label": "Portugal", "value": "Portugal" },
  { "label": "Qatar", "value": "Qatar" },
  { "label": "Romania", "value": "Romania" },
  { "label": "Russia", "value": "Russia" },
  { "label": "Rwanda", "value": "Rwanda" },
  { "label": "Saint Kitts and Nevis", "value": "Saint Kitts and Nevis" },
  { "label": "Saint Lucia", "value": "Saint Lucia" },
  { "label": "Saint Vincent and the Grenadines", "value": "Saint Vincent and the Grenadines" },
  { "label": "Samoa", "value": "Samoa" },
  { "label": "San Marino", "value": "San Marino" },
  { "label": "Sao Tome and Principe", "value": "Sao Tome and Principe" },
  { "label": "Saudi Arabia", "value": "Saudi Arabia" },
  { "label": "Senegal", "value": "Senegal" },
  { "label": "Serbia", "value": "Serbia" },
  { "label": "Seychelles", "value": "Seychelles" },
  { "label": "Sierra Leone", "value": "Sierra Leone" },
  { "label": "Singapore", "value": "Singapore" },
  { "label": "Slovakia", "value": "Slovakia" },
  { "label": "Slovenia", "value": "Slovenia" },
  { "label": "Solomon Islands", "value": "Solomon Islands" },
  { "label": "Somalia", "value": "Somalia" },
  { "label": "South Africa", "value": "South Africa" },
  { "label": "South Korea", "value": "South Korea" },
  { "label": "South Sudan", "value": "South Sudan" },
  { "label": "Spain", "value": "Spain" },
  { "label": "Sri Lanka", "value": "Sri Lanka" },
  { "label": "Sudan", "value": "Sudan" },
  { "label": "Suriname", "value": "Suriname" },
  { "label": "Swaziland", "value": "Swaziland" },
  { "label": "Sweden", "value": "Sweden" },
  { "label": "Switzerland", "value": "Switzerland" },
  { "label": "Syria", "value": "Syria" },
  { "label": "Taiwan", "value": "Taiwan" },
  { "label": "Tajikistan", "value": "Tajikistan" },
  { "label": "Tanzania", "value": "Tanzania" },
  { "label": "Thailand", "value": "Thailand" },
  { "label": "Timor-Leste", "value": "Timor-Leste" },
  { "label": "Togo", "value": "Togo" },
  { "label": "Tonga", "value": "Tonga" },
  { "label": "Trinidad and Tobago", "value": "Trinidad and Tobago" },
  { "label": "Tunisia", "value": "Tunisia" },
  { "label": "Turkey", "value": "Turkey" },
  { "label": "Turkmenistan", "value": "Turkmenistan" },
  { "label": "Tuvalu", "value": "Tuvalu" },
  { "label": "Uganda", "value": "Uganda" },
  { "label": "Ukraine", "value": "Ukraine" },
  { "label": "United Arab Emirates", "value": "United Arab Emirates" },
  { "label": "United Kingdom", "value": "UK" },
  { "label": "United States of America", "value": "USA" },
  { "label": "Uruguay", "value": "Uruguay" },
  { "label": "Uzbekistan", "value": "Uzbekistan" },
  { "label": "Vanuatu", "value": "Vanuatu" },
  { "label": "Vatican City", "value": "Vatican City" },
  { "label": "Venezuela", "value": "Venezuela" },
  { "label": "Vietnam", "value": "Vietnam" },
  { "label": "Yemen", "value": "Yemen" },
  { "label": "Zambia", "value": "Zambia" },
  { "label": "Zimbabwe", "value": "Zimbabwe" }

]

export const CONTINENTS = {
  'africa': "Africa",
  'asia': "Asia",
  'europe': "Europe",
  'mainland-europe': "Europe (excluding UK)",
  'oceania': "Oceania",
  'north-america': "North America",
  'south-america': "South America",
}

export const EXTENDED_LOCATIONS_LOOKUP = {
  'africa': "Africa",
  'asia': "Asia",
  'europe': "Europe",
	"mainland-europe": "Europe (excluding UK)",
  'oceania': "Oceania",
  'north-america': "North America",
  'south-america': "South America",
  '': "-----",

  'afghanistan': "Afghanistan",
  'albania': "Albania",
  'algeria': "Algeria",
  'andorra': "Andorra",
  'angola': "Angola",
  'antigua-and-barbuda': "Antigua and Barbuda",
  'argentina': "Argentina",
  'armenia': "Armenia",
  'australia': "Australia",
  'austria': "Austria",
  'azerbaijan': "Azerbaijan",
  'bahamas': "the Bahamas",
  'bahrain': "Bahrain",
  'bangladesh': "Bangladesh",
  'barbados': "Barbados",
  'belarus': "Belarus",
  'belgium': "Belgium",
  'belize': "Belize",
  'benin': "Benin",
  'bhutan': "Bhutan",
  'bolivia': "Bolivia",
  'bosnia-and-herzegovina': "Bosnia and Herzegovina",
  'botswana': "Botswana",
  'brazil': "Brazil",
  'brunei': "Brunei",
  'bulgaria': "Bulgaria",
  'burkina-faso': "Burkina Faso",
  'burundi': "Burundi",
  'cabo-verde': "Cabo Verde",
  'cambodia': "Cambodia",
  'cameroon': "Cameroon",
  'canada': "Canada",
  'central-african-republic': "Central African Republic",
  'chad': "Chad",
  'chile': "Chile",
  'china': "China",
  'colombia': "Colombia",
  'comoros': "Comoros",
  'congo-democratic-republic-of-the': "Congo, Democratic Republic of the",
  'congo-republic-of-the': "Congo, Republic of the",
  'costa-rica': "Costa Rica",
  'croatia': "Croatia",
  'cuba': "Cuba",
  'cyprus': "Cyprus",
  'czech-republic': "Czech Republic",
  'denmark': "Denmark",
  'djibouti': "Djibouti",
  'dominica': "Dominica",
  'dominican-republic': "Dominican Republic",
  'east-timor': "East Timor",
  'ecuador': "Ecuador",
  'egypt': "Egypt",
  'el-salvador': "El Salvador",
  'equatorial-guinea': "Equatorial Guinea",
  'eritrea': "Eritrea",
  'estonia': "Estonia",
  'eswatini': "Eswatini",
  'ethiopia': "Ethiopia",
  'fiji': "Fiji",
  'finland': "Finland",
  'france': "France",
  'gabon': "Gabon",
  'gambia': "Gambia",
  'georgia': "Georgia",
  'germany': "Germany",
  'ghana': "Ghana",
  'greece': "Greece",
  'grenada': "Grenada",
  'guatemala': "Guatemala",
  'guinea': "Guinea",
  'guinea-bissau': "Guinea-Bissau",
  'guyana': "Guyana",
  'haiti': "Haiti",
  'honduras': "Honduras",
  'hungary': "Hungary",
  'iceland': "Iceland",
  'india': "India",
  'indonesia': "Indonesia",
  'iran': "Iran",
  'iraq': "Iraq",
  'ireland': "Ireland",
  'israel': "Israel",
  'italy': "Italy",
  'ivory-coast': "Ivory Coast",
  'jamaica': "Jamaica",
  'japan': "Japan",
  'jordan': "Jordan",
  'kazakhstan': "Kazakhstan",
  'kenya': "Kenya",
  'kiribati': "Kiribati",
  'korea-north': "Korea, North",
  'korea-south': "Korea, South",
  'kosovo': "Kosovo",
  'kuwait': "Kuwait",
  'kyrgyzstan': "Kyrgyzstan",
  'laos': "Laos",
  'latvia': "Latvia",
  'lebanon': "Lebanon",
  'lesotho': "Lesotho",
  'liberia': "Liberia",
  'libya': "Libya",
  'liechtenstein': "Liechtenstein",
  'lithuania': "Lithuania",
  'luxembourg': "Luxembourg",
  'madagascar': "Madagascar",
  'malawi': "Malawi",
  'malaysia': "Malaysia",
  'maldives': "Maldives",
  'mali': "Mali",
  'malta': "Malta",
  'marshall-islands': "Marshall Islands",
  'mauritania': "Mauritania",
  'mauritius': "Mauritius",
  'mexico': "Mexico",
  'micronesia': "Micronesia",
  'moldova': "Moldova",
  'monaco': "Monaco",
  'mongolia': "Mongolia",
  'montenegro': "Montenegro",
  'morocco': "Morocco",
  'mozambique': "Mozambique",
  'myanmar': "Myanmar",
  'namibia': "Namibia",
  'nauru': "Nauru",
  'nepal': "Nepal",
  'netherlands': "the Netherlands",
  'new-zealand': "New Zealand",
  'nicaragua': "Nicaragua",
  'niger': "Niger",
  'nigeria': "Nigeria",
  'north-macedonia': "North Macedonia",
  'norway': "Norway",
  'oman': "Oman",
  'pakistan': "Pakistan",
  'palau': "Palau",
  'panama': "Panama",
  'papua-new-guinea': "Papua New Guinea",
  'paraguay': "Paraguay",
  'peru': "Peru",
  'philippines': "the Philippines",
  'poland': "Poland",
  'portugal': "Portugal",
  'qatar': "Qatar",
  'romania': "Romania",
  'russia': "Russia",
  'rwanda': "Rwanda",
  'saint-kitts-and-nevis': "Saint Kitts and Nevis",
  'saint-lucia': "Saint Lucia",
  'saint-vincent-and-the-grenadines': "Saint Vincent and the Grenadines",
  'samoa': "Samoa",
  'san-marino': "San Marino",
  'sao-tome-and-principe': "Sao Tome and Principe",
  'saudi-arabia': "Saudi Arabia",
  'senegal': "Senegal",
  'serbia': "Serbia",
  'seychelles': "Seychelles",
  'sierra-leone': "Sierra Leone",
  'singapore': "Singapore",
  'slovakia': "Slovakia",
  'slovenia': "Slovenia",
  'solomon-islands': "Solomon Islands",
  'somalia': "Somalia",
  'south-africa': "South Africa",
  'south-sudan': "South Sudan",
  'spain': "Spain",
  'sri-lanka': "Sri Lanka",
  'sudan': "Sudan",
  'suriname': "Suriname",
  'sweden': "Sweden",
  'switzerland': "Switzerland",
  'syria': "Syria",
  'taiwan': "Taiwan",
  'tajikistan': "Tajikistan",
  'tanzania': "Tanzania",
  'thailand': "Thailand",
  'togo': "Togo",
  'tonga': "Tonga",
  'trinidad-and-tobago': "Trinidad and Tobago",
  'tunisia': "Tunisia",
  'turkey': "Turkey",
  'turkmenistan': "Turkmenistan",
  'tuvalu': "Tuvalu",
  'uganda': "Uganda",
  'ukraine': "Ukraine",
  'united-arab-emirates': "United Arab Emirates",
  'uk': "UK",
  'usa': "USA",
  'uruguay': "Uruguay",
  'uzbekistan': "Uzbekistan",
  'vanuatu': "Vanuatu",
  'vatican-city': "Vatican City",
  'venezuela': "Venezuela",
  'vietnam': "Vietnam",
  'yemen': "Yemen",
  'zambia': "Zambia",
  'zimbabwe': "Zimbabwe",
}


export const COUNTRIES_LOOKUP = {
  "australia": "Australia",
  "austria": "Austria",
  "canada": "Canada",
  "croatia": "Croatia",
  "cyprus": "Cyprus",
  "denmark": "Denmark",
  "estonia": "Estonia",
  "france": "France",
  "germany": "Germany",
  "india": "India",
  "ireland": "Ireland",
  "israel": "Israel",
  "italy": "Italy",
  "malaysia": "Malaysia",
  "netherlands": "Netherlands",
  "new-zealand": "New Zealand",
  "norway": "Norway",
  "poland": "Poland",
  "portugal": "Portugal",
  "slovakia": "Slovakia",
  "south-africa": "South Africa",
  "spain": "Spain",
  "sweden": "Sweden",
  "switzerland": "Switzerland",
  "turkey": "Turkey",
  "uk": "United Kingdom",
  "usa": "United States of America",
}






export const POPULAR_LOCATIONS_LOOKUP = {
  'usa': "I'm in the USA",
  'uk': "I'm in the UK",
  'canada': "I'm in Canada",
  'india': "I'm in India",
  'australia': "I'm in Australia",
  '': "-----",
};

export const LOCATIONS_LOOKUP = {
  'afghanistan': "I'm in Afghanistan",
  'albania': "I'm in Albania",
  'algeria': "I'm in Algeria",
  'andorra': "I'm in Andorra",
  'angola': "I'm in Angola",
  'antigua-and-barbuda': "I'm in Antigua and Barbuda",
  'argentina': "I'm in Argentina",
  'armenia': "I'm in Armenia",
  'australia': "I'm in Australia",
  'austria': "I'm in Austria",
  'azerbaijan': "I'm in Azerbaijan",
  'bahamas': "I'm in the Bahamas",
  'bahrain': "I'm in Bahrain",
  'bangladesh': "I'm in Bangladesh",
  'barbados': "I'm in Barbados",
  'belarus': "I'm in Belarus",
  'belgium': "I'm in Belgium",
  'belize': "I'm in Belize",
  'benin': "I'm in Benin",
  'bhutan': "I'm in Bhutan",
  'bolivia': "I'm in Bolivia",
  'bosnia-and-herzegovina': "I'm in Bosnia and Herzegovina",
  'botswana': "I'm in Botswana",
  'brazil': "I'm in Brazil",
  'brunei': "I'm in Brunei",
  'bulgaria': "I'm in Bulgaria",
  'burkina-faso': "I'm in Burkina Faso",
  'burundi': "I'm in Burundi",
  'cabo-verde': "I'm in Cabo Verde",
  'cambodia': "I'm in Cambodia",
  'cameroon': "I'm in Cameroon",
  'canada': "I'm in Canada",
  'central-african-republic': "I'm in Central African Republic",
  'chad': "I'm in Chad",
  'chile': "I'm in Chile",
  'china': "I'm in China",
  'colombia': "I'm in Colombia",
  'comoros': "I'm in Comoros",
  'congo-democratic-republic-of-the': "I'm in Congo, Democratic Republic of the",
  'congo-republic-of-the': "I'm in Congo, Republic of the",
  'costa-rica': "I'm in Costa Rica",
  'croatia': "I'm in Croatia",
  'cuba': "I'm in Cuba",
  'cyprus': "I'm in Cyprus",
  'czech-republic': "I'm in Czech Republic",
  'denmark': "I'm in Denmark",
  'djibouti': "I'm in Djibouti",
  'dominica': "I'm in Dominica",
  'dominican-republic': "I'm in Dominican Republic",
  'east-timor': "I'm in East Timor",
  'ecuador': "I'm in Ecuador",
  'egypt': "I'm in Egypt",
  'el-salvador': "I'm in El Salvador",
  'equatorial-guinea': "I'm in Equatorial Guinea",
  'eritrea': "I'm in Eritrea",
  'estonia': "I'm in Estonia",
  'eswatini': "I'm in Eswatini",
  'ethiopia': "I'm in Ethiopia",
  'fiji': "I'm in Fiji",
  'finland': "I'm in Finland",
  'france': "I'm in France",
  'gabon': "I'm in Gabon",
  'gambia': "I'm in Gambia",
  'georgia': "I'm in Georgia",
  'germany': "I'm in Germany",
  'ghana': "I'm in Ghana",
  'greece': "I'm in Greece",
  'grenada': "I'm in Grenada",
  'guatemala': "I'm in Guatemala",
  'guinea': "I'm in Guinea",
  'guinea-bissau': "I'm in Guinea-Bissau",
  'guyana': "I'm in Guyana",
  'haiti': "I'm in Haiti",
  'honduras': "I'm in Honduras",
  'hungary': "I'm in Hungary",
  'iceland': "I'm in Iceland",
  'india': "I'm in India",
  'indonesia': "I'm in Indonesia",
  'iran': "I'm in Iran",
  'iraq': "I'm in Iraq",
  'ireland': "I'm in Ireland",
  'israel': "I'm in Israel",
  'italy': "I'm in Italy",
  'ivory-coast': "I'm in Ivory Coast",
  'jamaica': "I'm in Jamaica",
  'japan': "I'm in Japan",
  'jordan': "I'm in Jordan",
  'kazakhstan': "I'm in Kazakhstan",
  'kenya': "I'm in Kenya",
  'kiribati': "I'm in Kiribati",
  'korea-north': "I'm in Korea, North",
  'korea-south': "I'm in Korea, South",
  'kosovo': "I'm in Kosovo",
  'kuwait': "I'm in Kuwait",
  'kyrgyzstan': "I'm in Kyrgyzstan",
  'laos': "I'm in Laos",
  'latvia': "I'm in Latvia",
  'lebanon': "I'm in Lebanon",
  'lesotho': "I'm in Lesotho",
  'liberia': "I'm in Liberia",
  'libya': "I'm in Libya",
  'liechtenstein': "I'm in Liechtenstein",
  'lithuania': "I'm in Lithuania",
  'luxembourg': "I'm in Luxembourg",
  'madagascar': "I'm in Madagascar",
  'malawi': "I'm in Malawi",
  'malaysia': "I'm in Malaysia",
  'maldives': "I'm in Maldives",
  'mali': "I'm in Mali",
  'malta': "I'm in Malta",
  'marshall-islands': "I'm in Marshall Islands",
  'mauritania': "I'm in Mauritania",
  'mauritius': "I'm in Mauritius",
  'mexico': "I'm in Mexico",
  'micronesia': "I'm in Micronesia",
  'moldova': "I'm in Moldova",
  'monaco': "I'm in Monaco",
  'mongolia': "I'm in Mongolia",
  'montenegro': "I'm in Montenegro",
  'morocco': "I'm in Morocco",
  'mozambique': "I'm in Mozambique",
  'myanmar': "I'm in Myanmar",
  'namibia': "I'm in Namibia",
  'nauru': "I'm in Nauru",
  'nepal': "I'm in Nepal",
  'netherlands': "I'm in the Netherlands",
  'new-zealand': "I'm in New Zealand",
  'nicaragua': "I'm in Nicaragua",
  'niger': "I'm in Niger",
  'nigeria': "I'm in Nigeria",
  'north-macedonia': "I'm in North Macedonia",
  'norway': "I'm in Norway",
  'oman': "I'm in Oman",
  'pakistan': "I'm in Pakistan",
  'palau': "I'm in Palau",
  'panama': "I'm in Panama",
  'papua-new-guinea': "I'm in Papua New Guinea",
  'paraguay': "I'm in Paraguay",
  'peru': "I'm in Peru",
  'philippines': "I'm in the Philippines",
  'poland': "I'm in Poland",
  'portugal': "I'm in Portugal",
  'qatar': "I'm in Qatar",
  'romania': "I'm in Romania",
  'russia': "I'm in Russia",
  'rwanda': "I'm in Rwanda",
  'saint-kitts-and-nevis': "I'm in Saint Kitts and Nevis",
  'saint-lucia': "I'm in Saint Lucia",
  'saint-vincent-and-the-grenadines': "I'm in Saint Vincent and the Grenadines",
  'samoa': "I'm in Samoa",
  'san-marino': "I'm in San Marino",
  'sao-tome-and-principe': "I'm in Sao Tome and Principe",
  'saudi-arabia': "I'm in Saudi Arabia",
  'senegal': "I'm in Senegal",
  'serbia': "I'm in Serbia",
  'seychelles': "I'm in Seychelles",
  'sierra-leone': "I'm in Sierra Leone",
  'singapore': "I'm in Singapore",
  'slovakia': "I'm in Slovakia",
  'slovenia': "I'm in Slovenia",
  'solomon-islands': "I'm in Solomon Islands",
  'somalia': "I'm in Somalia",
  'south-africa': "I'm in South Africa",
  'south-sudan': "I'm in South Sudan",
  'spain': "I'm in Spain",
  'sri-lanka': "I'm in Sri Lanka",
  'sudan': "I'm in Sudan",
  'suriname': "I'm in Suriname",
  'sweden': "I'm in Sweden",
  'switzerland': "I'm in Switzerland",
  'syria': "I'm in Syria",
  'taiwan': "I'm in Taiwan",
  'tajikistan': "I'm in Tajikistan",
  'tanzania': "I'm in Tanzania",
  'thailand': "I'm in Thailand",
  'togo': "I'm in Togo",
  'tonga': "I'm in Tonga",
  'trinidad-and-tobago': "I'm in Trinidad and Tobago",
  'tunisia': "I'm in Tunisia",
  'turkey': "I'm in Turkey",
  'turkmenistan': "I'm in Turkmenistan",
  'tuvalu': "I'm in Tuvalu",
  'uganda': "I'm in Uganda",
  'ukraine': "I'm in Ukraine",
  'united-arab-emirates': "I'm in United Arab Emirates",
  'uk': "I'm in the UK",
  'usa': "I'm in the USA",
  'uruguay': "I'm in Uruguay",
  'uzbekistan': "I'm in Uzbekistan",
  'vanuatu': "I'm in Vanuatu",
  'vatican-city': "I'm in Vatican City",
  'venezuela': "I'm in Venezuela",
  'vietnam': "I'm in Vietnam",
  'yemen': "I'm in Yemen",
  'zambia': "I'm in Zambia",
  'zimbabwe': "I'm in Zimbabwe",




  '': "-----",
  'africa': "I'm in Africa",
  'asia': "I'm in Asia",
  'europe': "I'm in Europe",
  'mainland-europe': "I'm in mainland Europe",
  'oceania': "I'm in Oceania",
  'north-america': "I'm in North America",
  'south-america': "I'm in South America",
  'anywhere': "Work from anywhere"
};

export const REDUCED_HOURS_LOOKUP = {
  '4-day-week': '4 day week (~32hrs @ 100% pay)',
  '4-day-week-pro-rata': '4 day week (~32hrs @ 80% pay)',
  //'rotating-4-day-week': '4 day week (every 2nd week)',
  //'half-day-fridays': '4.5 day week',
  //'summer-fridays': '4 day week during summer',


  'rotating-4-day-week': '4 day week every 2nd week',
  '9-day-fortnight': '9 day fortnight (e.g. 9/80 schedule)',
  'half-day-fridays': 'Half day Fridays (4.5 day week)',
  'summer-fridays': 'Summer Fridays (summer 4 day week)',

  'flex-fridays': "5 day week with 'Flexible Fridays'",
  'generous-pto': '5 day week (40+ days vacation)',
  'unlimited-pto': '5 day week (unlimited PTO)',
  'flexible-hours': '5 day week (very flexible hours)',
  'part-time': 'Part time',

};

export const REDUCED_HOURS_LOOKUP_ALT = {
  '4-day-week': '4 Day Week',
  '4-day-week-pro-rata': 'Pro-Rated 4 Day Week',
  'rotating-4-day-week': '4 Day Week every 2nd week',
  'half-day-fridays': 'Half Day Fridays',
  'summer-fridays': 'Summer Fridays',

  'flex-fridays': 'Flex Fridays',
  '9-day-fortnight': '9 Day Fortnight (e.g. 9/80)',
  'generous-pto': 'Generous PTO',
  'flexible-hours': 'Fully Flexible Hours',
  'unlimited-pto': 'Unlimited PTO',

  //'part-time': 'Part Time',

};


export const REDUCED_HOURS_LOOKUP_2 = {
  "Offered": "4 day work week",
  "Offered every 2nd week": "4 day week every 2nd week",
  "Offered summer": "4 day week during summer",
  "Offered 4.5 days": "4.5 day week",
  "Would consider": "4 day week @ 80% pay",
  "Flex Fridays": "Flex Fridays",
  "Flexible hours": "Fully Flexible Hours",
  "Generous PTO": "Generous PTO",
  "Unlimited PTO": "Unlimited PTO",
  "Part time": "Open to part time",
  "9-day-fortnight": "9 day fortnight"
};

export const REDUCED_HOURS_OPTIONS = 	[
  { value: 'Offered', label: 'Has a 4 day week' },
  { value: 'Offered every 2nd week', label: "We have a 4 day week every 2nd week" },
  { value: 'Offered summer', label: "We have a 4 day week during the summer months" },
  { value: 'Offered 4.5 days', label: "We work 4.5 days per week" },
  { value: 'Would consider', label: "Would consider applications for a 4 day week" },
  { value: 'Flex Fridays', label: "We have flex Fridays e.g. no meetings" },
  { value: 'Flexible hours', label: "We work 5 days per week, but are fully flexible with our hours" },
  { value: 'Generous PTO', label: "Offers generous PTO" },
  { value: 'Unlimited PTO', label: "Offers unlimited PTO" },
  { value: 'Part time', label: "Hires part time employees" },

  { value: '9-day-fortnight', label: "has a 9 Day Fortnight" },
]


export const FOUR_DAY_OFF_LOOKUP = {
  'monday': 'Monday off',
  //'tuesday': 'Tuesday off',
  'wednesday': 'Wed off',
 // 'thursday': 'Thursday off',
  'fridays': 'Friday off',
  'any': 'Any day off',
}

export const REMOTE_OPTIONS_LOOKUP = {
  'remote': 'Remote',
  'hybrid': 'Hybrid',
  'onsite': 'Onsite',
}


export const LEVELS_LOOKUP = {
  'entry-level': 'Entry-level',
  'mid-level': 'Mid-level',
  'senior': 'Senior',
}


export const IS_HIRING_LOOKUP = {
  'hiring': 'Yes, hiring',
  'not-hiring': 'Not hiring',
}


export const YEARS_EXPERIENCE = {
  '0': '<1 year',
  '1': '1 year',
  '2': '2 years',
  '3': '3 years',
  '4': '4 years',
  '5': '5 years',
  '6': '6 years',
  '7': '7 years',
  '8': '8 years',
  '9': '9 years',
  '10': '10+ years',

};

export const USER_ROLES = {
  'seeker-ready': "I'm ready to interview",
  'seeker-open': "I'm open to offers",
  'seeker-closed': "I'm not looking right now",
  '---': "---",
  'company': "I'm hiring",
};



export const CATEGORIES_LOOKUP = {
  "administrative": "Administrative",
  "customer-support": "Customer Support",
  "data-science": "Data Science",
  "design": "Design",
  "engineering": "Engineering",
  "finance": "Finance",
  "human-resources": "Human Resources",
  "marketing": "Marketing",
  "other": "Other",
  "product": "Product",
  "project-management": "Project Management",
  "sales": "Sales",
  "legal": "Legal",
  'logistics': "Logistics",
}

export const CATEGORIES_GOOD_LOOKUP = {
  "administrative": "Administrative",
  "customer-support": "Customer Support",
  "data-science": "Data Science",
  "design": "Design",
  "engineering": "Engineering",
  "finance": "Finance",
  "human-resources": "Human Resources",
  "marketing": "Marketing",
  "product": "Product",
  "project-management": "Project Management",
  "sales": "Sales",


}

export const TOPICS_LOOKUP = {
  "advice": "Advice",
  "blog": "Blog",
  "career-advice": "Career Advice",
  "career-path": "Career Path",
  "case-study": "Case Study",
  "con": "Con",
  "interview-process": "Interview Process",
  "interview-questions": "Interview Questions",
  "job-description": "Job Description",
  "pro": "Pro",
  "region": "Region",
  "salary": "Salary",
  "industry": "Industry",
  "schedule": "Schedule",
  "study": "Study",
  "template": "Template",
  "work-life-balance": "Work Life Balance",
  "remote-work": "Remote Work"
}


export function GenerateLookups(filtersLookup) {
  let ret = {}


  for (let key in filtersLookup) { // e.g. reduced_hours
    let lookup = filtersLookup[key]
    for (let slug in lookup) {
      let cur = {
        key: key,
        value: slug,
        label: lookup[slug]
      }
      ret[slug] = cur;
    }
  }
  return ret;
}

export function ConvertLookupToOptions(lookup) {
  let ret = []
  for (let slug in lookup) {
    let cur = lookup[slug]
    ret.push({
      label: cur,
      value: slug
    })
  }
  return ret;
}


export function ConvertLookupToLabelOptions(lookup) {
  let ret = []
  for (let slug in lookup) {
    let cur = lookup[slug]
    ret.push({
      label: cur,
      value: cur
    })
  }
  return ret;
}



export const COUNTRIES_STRIPE = [
  { "label": "Afghanistan", "value": "Afghanistan" },
  { "label": "Albania", "value": "Albania" },
  { "label": "Algeria", "value": "Algeria" },
  { "label": "Andorra", "value": "Andorra" },
  { "label": "Angola", "value": "Angola" },
  { "label": "Antigua and Barbuda", "value": "Antigua and Barbuda" },
  { "label": "Argentina", "value": "Argentina" },
  { "label": "Armenia", "value": "Armenia" },
  { "label": "Australia", "value": "Australia" },
  { "label": "Austria", "value": "Austria" },
  { "label": "Azerbaijan", "value": "Azerbaijan" },
  { "label": "Bahamas", "value": "Bahamas" },
  { "label": "Bahrain", "value": "Bahrain" },
  { "label": "Bangladesh", "value": "Bangladesh" },
  { "label": "Barbados", "value": "Barbados" },
  { "label": "Belarus", "value": "Belarus" },
  { "label": "Belgium", "value": "Belgium" },
  { "label": "Belize", "value": "Belize" },
  { "label": "Benin", "value": "Benin" },
  { "label": "Bhutan", "value": "Bhutan" },
  { "label": "Bolivia", "value": "Bolivia" },
  { "label": "Bosnia and Herzegovina", "value": "Bosnia and Herzegovina" },
  { "label": "Botswana", "value": "Botswana" },
  { "label": "Brazil", "value": "Brazil" },
  { "label": "Brunei", "value": "Brunei" },
  { "label": "Bulgaria", "value": "Bulgaria" },
  { "label": "Burkina Faso", "value": "Burkina Faso" },
  { "label": "Burma", "value": "Burma" },
  { "label": "Burundi", "value": "Burundi" },
  { "label": "Cabo Verde", "value": "Cabo Verde" },
  { "label": "Cambodia", "value": "Cambodia" },
  { "label": "Cameroon", "value": "Cameroon" },
  { "label": "Canada", "value": "Canada" },
  { "label": "Central African Republic", "value": "Central African Republic" },
  { "label": "Chad", "value": "Chad" },
  { "label": "Chile", "value": "Chile" },
  { "label": "China", "value": "China" },
  { "label": "Colombia", "value": "Colombia" },
  { "label": "Comoros", "value": "Comoros" },
  { "label": "Congo (Brazzaville)", "value": "Congo (Brazzaville)" },
  { "label": "Congo (Kinshasa)", "value": "Congo (Kinshasa)" },
  { "label": "Costa Rica", "value": "Costa Rica" },
  { "label": "Cote d'Ivoire", "value": "Cote d'Ivoire" },
  { "label": "Croatia", "value": "Croatia" },
  { "label": "Cuba", "value": "Cuba" },
  { "label": "Cyprus", "value": "Cyprus" },
  { "label": "Czech Republic", "value": "Czech Republic" },
  { "label": "Denmark", "value": "Denmark" },
  { "label": "Djibouti", "value": "Djibouti" },
  { "label": "Dominica", "value": "Dominica" },
  { "label": "Dominican Republic", "value": "Dominican Republic" },
  { "label": "Ecuador", "value": "Ecuador" },
  { "label": "Egypt", "value": "Egypt" },
  { "label": "El Salvador", "value": "El Salvador" },
  { "label": "Equatorial Guinea", "value": "Equatorial Guinea" },
  { "label": "Eritrea", "value": "Eritrea" },
  { "label": "Estonia", "value": "Estonia" },
  { "label": "Ethiopia", "value": "Ethiopia" },
  { "label": "Fiji", "value": "Fiji" },
  { "label": "Finland", "value": "Finland" },
  { "label": "France", "value": "France" },
  { "label": "Gabon", "value": "Gabon" },
  { "label": "Gambia", "value": "Gambia" },
  { "label": "Georgia", "value": "Georgia" },
  { "label": "Germany", "value": "Germany" },
  { "label": "Ghana", "value": "Ghana" },
  { "label": "Greece", "value": "Greece" },
  { "label": "Grenada", "value": "Grenada" },
  { "label": "Guatemala", "value": "Guatemala" },
  { "label": "Guinea", "value": "Guinea" },
  { "label": "Guinea-Bissau", "value": "Guinea-Bissau" },
  { "label": "Guyana", "value": "Guyana" },
  { "label": "Haiti", "value": "Haiti" },
  { "label": "Honduras", "value": "Honduras" },
  { "label": "Hungary", "value": "Hungary" },
  { "label": "Iceland", "value": "Iceland" },
  { "label": "India", "value": "India" },
  { "label": "Indonesia", "value": "Indonesia" },
  { "label": "Iran", "value": "Iran" },
  { "label": "Iraq", "value": "Iraq" },
  { "label": "Ireland", "value": "Ireland" },
  { "label": "Israel", "value": "Israel" },
  { "label": "Italy", "value": "Italy" },
  { "label": "Jamaica", "value": "Jamaica" },
  { "label": "Japan", "value": "Japan" },
  { "label": "Jordan", "value": "Jordan" },
  { "label": "Kazakhstan", "value": "Kazakhstan" },
  { "label": "Kenya", "value": "Kenya" },
  { "label": "Kiribati", "value": "Kiribati" },
  { "label": "Kosovo", "value": "Kosovo" },
  { "label": "Kuwait", "value": "Kuwait" },
  { "label": "Kyrgyzstan", "value": "Kyrgyzstan" },
  { "label": "Laos", "value": "Laos" },
  { "label": "Latvia", "value": "Latvia" },
  { "label": "Lebanon", "value": "Lebanon" },
  { "label": "Lesotho", "value": "Lesotho" },
  { "label": "Liberia", "value": "Liberia" },
  { "label": "Libya", "value": "Libya" },
  { "label": "Liechtenstein", "value": "Liechtenstein" },
  { "label": "Lithuania", "value": "Lithuania" },
  { "label": "Luxembourg", "value": "Luxembourg" },
  { "label": "Macedonia", "value": "Macedonia" },
  { "label": "Madagascar", "value": "Madagascar" },
  { "label": "Malawi", "value": "Malawi" },
  { "label": "Malaysia", "value": "Malaysia" },
  { "label": "Maldives", "value": "Maldives" },
  { "label": "Mali", "value": "Mali" },
  { "label": "Malta", "value": "Malta" },
  { "label": "Marshall Islands", "value": "Marshall Islands" },
  { "label": "Mauritania", "value": "Mauritania" },
  { "label": "Mauritius", "value": "Mauritius" },
  { "label": "Mexico", "value": "Mexico" },
  { "label": "Micronesia", "value": "Micronesia" },
  { "label": "Moldova", "value": "Moldova" },
  { "label": "Monaco", "value": "Monaco" },
  { "label": "Mongolia", "value": "Mongolia" },
  { "label": "Montenegro", "value": "Montenegro" },
  { "label": "Morocco", "value": "Morocco" },
  { "label": "Mozambique", "value": "Mozambique" },
  { "label": "Namibia", "value": "Namibia" },
  { "label": "Nauru", "value": "Nauru" },
  { "label": "Nepal", "value": "Nepal" },
  { "label": "Netherlands", "value": "Netherlands" },
  { "label": "New Zealand", "value": "New Zealand" },
  { "label": "Nicaragua", "value": "Nicaragua" },
  { "label": "Niger", "value": "Niger" },
  { "label": "Nigeria", "value": "Nigeria" },
  { "label": "North Korea", "value": "North Korea" },
  { "label": "Norway", "value": "Norway" },
  { "label": "Oman", "value": "Oman" },
  { "label": "Pakistan", "value": "Pakistan" },
  { "label": "Palau", "value": "Palau" },
  { "label": "Palestine", "value": "Palestine" },
  { "label": "Panama", "value": "Panama" },
  { "label": "Papua New Guinea", "value": "Papua New Guinea" },
  { "label": "Paraguay", "value": "Paraguay" },
  { "label": "Peru", "value": "Peru" },
  { "label": "Philippines", "value": "Philippines" },
  { "label": "Poland", "value": "Poland" },
  { "label": "Portugal", "value": "Portugal" },
  { "label": "Qatar", "value": "Qatar" },
  { "label": "Romania", "value": "Romania" },
  { "label": "Russia", "value": "Russia" },
  { "label": "Rwanda", "value": "Rwanda" },
  { "label": "Saint Kitts and Nevis", "value": "Saint Kitts and Nevis" },
  { "label": "Saint Lucia", "value": "Saint Lucia" },
  { "label": "Saint Vincent and the Grenadines", "value": "Saint Vincent and the Grenadines" },
  { "label": "Samoa", "value": "Samoa" },
  { "label": "San Marino", "value": "San Marino" },
  { "label": "Sao Tome and Principe", "value": "Sao Tome and Principe" },
  { "label": "Saudi Arabia", "value": "Saudi Arabia" },
  { "label": "Senegal", "value": "Senegal" },
  { "label": "Serbia", "value": "Serbia" },
  { "label": "Seychelles", "value": "Seychelles" },
  { "label": "Sierra Leone", "value": "Sierra Leone" },
  { "label": "Singapore", "value": "Singapore" },
  { "label": "Slovakia", "value": "Slovakia" },
  { "label": "Slovenia", "value": "Slovenia" },
  { "label": "Solomon Islands", "value": "Solomon Islands" },
  { "label": "Somalia", "value": "Somalia" },
  { "label": "South Africa", "value": "South Africa" },
  { "label": "South Korea", "value": "South Korea" },
  { "label": "South Sudan", "value": "South Sudan" },
  { "label": "Spain", "value": "Spain" },
  { "label": "Sri Lanka", "value": "Sri Lanka" },
  { "label": "Sudan", "value": "Sudan" },
  { "label": "Suriname", "value": "Suriname" },
  { "label": "Swaziland", "value": "Swaziland" },
  { "label": "Sweden", "value": "Sweden" },
  { "label": "Switzerland", "value": "Switzerland" },
  { "label": "Syria", "value": "Syria" },
  { "label": "Taiwan", "value": "Taiwan" },
  { "label": "Tajikistan", "value": "Tajikistan" },
  { "label": "Tanzania", "value": "Tanzania" },
  { "label": "Thailand", "value": "Thailand" },
  { "label": "Timor-Leste", "value": "Timor-Leste" },
  { "label": "Togo", "value": "Togo" },
  { "label": "Tonga", "value": "Tonga" },
  { "label": "Trinidad and Tobago", "value": "Trinidad and Tobago" },
  { "label": "Tunisia", "value": "Tunisia" },
  { "label": "Turkey", "value": "Turkey" },
  { "label": "Turkmenistan", "value": "Turkmenistan" },
  { "label": "Tuvalu", "value": "Tuvalu" },
  { "label": "Uganda", "value": "Uganda" },
  { "label": "Ukraine", "value": "Ukraine" },
  { "label": "United Arab Emirates", "value": "United Arab Emirates" },
  { "label": "United Kingdom", "value": "United Kingdom" },
  { "label": "United States of America", "value": "United States of America" },
  { "label": "Uruguay", "value": "Uruguay" },
  { "label": "Uzbekistan", "value": "Uzbekistan" },
  { "label": "Vanuatu", "value": "Vanuatu" },
  { "label": "Vatican City", "value": "Vatican City" },
  { "label": "Venezuela", "value": "Venezuela" },
  { "label": "Vietnam", "value": "Vietnam" },
  { "label": "Yemen", "value": "Yemen" },
  { "label": "Zambia", "value": "Zambia" },
  { "label": "Zimbabwe", "value": "Zimbabwe" }

]
