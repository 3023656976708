import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import philImg from '../../assets/me-2.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';

class ArticleAuthor extends React.Component {

  render() {


    return (
      <div className="article-author">
        <div className='author-img'>
          <LazyLoadImage src={philImg} alt="Phil McParlane" />
        </div>
        <div className='author-description'>
          <h3 className='author-name'>Phil McParlane</h3>
          <h5 className='author-title'>Founder @ 4dayweek.io</h5>
          <p>Phil the founder of <Link to="/" className="btn btn-link btn-md">4dayweek.io</Link> - the world's largest job board for jobs with a better work-life balance. He completed his PhD in Computer Science before working as a Data Scientist & Software Engineer at Microsoft, Yahoo and Solarwinds. After feeling disengaged with the 9-5, Phil quit his job to promote the benefits of a shorter work week.</p>

          <ul className='author-social'>
            <li><a href="https://twitter.com/philostar" target="_blank" rel="noopener noreferrer" className='btn btn-link btn-lg'><FontAwesomeIcon icon={faTwitter} /> @philostar</a></li>
            <li><a href="https://www.linkedin.com/in/philmcp/" target="_blank" rel="noopener noreferrer" className='btn btn-link btn-lg'><FontAwesomeIcon icon={faLinkedin}/> LinkedIn </a></li>

          </ul>
        </div>
      </div>
    )
  }
}

export default ArticleAuthor;