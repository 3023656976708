import * as EmailValidator from 'email-validator';
import React from 'react';

class FieldTextareaInput extends React.Component {

	render() {
		var { id, isRequired, options, label, isEmail, isNumber, defaultValue, description, type, className, input} = this.props;

		var val = null;
		if (isEmail) {
			val = value => (EmailValidator.validate(value) ? undefined : 'Enter valid email')
		} else if (isRequired && isNumber) {
			val = value => (!value || isNaN(value) ? 'Must be a number' : undefined)
		} else if (isNumber) {
			val = value => (isNaN(value) ? 'Must be a number' : undefined)
		} else if (isRequired) {
			val = value => (value ? undefined : 'Required')
		}

		var extra = Object.assign({}, this.props);
		extra.className = null;



		return (

							<textarea {...input} {...extra} className={className}>
								{defaultValue}
							</textarea>


		);
	}
}

export default FieldTextareaInput;
