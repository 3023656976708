import LabelField from '../fields/LabelField';
import React from 'react';
class EditJobFormApply extends React.Component {



    render() {

        let { values } = this.props;

        return (
            <div className="post-job-box  job-post-form-apply">

                <h3 className="post-job-heading">Application Method</h3>



                <div className="row field-row">
                    <LabelField inputType="radio" id="apply_method" label={"Application Method"} singleCol={true} options={
                        [
                            { value: 'email', label: 'Email' },
                            { value: 'redirect', label: 'Redirect to external page' },
                        ]
                    } isRequired={true} />
                </div>

                {values.apply_method === 'email' &&
                    <div className="row field-row">
                        <LabelField inputType="text" id="apply_email" label={"Email address (not visible to applicants)"} description={"Enter the email address you'd like applications sent to"}  singleCol={true} isRequired={true} placeholder={"you@yourcompany.com"} />
                    </div>

                }

                {values.apply_method === 'redirect' &&
                    <div className="row field-row">
                        <LabelField inputType="url" id="apply_url" label={"Application URL"} isRequired={true}   singleCol={true} description={"Enter the webpage / form you'd like applications to be redirected to"} placeholder={"https://yoursite.com/careers/job-1"} />
                    </div>

                }





            </div>
        );
    }
}

export default EditJobFormApply;