import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';



class CompanyBenefits extends React.Component {

    render() {

        var { company: d } = this.props;
        var { featured, benefit_pension, benefit_healthcare, benefit_dentalcare, benefit_retreats, benefit_transparent_salaries, benefit_parental_leave, benefit_equity, benefit_flexible_working_hours, benefit_childcare, benefit_equipment } = d;

        if (!benefit_pension && !benefit_healthcare && !benefit_dentalcare && !benefit_retreats && !benefit_transparent_salaries && !benefit_parental_leave && !benefit_flexible_working_hours) {
            return "";
        }


        return (
            <div className="company-side-box company-benefits ">
                <h3>Company Benefits</h3>

                <ul>

                    {benefit_healthcare ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'heartbeat']} /></div>
                            <div className="benefit-title">{d.benefit_healthcare_label ? d.benefit_healthcare_label : "Health insurance"}</div>
                        </li> : ""}

                    {benefit_pension ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'hand-holding-usd']} /></div>
                            <div className="benefit-title">{d.benefit_pension_label ? d.benefit_pension_label : "401(k) company contribution"}</div>
                        </li> : ""}

                    {benefit_transparent_salaries ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'money-bill-alt']} /></div>
                            <div className="benefit-title">{d.benefit_transparent_salaries_label ? d.benefit_transparent_salaries_label : "Transparent Salaries"}</div>
                        </li>
                        : ""}

                    {benefit_flexible_working_hours ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'clock']} /></div>
                            <div className="benefit-title">{d.benefit_flexible_working_hours_label ? d.benefit_flexible_working_hours_label : "Flexible working hours"}</div>
                        </li>
                        : ""}


                    {benefit_parental_leave ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'baby-carriage']} className="green" /></div>
                            <div className="benefit-title">{d.benefit_parental_leave_label ? d.benefit_parental_leave_label : "Generous parental leave"}</div>
                        </li> : ""}

                    {benefit_dentalcare ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'tooth']} /></div>
                            <div className="benefit-title">{d.benefit_dentalcare_label ? d.benefit_dentalcare_label : "Dentalcare"}</div>
                        </li> : ""}


                    {benefit_retreats ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'sun']} className="green" /></div>
                            <div className="benefit-title">{d.benefit_retreats_label ? d.benefit_retreats_label : "Company retreats"}</div>
                        </li> : ""}



                    {benefit_equity ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'chart-line']} className="pink" /></div>
                            <div className="benefit-title">{d.benefit_equity_label ? d.benefit_equity_label : "Equity / options"}</div>
                        </li> : ""}



                    {benefit_childcare ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'child']} className="blue" /></div>
                            <div className="benefit-title">{d.benefit_childcare_label ? d.benefit_childcare_label : "Childcare support"}</div>
                        </li> : ""}

                    {benefit_equipment ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'laptop-code']} className="green" /></div>
                            <div className="benefit-title">{d.benefit_equipment_label ? d.benefit_equipment_label : "Equipment allowance"}</div>
                        </li> : ""}


                    {d.benefit_life_disability_insurance ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'wheelchair']} className="pink" /></div>
                            <div className="benefit-title">{d.benefit_life_disability_insurance_label ? d.benefit_life_disability_insurance_label : "Life & Disability Insurance"}</div>
                        </li> : ""}


                    {d.benefit_professional_development ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'chalkboard-teacher']} /></div>
                            <div className="benefit-title">{d.benefit_professional_development_label ? d.benefit_professional_development_label : "Professional Development Budget"}</div>
                        </li> : ""}


                    {d.benefit_bonuses ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'money-bill']} className="green" /></div>
                            <div className="benefit-title">{d.benefit_bonuses_label ? d.benefit_bonuses_label : "Yearly bonuses"}</div>
                        </li> : ""}


                    {d.benefit_sabbaticals ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'umbrella-beach']} className="blue" /></div>
                            <div className="benefit-title">{d.benefit_sabbaticals_label ? d.benefit_sabbaticals_label : "Opportunties for Sabbaticals"}</div>
                        </li> : ""}


                    {d.benefit_mental_health ?
                        <li>
                            <div className="benefit-icon"><FontAwesomeIcon icon={['fas', 'brain']} className="pink" /></div>
                            <div className="benefit-title">{d.benefit_mental_health_label ? d.benefit_mental_health_label : "Mental Health Support"}</div>
                        </li> : ""}


                    {/*}         <li>
                        <div className="benefit-icon"></div>
                        <div className="benefit-title">And more...</div>
                    </li>*/}


                </ul>
            </div>

        )
    }
}


export default CompanyBenefits;