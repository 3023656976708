import React, { Component } from 'react';

class JobCopilotHero extends Component {
  render() {
    return (
      <div className="job-copilot-hero">
        <div className="content">
          <h1>
            <span>Get 10x more</span>
            <span>Job Interviews with</span>
            <span>JobCopilot.</span>
          </h1>
          <p className="text-center">Use AI to automatically apply to jobs from 300k companies worldwide.</p>
          <div className="text-center">
            <a
              className="cop-btn btn-signup"
              href="https://4dayweek.jobcopilot.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try it now
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default JobCopilotHero;
