
import React from 'react';
import ApplicationWrapper from '../application-form/ApplicationWrapper';
import JobTileCompany from './job/JobTileCompany';
import JobTileCompanyImage from './job/JobTileCompanyImage';
import JobTileListing from './job/JobTileListing';
import JobTilePosted from './job/JobTilePosted';
import JobTileTags from './job/JobTileTags';
import JobTileTitle from './job/JobTileTitle';
import posthog from 'posthog-js';

class JobTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            hovering: false,
        };
    }
    onClick(e) {
        posthog.capture('job_tile_clicked', { job_id: this.props.data.id });
        this.setState({ expanded: !this.state.expanded, selected: !this.state.selected });
    }



    render() {
        var { data, page } = this.props;
        var { expanded, hovering } = this.state;

        let highlightClass = "";

        if(data.highlight_color || data.featured){
            highlightClass = " job-highlighted ";
        }

        return (
            <>
                <div
                    className={"job-tile-wrapper " + (expanded ? " expanded " : "") + (hovering ? " hovering " : "") + (data.featured ? highlightClass : "")}
                    onClick={(e) => this.onClick(e)}
                    key={data.url}
                    onMouseEnter={() => this.setState({ hovering: true })}
                    onMouseLeave={() => this.setState({ hovering: false })}
                    onFocus={() => this.setState({ hovering: true })}
                >

                    <JobTileCompanyImage {...this.props} />

                    <div className="row job-tile" >
                        <div className="job-tile-left col-sm-9">
                            <JobTileCompany {...this.props} />
                            <JobTileTitle {...this.props} />


                            <JobTileTags {...this.props} />
                        </div>

                        <div className='job-tile-right col-sm-3'>

                            {/*<p className="job-tile-salary">{data.salary}</p>*/}
                            <JobTilePosted {...this.props} />
                            {!expanded ? <ApplicationWrapper job={this.props.data} position={"job_tile_top"} /> : ""}

                        </div>



                    </div>

                </div>
                {expanded ? <JobTileListing {...data} page={page} onToggle={() => this.onToggle()} /> : ""}
            </>
        )
    }
}

export default JobTile;