import React from 'react';
import { Link } from 'react-router-dom';
class InterviewQuestionsTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        var { data } = this.props;

        var url = "/interview-questions/" + data.slug;

        return (
            <li>
                <Link to={url} className={"btn btn-link btn-lg"}>
                    {data.title}
                </Link>
            </li>
        );
    }
}

export default InterviewQuestionsTile;