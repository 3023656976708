import JobCopilotButton from '../../job-page/JobCopilotButton';
import React from 'react';

class JobTileApplyBottom extends React.Component {

    render() {
        var { is_expired, featured, is_remote, location_city, location_country, location_continent, company, apply_url, url, apply_email, application_template } = this.props.job;


        if (is_expired) {
            return <div className="job-tile-expired">
                This role is now closed
            </div>
        } else {
            var apply_location = "";

            // Location
            if (is_remote && location_city) {
                apply_location = location_city + " only";
            } else if (is_remote && location_country) {
                apply_location = location_country + " only";
            } else if (is_remote && location_continent) {
                apply_location = location_continent + " only";
            }

            if (company && company.remote_relocation_budget) {
                apply_location = apply_location.replace(" only", "") + " / relocate";
            }

            if (apply_location) {
                apply_location = <span className='apply-location'>{apply_location}</span>
            }


            let content = "";
            let jobUrl = "";

            if (url && (!application_template && !apply_email)) {
                jobUrl = url + "/apply";
            } else if (url) {
                jobUrl = url;
            } else if (apply_url) {
                jobUrl = apply_url;
            } else {
                jobUrl = "#";
            }

            content = <a href={jobUrl} onMouseDown={(e) => this.props.onClick(e)} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-success job-tile-apply btn-huge cta" >Apply now {apply_location}</a>;

            return (
                <div className='tile-apply-bottom'>
                    {content}

                    <JobCopilotButton job={this.props.job} />
                    {/*} <div className='ai'>
                        <a href="https://aiapply.co/resume-builder?via=philip" target='_blank' rel={"noopener noreferrer nofollow"} className='btn btn-link btn-lg resume-btn'>AI Resume Builder</a>
                    </div>*/}
                </div>
            )
        };



    }
}

export default JobTileApplyBottom;