import React from 'react';

class FieldPasswordInput extends React.Component {

	render() {
		var { input } = this.props;

		return (
			<>
				<input type="password" {...input}  {...this.props} />
			</>
		);
	}
}

export default FieldPasswordInput;
