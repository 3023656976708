
import ApplicationModal from './ApplicationModal';
import JobApplyBottom from '../job-page/JobApplyBottom';
import JobApplyTop from '../job-page/JobApplyTop';
import React from 'react';
import * as itemActions from '../../store/item/actions';
import { connect } from 'react-redux';
import JobTileApplyTop from '../tiles/job/JobTileApplyTop';

import { withRouter } from 'react-router-dom';
import JobTileApplyBottom from '../tiles/job/JobTileApplyBottom';
import { IsLoggedIn } from '../../utils/user';
import JobApplyMiddle from '../job-page/JobApplyMiddle';
import posthog from 'posthog-js';
import * as Fathom from 'fathom-client';
import JobTileApplyMiddle from '../tiles/job/JobTileApplyMiddle';


// Accepts a "type" props (job, job_tile)
class ApplicationWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }


    onClose() {
        this.setState({ showModal: false });
    }

    onClick(e) {
        let { user, job } = this.props;

        let { application_template, apply_email, slug, url } = job;

        let userData = user && user.requested && !user.loading && user.response ? user.response : {};

        console.log("Clicked on apply", job,  userData, application_template)

        if (application_template || apply_email) {
            // 1. Native application (4dayweek.io)
            if (!IsLoggedIn(user)) {
                // 1.a Not logged in
                console.log("Redirecting to sign up")
                let signupSlug = encodeURIComponent(slug + "-" + job.company.slug);
                localStorage.setItem("signup_job_slug", signupSlug);
                this.props.history.push("/sign-up?unauthorized=true");
            } else if (!userData.full_name) {
                // 1.b Incomplete profile
                console.log("Redirecting to complete profile")
                this.props.history.push("/sign-up/complete?profileincomplete=true");
            } else if (!job.apply_url && application_template && (!application_template.boolean_qualifiers || application_template.boolean_qualifiers.length == 0) && (!application_template.select_qualifiers || application_template.select_qualifiers.length == 0) && (!application_template.num_qualifiers || application_template.num_qualifiers.length == 0) && (!application_template.fields || application_template.fields.length == 0)) {
                // 1.c No questions to ask (e.g. a worldwide application), so just redirect
                console.log("Redirecting to job application")
                this.props.history.push(url + "/apply");
                this.logApplication('internal');
            } else if (e.button !== 1) {
                // 1.d Show application modal
                e.preventDefault();
                e.stopPropagation();
                this.setState({ showModal: true });
            }
        } else {
            // 2. Redirect to job application form
            this.logApplication('external')
        }

    }

    logApplication(t = 'external') {
        let { user, job } = this.props;
        let { id_str } = job;

        let clickData = { 'page': this.props.position, 'action': 'apply_job', 'item_id': id_str };
        if (IsLoggedIn(user)) {
            clickData['user_id'] = user.response.id_str;
        }
        // Redirect application
        this.props.newClick(clickData);

        Fathom.trackEvent('Apply start (' + t + ')');
        posthog.capture('Apply start (' + t + ')');
    }


    render() {
        let { position } = this.props;
        var { showModal } = this.state;


        let btn = null;

        switch (position) {
            case "job_page_top":
                btn = <JobApplyTop {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            case "job_page_middle":
                btn = <JobApplyMiddle {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            case "job_page_bottom":
                btn = <JobApplyBottom {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            case "job_tile_bottom":
                btn = <JobTileApplyBottom {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            case "job_tile_top":
                btn = <JobTileApplyTop {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            case "job_tile_middle":
                btn = <JobTileApplyMiddle {...this.props} onClick={(e) => this.onClick(e)} />
                break;
            default:
                btn = "";
                break;
        }

        return (

            <div className="row application-apply" onClick={(e) => e.stopPropagation()}>
                {showModal ? <ApplicationModal onClose={(e) => this.onClose(e)}  {...this.props} /> : ""}

                {btn}
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.item.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

ApplicationWrapper = connect(mapStateToProps, mapDispatchToProps)(ApplicationWrapper);
export default withRouter(ApplicationWrapper);