import React from 'react';

const ResumeReviewFeatures = () => {
    return (
        <div className="resume-review-features job-copilot-features">
            <div className="content">
                <h2>Comprehensive Resume Analysis</h2>
                <h6>Our AI analyzes your resume across multiple dimensions to help you improve</h6>

                <div className="jobcopilot-features-items">
                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-search"></i>
                        </div>
                        <div className="info">
                            <h4>ATS Compatibility</h4>
                            <p>Ensure your resume can be properly read by Applicant Tracking Systems</p>
                        </div>
                    </div>

                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-key"></i>
                        </div>
                        <div className="info">
                            <h4>Keyword Analysis</h4>
                            <p>Identify missing industry and job-specific keywords to improve visibility</p>
                        </div>
                    </div>

                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-line-chart"></i>
                        </div>
                        <div className="info">
                            <h4>Impact Measurement</h4>
                            <p>Evaluate how effectively you've quantified your achievements</p>
                        </div>
                    </div>

                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-check-square-o"></i>
                        </div>
                        <div className="info">
                            <h4>Content Quality</h4>
                            <p>Check for clichés, passive voice, and weak language patterns</p>
                        </div>
                    </div>

                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-file-text-o"></i>
                        </div>
                        <div className="info">
                            <h4>Format & Structure</h4>
                            <p>Review layout, section organization, and overall visual appeal</p>
                        </div>
                    </div>

                    <div className="jobcopilot-features-item">
                        <div className="img-mask">
                            <i className="fa fa-lightbulb-o"></i>
                        </div>
                        <div className="info">
                            <h4>Improvement Suggestions</h4>
                            <p>Get actionable recommendations to enhance your resume</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewFeatures; 