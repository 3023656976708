import React from 'react';
import { Link } from 'react-router-dom';

class JobTileCompany extends React.Component {



    render() {
        var { data } = this.props;

        if(!data || !data.company){
            return "";
        }

    

        if(data.company.reduced_hours == "Part time"  && !data.company.featured){
            return  <div className="row job-tile-company no-link"><h5>{data.company.name}</h5></div>

        }
        var company_url = "/company/" + data.company.slug + "/jobs";

        if(!data.company.slug){
            company_url = "#"
        }

        return (
            <div className="row job-tile-company">
                <h5>   <Link className="btn btn-link dark btn-md" to={company_url}>{data.company.name}</Link></h5>
            </div>
        );


    }
}

export default JobTileCompany;