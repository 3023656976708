import React from 'react';
import { Link } from 'react-router-dom';
import * as TimeTools from '../../utils/time-tools';
import * as FormatTools from '../../utils/format';
class ArticleTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        var { data, fullWidth } = this.props;

        var url = (data.topic ? "/" + FormatTools.Slugify(data.topic) + "/" : "/article/") + data.slug;

        return (
            <Link to={url} className={(fullWidth ? "article-item-full" : "") + " article-item "}>
                {data.header_image ?
                <div className={"article-image-wrapper "}>

                    <div style={{ backgroundImage: `url(${data.header_image})` }} className="article-image" />
                </div> : ""}
                <div className={(!fullWidth ? "article-info-full" : "") + " article-info"}>
                    <div className="article-meta">
                        <h3>{data.read_time}</h3>
                        <h3>{TimeTools.FullDate(data.posted * 1000)}</h3>
                    </div>
                    <h2 className="article-title">
                        {data.title}
                    </h2>
                    <p className="article-page-subtitle">
                        {data.sub_title}
                    </p>
                </div>


            </Link>
        );
    }
}

export default ArticleTile;