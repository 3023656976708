import * as EmailValidator from 'email-validator';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import FieldCheckbox from './FieldCheckbox';
import FieldPasswordInput from './FieldPasswordInput';
import FieldRadio from './FieldRadio';
import FieldTextareaInput from './FieldTextareaInput';
import FieldTextInput from './FieldTextInput';
import FieldCheckboxes from './FieldCheckboxes';
import loadable from '@loadable/component'
import FieldBooleanQualifier from './FieldBooleanQualifier';
import FieldNumQualifier from './FieldNumQualifier';
import FieldSelectQualifier from './FieldSelectQualifier';
import FieldMultiSelect from './FieldMultiSelect';
import FieldSelect from './FieldSelect';
import FieldFileInput from './FieldFileInput';

const FieldMarkdownInput = loadable(() => import("./FieldMarkdownInput"), { ssr: true });
const FieldList = loadable(() => import("./FieldList"), { ssr: true });


export default class LabelField extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	renderInput(input) {
		var { inputType } = this.props;

		var ret = "";
		switch (inputType) {
			case "select":
				ret = <FieldSelect {...this.props} input={input} />;
				break;
			case "multiselect":
				ret = <FieldMultiSelect {...this.props} input={input} />;
				break;
			case "text":
				ret = <FieldTextInput {...this.props} input={input} />;
				break;
			case "url":
				ret = <FieldTextInput {...this.props} input={input} />;
				break;
			case "number":
				ret = <FieldTextInput {...this.props} input={input} />;
				break;
			case "email":
				ret = <FieldTextInput {...this.props} input={input} />;
				break;
			case "password":
				ret = <FieldPasswordInput {...this.props} input={input} />;
				break;
			case "textarea":
				ret = <FieldTextareaInput {...this.props} input={input} />;
				break;
			case "checkbox":
				ret = <FieldCheckbox {...this.props} input={input} />;
				break;
			case "radio":
				ret = <FieldRadio {...this.props} input={input} />;
				break;
			case "file":
				ret = <FieldFileInput {...this.props} input={input} />;
				break;
			case "markdown":
				ret = <FieldMarkdownInput {...this.props} input={input} />;
				break;
			case "array":
				ret = <FieldList {...this.props} input={input} />;
				break;

			case "boolean_qualifiers":
				ret = <FieldBooleanQualifier {...this.props} input={input} />;
				break;
			case "num_qualifiers":
				ret = <FieldNumQualifier {...this.props} input={input} />;
				break;
			case "select_qualifiers":
				ret = <FieldSelectQualifier {...this.props} input={input} />;
				break;
			case "checkboxes":
				ret = <FieldCheckboxes {...this.props} input={input} />;
				break;

		}
		return ret;
	}

	isValidURL(urlString) {
		var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
			'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
			'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
			'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
			'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
			'(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
		let out = !!urlPattern.test(urlString);
		return out;
	}

	validate() {
		var { isRequired, type, isEmail, isNumber, inputType, maxCharacters } = this.props;
		var val = null;

		if (!isRequired) {
			return val;
		}
		if (isEmail || inputType == 'email') {
			val = value => (EmailValidator.validate(value) ? undefined : 'Please enter a valid email')
		} else if (isRequired && (isNumber || inputType == 'number')) {
			val = value => (!value || isNaN(value) ? 'Must be a number' : undefined)
		} else if (isNumber || inputType == 'number') {
			val = value => (isNaN(value) ? 'Must be a number' : undefined)
		} else if (isRequired && inputType == 'url') {
			val = value => (!value || !this.isValidURL(value) ? 'Please enter a URL' : undefined)
		} else if (isRequired && inputType != 'radio') {
			val = value => (value ? undefined : 'Required')
		}

		if (type == "password") {
			val = value => (value && value.length >= 8 ? undefined : '8+ characters')
		}

		if (maxCharacters) {
			val = value => (!value || value.length <= maxCharacters ? undefined : 'Max ' + maxCharacters + ' characters')
		}

		return val;
	}

	parseData(value) {
		var { isNumber } = this.props;
		if (isNumber) {
			return parseFloat(value);
		} else if (value == "true" || value == "false") {
			return value == "true";
		} else {
			return value
		}
	}

	render() {


		var { id, isRequired, labelPos, labelClass, label, defaultValue, description, extra, hideErrors, singleCol, inlineCol, className, splitWidth, inputType, showOptional } = this.props;

		var topRightText = ""
		if (isRequired) {
			topRightText = <span className="required">*</span>;//<span className="small">optional</span>;
		} else if (showOptional) {
			topRightText = <span className="optional">Optional</span>;
		}

		var val = this.validate();

		var content = "";

		if (singleCol) {
			content = <Field name={id} validate={val} defaultValue={defaultValue} parse={(value) => this.parseData(value)}> 
				{({ input, meta }) => (
					<div className={"label-container single-col label-"+inputType}>
						{(label && (!labelPos || labelPos == 'left')) ? <label htmlFor={id} className={labelClass + " padded-right-small"}>{label}{topRightText} </label> : <div className="label-placeholder"></div>} {val && meta.touched && !hideErrors ? <span className="error small">{meta.error}</span> : ""}

						{inputType == "checkbox" ? this.renderInput(input) : ""}
						{description ? <p className="small">
							{description}
						</p> : ""}
						{inputType != "checkbox" ? this.renderInput(input) : ""}
						{(label && labelPos && labelPos == 'right') ? <label htmlFor={id} className={labelClass}>{label} {val && meta.touched ? <span className="error small">{meta.error}</span> : ""}{topRightText} </label> : ""}
						{/*}	<pre>{JSON.stringify(meta, 0, 2)}</pre>*/}
					</div>
				)}
			</Field>
		} else {
			content = <Field name={id} validate={val} defaultValue={defaultValue} parse={(value) => this.parseData(value)}>
				{({ input, meta }) => (
					<>
						<div className={splitWidth ? "col-sm-6 cols cols-sm label-container" : "col-sm-4 cols label-container cols-sm"}>
							{(label && (!labelPos || labelPos == 'left')) ? <label htmlFor={id} className={labelClass}>{label}{topRightText} </label> : ""} {val && meta.touched && !hideErrors ? <span className="error small">{meta.error}</span> : ""}

							{description ? <p className="small label-container">
								{description}
							</p> : ""}
						</div>
						<div className={splitWidth ? "col-sm-6 cols cols-sm " : "col-sm-8 cols cols-sm"}>

							{this.renderInput(input)}
							{(label && labelPos && labelPos == 'right') ? <label htmlFor={id} className={labelClass}>{label} {val && meta.touched ? <span className="error small">{meta.error}</span> : ""}{topRightText} </label> : ""}
							{/*}	<pre>{JSON.stringify(meta, 0, 2)}</pre>*/}
						</div>
					</>
				)}
			</Field>
		}

		return (
			<div className={(!inlineCol ? "row " : "") + " field-row " + className}>
				{content}
				{extra ? <div className="extra">{extra}</div> : ""}
			</div >
		);
	}
}