import LabelField from '../fields/LabelField';
import React from 'react';
class EditJobFormJobDescription extends React.Component {



    render() {


        return (
            <div className="post-job-box job-post-form-job-description">

                <h3 className='post-job-heading'>Job Details</h3>

                <div className="row field-row">
                    <LabelField inputType="text" id="title" label={"Job Title"} isRequired={true} singleCol={true} placeholder={"e.g. Software Developer"} />
                </div>

                <div className="row field-row ">
                    <LabelField inputType="radio" id="reduced_hours" label={"Working Policy"} singleCol={true} options={
                        [
                            { value: 'Offered', label: '4 day work week (no salary reduction)' },
                            { value: 'Would consider', label: '4 day work week (80% salary)' },
                            { value: 'Offered every 2nd week', label: '4 day work week every 2nd week' },
                            { value: 'Offered 4.5 days', label: '4.5 day work week' },
                            { value: 'Offered summer', label: '4 day work week during summer months' },
                            { value: '9 day fortnight', label: '9 day fortnight e.g. 9/80 schedule' },
                            { value: 'Flex Fridays', label: '5 day work week with "flexible fridays" i.e. "no meetings + rest if you like"' },
                            { value: 'Flexible hours', label: '5 day work week with fully flexible hours' },
                            { value: 'Generous PTO', label: '5 day work week with 40+ days vacation per year' },
                            { value: 'Part time', label: "Part time" },
                            { value: 'Other', label: 'Other' },

                        ]} isRequired={true} />
                </div>

                <div className="row field-row">
                    <LabelField inputType="number" id="hours" isNumber={true} label={"Hours / week"} max={40} description="How many hours is the candidate expected to work in a typical week?" isRequired={true} singleCol={true} placeholder={"e.g. 32"} />
                </div>




                <div className="row field-row">
                    <LabelField inputType="markdown" id="description_html" label={"Job description"} isRequired={true} singleCol={true} placeholder={"What skills are required? Who will they report to? etc"} />
                </div>




                <div className="row field-row">
                    <LabelField inputType="select" isClearable={true} singleCol={true} id="remote_level" label={"Remote level"} options={[
                        { label: '100% remote', value: '100% remote' },
                        { label: 'Hybrid remote', value: 'Offers remote' },
                        { label: '100% in-office', value: 'Onsite' },
                    ]
                    } isRequired={true} />
                </div>

                <div className="row field-row">
                    <LabelField inputType="text" id="location_original" label={"Job Location"} singleCol={true} description="Enter the location as free text. Some examples could be '100% remote', 'Amsterdam', 'USA only' etc" isRequired={true} placeholder={"e.g. 100% remote (anywhere), Amsterdam (on-site), remote (USA only) etc"} />
                </div>




                <div className="row field-row">
                    <div className='col-sm-12 cols'>
                        <div className='label-container'>
                            <label for="company_name">Salary Range</label>
                        </div>
                        <div className='row'>
                            <div className='col-sm-2 cols quad'>
                                <LabelField inputType="select" id="salary_currency" singleCol={true} label={""} options={[
                                    { value: '$', label: '$' },
                                    { value: '£', label: "£" },
                                    { value: 'C$', label: "C$" },
                                    { value: '€', label: "€" },
                                    { value: '¥', label: "¥" },
                                    { value: '₹', label: "₹" },
                                    { value: '₽', label: "₽" },
                                    { value: '₩', label: "₩" },
                                    { value: '₿', label: "₿" },
                                    { value: '฿', label: "฿" },
                                ]} />

                            </div>

                            <div className='col-sm-3 cols quad'>
                                <LabelField inputType="text" id="salary_lower" label={""} isRequired={false} singleCol={true} isNumber={true} placeholder={"50,000"} />


                            </div>
                            <div className='col-sm-1 cols quad salary-to'>
                                -
                            </div>


                            <div className='col-sm-3 cols quad'>

                                <LabelField inputType="text" id="salary_upper" label={""} isRequired={false} singleCol={true} isNumber={true} placeholder={"80,000"} />


                            </div>


                            <div className='col-sm-3 cols quad'>

                                <LabelField inputType="select" id="salary_period" singleCol={true} label={""} options={[
                                    { value: 'per year', label: 'per year' },
                                    { value: 'per month', label: 'per month' },
                                    { value: 'per week', label: 'per week' },
                                    { value: 'per day', label: 'per day' },
                                    { value: 'per hour', label: 'per hour' },
                                ]} />
                            </div>
                        </div>

                    </div>

                </div>








            </div>
        );
    }
}

export default EditJobFormJobDescription;