
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from '../misc/Loader';

class ArticleAdvertAltTile extends React.Component {

    render() {

        let { advert } = this.props;


        if (!advert || !advert.requested || !advert.response) {
            return "";
        } else if (advert && advert.loading) {
            return <Loader />
        }
        var data = advert.response;


        let htmlTags = [];

        if (data && data.tags && data.tags.length > 0) {
            htmlTags = data.tags.map((tag) => {
                return <li>{tag.label}</li>
            });
        }


        return (
            <>
                <a className={"article-notad-alt-tile-wrapper "} key={"article-ad-" + data.id_str} href={data.url} onClick={(e) => this.props.handleClick(e)} rel="noopener sponsored noreferrer nofollow" target="_blank">





                    <div className="article-notad-alt-tile-content-wrapper">
                        <h4>{data.company_name}</h4>
                        <h5>{data.title}</h5>
                        <p>{data.description}</p>

                        <div className='row padded-top'>
                            <a href={data.url} className='article-notad-alt-tile-link btn btn-success cta btn-huge' rel="noopener sponsored noreferrer nofollow" target="_blank">{data.cta}</a>
                         
                        </div>
                        <ul className="article-notad-alt-tile-tags">
                                {htmlTags}
                            </ul>
                    </div>

                    <div className='article-notad-alt-tile-image-wrapper'>
                        <LazyLoadImage
                            //  style={{ backgroundImage: `url(${data.company.logo_url})` }} 
                            className="article-notad-alt-tile-image"
                            alt={`${data.title} logo`}
                            threshold={100}

                            src={data.image_url} // use normal <img> attributes as props
                        />
                      
                    </div>








                </a >

            </>
        );
    }
}



export default ArticleAdvertAltTile;