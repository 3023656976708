import React from 'react';
import { Form } from 'react-final-form';

import arrayMutators from 'final-form-arrays';
import ApplicationFormFields from './ApplicationFormFields';
import ApplicationFormQualifiers from './ApplicationFormQualifiers';

class ApplicationForm extends React.Component {

    render() {
        let {job, user} = this.props;


        let userData = { }

        if(user && user.requested && !user.loading && user.response){
            userData = user.response;
        }

        return (


            <Form
                onSubmit={(values) => this.props.onSubmit(values)}
                mutators={{
                    ...arrayMutators,
                    changeResume: (args, state, utils) => {
                        utils.changeValue(state, 'resume_upload', () => args.length > 0 ? args[0] : [])
                    },

                }}
                initialValues={{
                    job_id: this.props.job.id_str,
                    name: userData.full_name ? userData.full_name : "",
                    email: userData.email ? userData.email : "",
                    website_url: userData.website_url ? userData.website_url : userData.linkedin_url ? userData.linkedin_url : userData.github_url ? userData.github_url : userData.twitter_url ? userData.twitter_url : "",
                 //   website_url: "https://www.google.com",
                 //   name: "blah blah",
                    subscribe: true,
                }}
                render={({ handleSubmit, submitting, errors, form, values }) => (
                    <form onSubmit={handleSubmit} data-sparkloop-upscribe='false'>

                        <div className="modal-body">
                            <div className="row padded-small">

                                <ApplicationFormFields {...this.props} form={form} />
                                <ApplicationFormQualifiers {...this.props} form={form} />
                     
                            </div>

                        </div>
                        <div className="modal-footer">

                            <button type="submit" disabled={submitting || Object.keys(errors).length > 0} className="btn btn-huge btn-success cta">{job && job.apply_email ? "Submit application" : "Continue →"}</button>
                        </div>



                  {/*     <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                    </form>
                )}
            />
        );
    }
}


export default ApplicationForm;