import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { Link } from 'react-router-dom';

class PostAJobCancelled extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	render() {

		var metaTitle = "Order cancelled · 4 Day Week Jobs";
		var metaDesc = "Please get in touch if you have any questions or issues.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/not-found";

		return (
			<div className="main-container medium-width not-found">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />


				<div className="not-found">

			
					<section className="row hero section-header">
						<div className="col-sm-8 hero-left">
							<h1>Your order has been cancelled.</h1>
							<h3>{metaDesc}</h3>
							<Link to="/contact" className="btn btn-success cta btn-huge margin-top-large">Contact us</Link>
						</div>

					
					</section>

				</div>
			</div >
		);
	}
}

export default PostAJobCancelled;