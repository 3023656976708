
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import RemoteWorksList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class RemoteWorks extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_remote_works"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(RemoteWorks.page, RemoteWorks.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(RemoteWorks.page + "_" + RemoteWorks.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: RemoteWorks.page,
			id: RemoteWorks.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "Remote Work Guides";
		var metaDesc = "We discuss everything you need to know about remote work, including the best countries to work remotely, the best cities to work remotely, the best jobs to work remotely, and the best tools to work remotely.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/remote-work";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<RemoteWorksList data={data} settings={settings} />
				</section>




				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[RemoteWorks.page + "_" + RemoteWorks.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(RemoteWorks.page, RemoteWorks.id));
	},


});

RemoteWorks = connect(mapStateToProps, mapDispatchToProps)(RemoteWorks);

export default RemoteWorks;