import React from 'react';

import { CATEGORIES_LOOKUP, COUNTRIES, ConvertLookupToOptions, USER_ROLES, YEARS_EXPERIENCE } from '../../utils/filters';
import LabelField from '../fields/LabelField';

class ProfileBasicFields extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			submitted: false,

			categories: ConvertLookupToOptions(CATEGORIES_LOOKUP),
			countries: COUNTRIES,
			roles: ConvertLookupToOptions(USER_ROLES),
			yearsExperience: ConvertLookupToOptions(YEARS_EXPERIENCE),
		};
	}

	render() {
		var { categories, countries, yearsExperience, roles } = this.state;
		var { form, jobRoles, user } = this.props;


		// Create a copy of jobRoles
		let roleOptions = Object.assign([], jobRoles);
		roleOptions.push({ value: "other", label: "-------" })
		roleOptions.push({ value: "other", label: "Other (job role not listed)" })

		return (
			<div className='profile-form'>
				<div className='row form-section'>

					<div className="col-sm-4 cols form-meta">
						<h4>Basic info</h4>
						<p>Who are you and how should we contact you</p>
					</div>
					<div className="col-sm-8 cols  form-fields">
						<LabelField inputType="email" id="email" label={"Email"} singleCol={true} disabled={true} placeholder="Enter your email address..." isRequired={true} disabled={true} />
						<LabelField inputType="text" id="full_name" singleCol={true} label={"Your name"} placeholder="Please enter your full name" isRequired={true} />
						<LabelField inputType="select" id="country" singleCol={true} label={"Where are you based?"} placeholder="i.e. the country you are working from" isSearchable={true} isRequired={true} options={countries} />
						<LabelField inputType="select" id="role" singleCol={true} label={"What describes you best?"} placeholder="Please select" isRequired={true} options={roles} />
					</div>

				</div>


				{this.props.form.getState().values.role != "company" ?

					<>
						<div className='row form-section'>

							<div className="col-sm-4 cols  form-meta">
								<h4>Job search</h4>
								<p>What do you specialise in and what jobs would you like to see</p>
							</div>
							<div className="col-sm-8 cols  form-fields">
								<LabelField inputType="select" id="job_primary" singleCol={true} label={"Select your primary job"} placeholder="The job you'd prefer to work" isSearchable={true} isRequired={true} options={roleOptions} />
								<LabelField inputType="text" id="job_primary_experience" singleCol={true} isNumber={true} label={"Years of experience"} placeholder="Years" isSearchable={false} isRequired={true} />
								<LabelField inputType="select" id="job_categories" isMulti singleCol={true} label={"What jobs are you interested in?"} placeholder="What job types are you interested in?" isSearchable={true} isRequired={true} options={categories} />
								{/*}	<LabelField inputType="checkbox" className="no-padding" id="permission_to_contact" label={"Allow companies to contact me about new jobs"} singleCol={true} isRequired={false} />*/}

							</div>
						</div>

						<div className='row form-section'>

							<div className="col-sm-4 cols  form-meta">
								<h4>Social profiles</h4>
								<p>Add your social profiles to make it faster to apply for jobs</p>
							</div>
							<div className="col-sm-8 cols  form-fields">
								<LabelField inputType="url" id="website_url" singleCol={true} label={"Your website or portfolio"} placeholder="https://www.mysite.com" isRequired={false} showOptional />
								<LabelField inputType="url" id="linkedin_url" singleCol={true} label={"LinkedIn"} placeholder="https://www.linkedin.com/in/philmcp/" isRequired={false} showOptional />
								<LabelField inputType="url" id="twitter_url" singleCol={true} label={"Twitter"} placeholder="https://www.twitter.com/philostar" isRequired={false} showOptional />
								<LabelField inputType="url" id="github_url" singleCol={true} label={"GitHub"} placeholder="https://github.com/philmcp" isRequired={false} showOptional />

							</div>
						</div>
					</>
					: ""}

				{/*}	
					
					
					
					
						{/*}

				<div className='row form-section'>

					<div className="col-sm-4 cols  form-meta">
						<h4>Resume</h4>
						<p>Upload your resume to make it faster to apply for jobs</p>
					</div>
					<div className="col-sm-8 cols  form-fields">
						<LabelField
							inputType="file"
							multiple={false}
							id={"resume_upload"}
							isRequired={false}
							singleCol={true}
							label={"Upload your resume"}
							onChange={form.mutators.changeResume}
							accepted={['application/pdf'/*, 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/vnd.oasis.opendocument.text']}
							helper="Upload your resume (PDF)"
							icon={faFileUpload} />

						<LabelField inputType="checkbox" className="no-padding" id="get_resume_review" label={"Get a free resume review"} singleCol={true} isRequired={false} />

					</div>
				</div>

		<pre>
					{JSON.stringify(this.props.form.getState().values, 0, 2)}
				</pre>*/}
			</div >
		);
	}
}

export default ProfileBasicFields;