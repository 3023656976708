import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import parkinsonsLaw from '../../assets/parkinsons-law.png';
import { Link } from 'react-router-dom';

class ProsAndConsCompany extends React.Component {


    render() {


        return (

            <section className="row hero pros-and-cons">


                <div className='row pro-con-title pro-con-company'>
                    <h3 className=''>📈 Pros and Cons for the Company</h3>

                </div>
                <div className="row pro-con">
                    <div className="col-sm-2 cols pro-con-number ">
                        1.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Increased Productivity / Output Doesn't Drop</h4>
                        <p>Hundreds of companies have now participated in various 4 day week pilots. The result: we just naturally work more efficiently (known as "Parkinson's Law") and most importantly: <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" className="btn btn-link normal btn-lg" rel="noopener noreferrer nofollow" target="_blank">revenue doesn't drop</a>.</p>
                        <div className="pro-con-link">
                            e.g. Microsoft Japan tried a 4-day week and <a href="https://www.theguardian.com/technology/2019/nov/04/microsoft-japan-four-day-work-week-productivity" className="btn btn-link normal btn-lg" rel="noopener noreferrer nofollow" target="_blank">productivity increased 40%.</a>
                        </div>
                        <Link to="/pro/productivity" className="btn cta btn-success btn-lg pro-con-more-info">More info...</Link>

                        <a href="https://en.wikipedia.org/wiki/Parkinson%27s_law" rel="noopener noreferrer nofollow" target="_blank">
                            <LazyLoadImage src={parkinsonsLaw} threshold={100} className="pro-con-img" alt="Parkinson's Law" />

                        </a>
                        <p className='pro-con-caption'>With less time, we naturally work more efficiently (aka <a href="https://en.wikipedia.org/wiki/Parkinson%27s_law" target="_blank" rel="noopener noreferrer nofollow">
                            "Parkinson's law"</a>)</p>
                    </div>

                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">2.</div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Costs Reduce</h4>
                        <p>There are many ways 4 day week companies can save on operating costs, such as:</p>
                        <ul>
                            <li><strong>Lower utility bills:</strong> shut the office 1 day / week = heating &amp; electrical costs drop by 10-20%.</li>
                            <li><strong>Smaller office needed:</strong> 4-day week + employees off different days + hot-desking = smaller office.</li>
                            <li><strong>Cheaper recruitment:</strong> 95%+ of people want a 4 day week, but only ~1% of companies <em>offer</em> it. In other words... watch great candidates come to <em>you</em>.</li>
                            <li><strong>Lower turnover costs:</strong> with a 4 day work week, employees retention skyrockets. Put simply:</li>
                        </ul>

                        <div className="pro-con-quote">
                            <div className='icon'>
                                “
                            </div>
                            <blockquote>
                                Once you go to a four-day work week, you don’t go back.
                            </blockquote>
                            <p className='pro-con-author'>Everyone</p>
                        </div>

                        <ul>
                            <li><strong>Decreased Absenteeism:</strong> when staff are healthier, they are off sick less.</li>
                            <li><strong>Lower costs for on-site perks:</strong> costs to pay for free meals, on-site gyms &amp; childcare drop by 20%.</li>
                        </ul>


                    </div>
                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        3.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>More Job Applicants</h4>
                        <p>Let's continue by stating the obvious... 4 day week jobs (32hrs @ full pay) get <a href="https://www.cnbc.com/2023/02/24/worlds-biggest-4-day-workweek-experiment-shows-big-health-pro-cons.html" className='btn btn-link normal btn-lg' target="_blank" rel="noopener noreferrer nofollow">a lot</a> more applications:</p>





                        <div className='pro-con-emph blue'>

                            <div className='icon arrow'>
                                ↑
                            </div>
                            <blockquote>
                                <a href="https://www.cityam.com/four-day-week-at-atom-bank-leads-to-500-per-cent-surge-in-job-applicants-who-want-34-hours-a-week-with-no-pay-cut/" target="_blank" rel="noopener noreferrer nofollow">
                                    Job applications increased by 500%</a> when Atom Bank switched to a 4 day work week (34hrs).
                            </blockquote>





                        </div>


                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        4.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Better Job Applicants</h4>
                        <p>And let's just say that it attracts candidates who... normally wouldn't apply 😇</p>
                    </div>
                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        5.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Increased Employee Retention</h4>
                        <p>Happier staff = longer employment</p>

                        <div className="pro-con-quote">
                            <div className='icon'>
                                “
                            </div>
                            <blockquote>
                                Our retention of staff increased from 80% to 98%.
                            </blockquote>
                            <p className='pro-con-author'>Mark Haslam - Loud Mouth Media</p>
                        </div>
                    </div>



                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        6.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Better for Gender Equality</h4>
                        <p>Here's how a 4 day week is a win for gender equality:</p>

                        <ul>
                            <li><strong>Reduces Gender pay gap:</strong> Women often take a pay cut due to caregiving - by dropping to part-time. Reimagining full time work as a four-day work week levels the playing field for women.</li>
                            <li><strong>Balanced Caring Responsibilities:</strong> when 3k people trialed a 4 day week in the UK, <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" target='_blank' rel="noopener noreferrer nofollow" className='btn btn-link btn-md'>men spent 27% more time</a> looking after children, vs 13% for women.</li>
                            <li><strong>Better for single parents:</strong> Childcare can be a <em>massive</em> expensive for single parents. A 4 day week drops this by at least 20%.</li>
                        </ul>

                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        7.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Absenteeism Reduces</h4>
                        <p>It's a simple concept: healthier staff &amp; lower burnout = less sick days.</p>


                        <div className='pro-con-emph'>

                            <div className='icon arrow'>
                                ↓
                            </div>
                            <blockquote>
                                Absenteeism <a href="https://www.cityam.com/four-day-week-at-atom-bank-leads-to-500-per-cent-surge-in-job-applicants-who-want-34-hours-a-week-with-no-pay-cut/" target="_blank" rel="noopener noreferrer nofollow">
                                    dropped by 67%</a> when 3k employees switched to a 4 day work week.
                            </blockquote>





                        </div>
                    </div>


                </div>




                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        8.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Increased Diversity & Inclusivity</h4>
                        <p>Put simply, flexibility creates a more diverse & inclusive workplace:</p>

                        <ul>
                            <li><strong>Attracts a Broader Range of Candidates:</strong> people with family obligations, health problems etc often can't commit to a traditional 5 day week schedule.</li>
                            <li><strong>Better for those with Disabilities:</strong> flexibility = inclusivity e.g. employees with disabilities may find a traditional work schedule challenging.</li>
                            <li><strong>Supports Continuing Education:</strong> an day off makes it easier for staff to pursue further education, which can increase diversity - especially in roles where certain groups are underrepresented.</li>
                        </ul>

                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        9.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Increased Innovation & Creativity</h4>
                        <p>Well-rested employees = increased creativity.</p>

                        <div className='pro-con-quote smaller'>

                            <div className='icon'>
                                “
                            </div>
                            <blockquote>
                                Creativity needs space. Creativity needs downtime. You're not always going to be at your most creative when you're sat in front of a computer.
                            </blockquote>
                            <p className='pro-con-author'>Jo Burns-Russell, Amplitude Media</p>





                        </div>

                    </div>
                </div>


                {/*}      <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        10.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Less workplace conflicts</h4>
                        <p>shown in many studies</p>
                    </div>
        </div> */}

                <div className='row pro-con'>
                    <div className="col-sm-2 cols pro-con-number ">
                        10.
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>More Time for Employees to Upskill</h4>
                        <p>The extra day could be used for employees to enhance their skills e.g. by taking courses, getting professional certification, hobbies etc.</p>
                    </div>
                </div>

                <div className='row pro-con con'>
                    <div className="col-sm-2 cols pro-con-number con">Con
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>Productivity Needs to Increase by 25%</h4>
                        <p>KPIs are obviously the biggest concern when switching to a shorter work week.</p>
                        <div className="pro-con-emph">
                            <div class="icon">“</div><blockquote>To avoid the business suffering, productivity needs to increase by 25% - to account for the lost day.</blockquote>

                        </div>

                        <p className="pro-con-link">
                            Perhaps surprisingly though, <strong>output didn't drop</strong> in every large scale 4 day week pilot so far e.g. see <Link to="/country/uk" className="btn btn-link">UK</Link>, <Link to="/country/usa" className="btn btn-link">US</Link> and <Link to="/country/canada" className="btn btn-link">Canada</Link> pilots.

                        </p>
                        <p><em>How is this possible I hear you ask...</em> Well, the phenomenon of <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" className="btn btn-link normal btn-lg" rel="noopener noreferrer nofollow" target="_blank">Parkinson's Law</a> means that we <em>naturally</em> work faster when less time is made available.
                        </p>

                        <div className="pro-con-quote smaller">
                            <div class="icon">“</div>
                            <blockquote>It's no surprise to me that when staff get 50% more time off, they work <em>much</em> more productively.</blockquote>

                            <p className='pro-con-author'>Phil McParlane, Founder @ 4dayweek.io</p>
                        </div>
                    </div>
                </div>


                <div className='row pro-con con'>
                    <div className="col-sm-2 cols pro-con-number con">Con
                    </div>
                    <div className="col-sm-10 cols pro-con-text">
                        <h4>It Can't be Applied in Some Industries</h4>
                        <p>Yes, the 4 day work is certainly more challenging to implement in some situations vs others e.g. customer support needs to be available 5 days / week.</p>
                        <p>But it doesn't mean there is no hope - we just have to be more creative.</p>
                        <p>e.g. for customer facing roles: companies usually split teams into different shifts: Mon-Thu and Tue-Fri.</p>
                     

                        <div className="pro-con-quote smaller">
                            <div class="icon">“</div>
                            <blockquote>We have the productive and technological capacity for the four-day week to replace the five-day week as the ‘new normal’, and for some version of work time reduction to be achieved across all sectors of the economy.</blockquote>

                            <p className='pro-con-author'>Joe O'Connor<br/>Director, <a href='https://worktimereduction.com/'  rel="noopener noreferrer nofollow" target="_blank" className="btn btn-link">Work Time Reduction Center of Excellence</a></p>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default ProsAndConsCompany;