import JobTile from '../tiles/JobTile';
import React from 'react';
import TurndownService from 'turndown'; // Import TurndownService

class PostJobFormPreview extends React.Component {
    shouldComponentUpdate() {
        return true;
    }

    render() {
        let { values } = this.props;

        let tmp = Object.assign({}, values);

        // Job
        tmp.posted = Math.floor(Date.now() / 1000);

        // Convert html (tmp.description_html) to markdown (tmp.description)
        if (tmp.description_html) {
            const turndownService = new TurndownService();
            tmp.description = turndownService.turndown(tmp.description_html);
        } else {
            tmp.description = "";
        }

        // Missing
        tmp.title = tmp.title ? tmp.title : "Job title";
        tmp.company_name = tmp.company_name ? tmp.company_name : "Company name";
        tmp.reduced_hours = tmp.reduced_hours ? tmp.reduced_hours : "Offered";


        // Company
        tmp.company = {
            vacation: 0,
            name: tmp.company_name ? tmp.company_name : "",
        };

        let localImg = null;

        if(values.company_logo_upload && values.company_logo_upload.length > 0){
            localImg = values.company_logo_upload[0];
        }


        return (
            <div className="post-job-box post-job-form-preview">
                <h3 className='post-job-heading'>Preview</h3>
                <JobTile data={tmp} localImg={localImg} />
               {/*} <pre className='margin-top'>{JSON.stringify(tmp)}</pre>*/}
            </div>
        );
    }
}

export default PostJobFormPreview;

