
import React from 'react';
import JobResumeButton from './JobResumeButton';
import JobApplyButton from './JobApplyButton';

class JobApplyTop extends React.Component {

    render() {

        var { is_expired, location_continent, location_country, location_city, url, apply_email, application_template, featured, company } = this.props.job;

    /*    // Location
        var loc = "";
        var locPrefix = !location_city ? ["USA", "UK", "United Kingdom"].indexOf(location_country) > -1 ? "the " : "" : "";

        if (location_city) {
            loc = location_city;
        } else if (location_country) {
            if (location_country == "United Kingdom") {
                loc = "UK";
            } else {
                loc = location_country;
            }
        } else if (location_continent) {
            loc = location_continent;
        }


        let content = "";
        if (is_expired) {
            content = <div className="job-tile-expired">Unfortunately, this role is now closed</div>;
        } else {
            content = <div className="job-apply-wrapper">
                <a href={url + (!application_template && !apply_email ? "/apply" : "")} onMouseDown={(e) => this.props.onClick(e)} rel="noopener noreferrer nofollow" className="btn btn-success cta btn-huge" target='_blank' >
                    Apply now
                    {loc ? <div className="row apply-location">{loc} based applicants only</div> : ""}
                </a>
            </div>;
        }*/

        let secondaryBtn = <div className='job-secondary'>
            <JobResumeButton/>
        </div>;

        return (
            <div className="row job-apply job-apply-top">
                
                {<JobApplyButton {...this.props} />}
                {!featured && company && !company.featured ? secondaryBtn : ""}
            </div>

        );
    }
}

export default JobApplyTop;