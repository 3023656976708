
import React from 'react';
import JobResumeButton from './JobResumeButton';
import JobApplyButton from './JobApplyButton';

class JobApplyBottom extends React.Component {

    render() {
        var { title, company, featured } = this.props.job;


        return (
            <div className="row job-apply-bottom">
                <div className="apply-bottom-inner">
                    <div className="apply-bottom-left">
                        <h3>{title}</h3>
                        <div className="company-name">{company.name}</div>
                    </div>
                    <div className="apply-bottom-right">
                        {<JobApplyButton {...this.props} />}
                        {false && !featured && company && !company.featured ? <JobResumeButton /> : ""}
                    </div>
                </div>
            </div>

        );
    }
}

export default JobApplyBottom;