import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as itemActions from '../store/item/actions';


class Logout extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}

	componentDidMount() {
		this.props.getLogout();
		this.props.resetUser();
	}

	render() {

		var metaTitle = "Logout· 4 Day Week Jobs";
		var metaDesc = "We hope to see you again soon!";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/logout";

		return (
			<div className="main-container medium-width not-found">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />


				<div className="not-found">

			
					<section className="row hero section-header">
						<div className="col-sm-8 hero-left">
							<h1>Logged out</h1>
							<h3>{metaDesc}</h3>
							<Link to="/" className="btn btn-success cta btn-huge margin-top-large">Go to homepage</Link>
						</div>

						<div className="col-sm-4 hero-right hide-mobile ">
						</div>
					</section>

				</div>
			</div >
		);
	}
}

const mapStateToProps = (state) => {
	return {
		};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	getLogout: (data) => {
		dispatch(itemActions.getItem("auth", {}, null, "logout"));
	},

	resetUser: () => {
        dispatch(itemActions.resetItem("user"));
    },


});


Logout = connect(mapStateToProps, mapDispatchToProps)(Logout);

export default withRouter(Logout);