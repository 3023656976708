import React from 'react';
import { Link } from 'react-router-dom';
import { TimeAgoShort } from '../../utils/time-tools';

class PostedJobTile extends React.Component {

    render() {
        var { data } = this.props;

        return (
            <li>
                <div className="post-tile-wrapper">
                    <div className="post-tile">
                        <div className="post-tile-left">
                            <h3>{data.title}</h3>
                            <p>Posted {TimeAgoShort(data.posted * 1000, true)}</p>

                        </div>

                        <div className="post-tile-right">
                            <Link to={data.url} className="btn btn-success btn-sm">View post</Link>
                            <Link to={"/dashboard/job/edit/" + data.id_str} className="btn btn-ghost btn-sm">Edit post</Link>

                        </div>
                    </div>
                </div>

            </li>
        );
    }
}

export default PostedJobTile;