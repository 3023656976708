import React from 'react';
import * as FormatTools from '../../utils/format';

class FieldRadio extends React.Component {

	render() {

		var { id, options, input, meta } = this.props;

		var optionsHtml = options.map((d) => <div className="row">
			<input {...input} id={FormatTools.Slugify(id+"_"+d.value)} component="radio" type={"radio"} value={d.value} name={id} checked={d.value == input.value} required options={null} />
			<label htmlFor={FormatTools.Slugify(id+"_"+d.value)}>{d.label}</label>
			{d.meta ? <p className="radio-meta">{d.meta}</p> : ""}
		</div>);


		return (<>
			{optionsHtml}
		</>
		);
	}
}

export default FieldRadio;
