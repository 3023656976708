import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HelmetMeta from '../components/global/HelmetMeta';
import Loader from '../components/misc/Loader';
import ProfileBasicFields from '../components/user/ProfileBasicFields';
import * as itemActions from '../store/item/actions';
import * as listActions from '../store/list/actions';


class UserEdit extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

		};
	}

	onSubmit(values) {
		console.log("Submitting...", values)
		this.props.setUser(values);

		if (values.get_resume_review && values.resume_upload && values.resume_upload.length > 0) {
			this.props.newCritique(values);
		}

		this.props.history.push("/jobs?profileupdated=true");
	}

	redirectIfNotLoggedIn(){
		let { user } = this.props;

		if (user && user.requested && !user.loading && user.response && user.response.error) {
			this.props.history.push("/login?unauthorized=true");
			return
		}
	}

	componentDidMount() {
		this.redirectIfNotLoggedIn();

		this.props.getFilters();
	}

	componentDidUpdate(prevProps) {
		this.redirectIfNotLoggedIn();
	}


	render() {

		var { submitted } = this.state;
		var { user, jobs_roles } = this.props;


		if (!user || user.loading || !user.requested) {
			return <Loader />
		} else if (!jobs_roles || jobs_roles.loading || jobs_roles.itemIds.length == 0) {
			return <Loader />
		}



		var jobRoles = [];

		if (jobs_roles && jobs_roles.itemIds) {
			for (var i = 0; i < jobs_roles.itemIds.length; i++) {
				var cur = jobs_roles.items[jobs_roles.itemIds[i]];
				jobRoles.push({ 'label': cur.label, 'value': cur.value });
			}
		}

		let userData = user.response;

		if (submitted) {
			return (<div className="row padded">
				<h2 className="section-title success">Thanks for your submission. We'll get back to you shortly</h2>
			</div>)
		}

		var metaTitle = "Edit your profile Sign up · 4DayWeek.io";
		var metaDesc = "Set your preferences for the 4DayWeek.io newsletter and more.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/profile/edit";


		return (
			<div className="user-edit">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<div className="user-edit-hero">
					<section className="row thin section-header">
						<div className=''>
							<h1 className='smaller'>Edit your profile</h1>
							<h2 className='page-subtitle'>Set your preferences to get the most relevant content on the website and newsletter.</h2>
						</div>
					</section>
				</div>

				<section className='row thin'>
					<Form
						onSubmit={(values) => this.onSubmit(values)}
						mutators={{
							...arrayMutators,
							changeResume: (args, state, utils) => {
								utils.changeValue(state, 'resume_upload', () => args.length > 0 ? args[0] : [])
							},
						}}
						initialValues={userData}
						render={({ handleSubmit, submitting, submitted, errors, pristine, values, form, form: {
							mutators: { push, pop }
						} }) => (
							<form onSubmit={handleSubmit} className="user-edit-form padded-bottom margin-bottom"
								data-sparkloop-upscribe='false'>

								{/* Job hours? */}

								<div className="row">


									<ProfileBasicFields form={form} jobRoles={jobRoles} />


									<div className="row">
										<div className='col-sm-4 cols'>
										</div>
										<div className='col-sm-8 cols'>
											<button type="submit" disabled={Object.keys(errors).length > 0} className="btn btn-huge btn-success">Update profile</button>
											{/*By signing up you agree to our <Link to="/terms">terms of service</Link> and <Link to="/privacy">privacy policy</Link>.*/}
										</div>

									</div>

								</div>
								{/* Debug 
								<pre>{JSON.stringify(values, 0, 2)}</pre> */}
							</form>
						)}
					/>



				</section>


			</div >
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.item.user,
		jobs_roles: state.list.jobs_roles
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({

	setUser: (data) => {
		dispatch(itemActions.setItem("users", data, "set", "user"));
	},


	newCritique: (data) => {
		dispatch(itemActions.newItem("critiques", data, "critique"));
	},


	getFilters: () => {
		dispatch(listActions.fetchItems("jobs", "roles"));
	}
});

UserEdit = connect(mapStateToProps, mapDispatchToProps)(UserEdit);

export default withRouter(UserEdit);