
export function CleanValues(values, formId) {
    // Remove suffix from key
    for (let key in values) {
        if (key.endsWith("_" + formId)) {
            let newKey = key.substring(0, key.length - formId.length - 1);
            values[newKey] = values[key];
            delete values[key];
        }
    }
    return values;
}

export function AddRef(values) {
    // Add UTM params
    const consider = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content", "ref", "ref_url"];
    for (let key of consider) {
        let cur = localStorage.getItem(key);
        if (cur && cur != "null") {
            values[key] = cur;
        }
    }
    values["cur_url"] = window.location.href;
    let temp = window.location.pathname.split('/')

    if (temp.length > 1 && temp[1] != "") {
        values['cur_page'] = temp[1];
    } else {
        values['cur_page'] = "homepage";
    }
    return values;
}

