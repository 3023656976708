import JobApplyButton from '../..//job-page/JobApplyButton';
import React from 'react';

class JobTileApplyTop extends React.Component {

    render() {

        return (
            <div>
                <JobApplyButton {...this.props} />
            </div>
        )
    };




}

export default JobTileApplyTop;