import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
class CompanySideprojects extends React.Component {

    render() {

        var { company: companyData } = this.props;
        var { employee_side_projects } = companyData;

        if (!employee_side_projects || employee_side_projects.length == 0) {
            return "";
        }

        var content = employee_side_projects.map((d) =>
            <li>
                <a href={d.url} rel="noopener noreferrer nofollow" target="_blank" className='btn btn-link btn-md align-left'>
                    <div className="sideproject-icon">
                        <LazyLoadImage
                            alt={d.title}
                            height={40}
                            src={d.image} // use normal <img> attributes as props
                            width={40} />
                    </div>
                    <div className="sideproject-title">{d.title}
                        <div className="sideproject-name">By {d.person} ({d.team})</div>
                    </div>

                </a>
            </li>
        );

        return (
            <div className="company-side-box company-sideprojects ">
                <h3>Employee side projects</h3>
                <p>We are committed to your personal growth and encourage side projects e.g:</p>
                <ul>
                    {content}

                </ul>
            </div>

        )
    }
}

export default CompanySideprojects;