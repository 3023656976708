import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as itemActions from '../../store/item/actions';

const CategoryItem = ({ path, title, jobCount, className }) => (
    <li className={className}>
        <Link className="popular-btn" to={path}>
            <h5>{title}</h5>
            <p>{jobCount ? jobCount : "... "} jobs</p>
        </Link>
    </li>
);

class HomeCategories extends React.Component {
    componentDidMount() {
        this.props.getData();
    }

    totalJobs(obj) {
        return Object.keys(obj).reduce((sum, key) => sum + parseFloat(obj[key] || 0), 0);
    }

    render() {
        const { data } = this.props;
        const categories = data?.response || {};
        const categoryList = [
         //   { path: '/jobs/administrative', title: 'Administrative', key: 'administrative' },
            { path: '/jobs/customer-support', title: 'Customer Support', key: 'customer support' },
            { path: '/jobs/data-science', title: 'Data & Analytics', key: 'data science' },
            { path: '/jobs/design', title: 'Design', key: 'design' },
            { path: '/jobs/engineering', title: 'Engineering', key: 'engineering' },
            { path: '/jobs/finance', title: 'Finance', key: 'finance' },
            { path: '/jobs/human-resources', title: 'Human Resources', key: 'human resources', className: 'hide-mobile' },
            { path: '/jobs/marketing', title: 'Marketing', key: 'marketing' },
            { path: '/jobs/product', title: 'Product and UX', key: 'product' },
         //   { path: '/jobs/project-management', title: 'Project Management', key: 'project management' },
            { path: '/jobs/sales', title: 'Sales', key: 'sales' },
        ];

        return (
            <div className='row popular'>
                <h4>Browse jobs by category...</h4>
                <ul className='row'>
                    {categoryList.map(({ path, title, key, className }) => (
                        <CategoryItem key={key} path={path} title={title} jobCount={categories[key]} className={className} />
                    ))}
                    <li className='view-all'>
                        <Link className="popular-btn  padded-small" to="/jobs">
                            <h5>View all 4 day week jobs</h5>
                            <p>{categories ? this.totalJobs(categories) : ""} jobs</p>
                        </Link>
                    </li>
                    <li className='view-all-part-time'>
                        <Link className="btn btn-link text-center btn-sm padded-small" to="/jobs/part-time">
                            <h5>Or view part-time jobs</h5>
                        </Link>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    data: state.item.num_by_category,
});

const mapDispatchToProps = (dispatch) => ({
    getData: () => {
        dispatch(itemActions.getItem("jobs", {}, "num_by_category", "num_by_category"));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeCategories);
