import React from 'react';

const ResumeReviewHowItWorks = () => {
    return (
        <div className="resume-review-how-it-works">
            <div className="content">
                <h2>How Our Resume Review Works</h2>
                <h6>Get actionable feedback on your resume in 3 simple steps</h6>
                
                <div className="steps">
                    <div className="step-wrapper">
                        <div className="step">
                            <h4>1</h4>
                            <p>Upload your resume in PDF or DOCX format</p>
                        </div>
                    </div>
                    
                    <div className="step-wrapper">
                        <div className="step">
                            <h4>2</h4>
                            <p>Our AI analyzes your resume against key hiring criteria</p>
                        </div>
                    </div>
                    
                    <div className="step-wrapper">
                        <div className="step">
                            <h4>3</h4>
                            <p>Receive detailed feedback and actionable improvements</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewHowItWorks; 