import React from 'react';
import LabelField from '../fields/LabelField';
import * as FormatTools from '../../utils/format';

class ApplicationFormFields extends React.PureComponent {
    render() {
        const { job, form } = this.props;

        let fields = [];
        if (job.application_template && job.application_template.fields) {
            fields = job.application_template.fields.map((d) => {
                // Make a slug, look for a saved value under "field_<slug>"
                const slug = FormatTools.Slugify(d.label);
                const savedValue = localStorage.getItem("field_" + slug) || "";

                return (
                    <LabelField
                        key={d.id}
                        inputType={d.type}
                        id={d.id}
                        label={d.label}
                        inlineCol={true}
                        name={d.id}
                        singleCol={true}
                        placeholder={d.placeholder}
                        isRequired={d.required}
                        // Pass in the savedValue as the default
                        defaultValue={savedValue}
                        // Optional: If you want the user to not need to re-answer, you could remove isRequired if savedValue exists:
                        // isRequired={!savedValue}
                    />
                );
            });
        }

        return (
            <>
                {/* Some standard fields */}
                {job && job.apply_email ? (
                    <LabelField
                        inputType="file"
                        multiple={false}
                        name="resume_upload"
                        id="resume_upload"
                        label="Resume / CV (PDF only)"
                        isRequired={true}
                        inlineCol={true}
                        singleCol={true}
                        onChange={form.mutators.changeResume}
                        accepted={['application/pdf']}
                    />
                ) : null}

                {job && job.apply_email ? (
                    <LabelField
                        inputType="checkbox"
                        id="get_resume_review"
                        label="Get a free resume review"
                        singleCol={true}
                        isRequired={false}
                    />
                ) : null}

                {/* Render the dynamic fields */}
                {fields}
            </>
        );
    }
}

export default ApplicationFormFields;
