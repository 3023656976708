import React from 'react';

class CompanyVacation extends React.Component {

    render() {



        var { company: companyData } = this.props;


        var { vacation, vacation_description, featured, reduced_hours, four_day_off, benefit_unlimited_pto } = companyData;

        if (!vacation || vacation == 0) {
            return "";
        }

        var vacationDesc = "";

        if (vacation_description) {
            vacationDesc = vacation_description;
        } else if (benefit_unlimited_pto) {
            vacationDesc = "We offer unlimited PTO"
        } else if(!featured) {
            vacationDesc = `Our vacation policy is ${vacation} days`
        }


        return (
            <div className="company-side-box company-vacation ">
                <h3>Our Vacation Policy</h3>

                <p>{vacationDesc}</p>



                {vacation && (reduced_hours == "Offered" || reduced_hours == "Would consider")  ?
                    <ul className="margin-top-small">
                        <li>{vacation} days {reduced_hours == "Would consider" ? "PTO" : ""}</li>
                        <li>52 {reduced_hours == "Would consider" ? "unpaid" : ""} {four_day_off && four_day_off != "Any" ? four_day_off : "day"}s</li>
                        <li>{vacation + 52} days off per year</li>
                    </ul> :

                    <></>
                }
            </div>

        )
    }
}

export default CompanyVacation;