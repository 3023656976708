
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import AdvicesList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class Advices extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_advices"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Advices.page, Advices.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Advices.page + "_" + Advices.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Advices.page,
			id: Advices.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "Advice and Guides";
		var metaDesc = "Delve into our comprehensive guides on the nuances of the 4 day work week, job seeking, interviewing, and resume writing. Navigate your career journey with our expert insights and tips.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/advice";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<AdvicesList data={data} settings={settings} />
				</section>




				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[Advices.page + "_" + Advices.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(Advices.page, Advices.id));
	},


});

Advices = connect(mapStateToProps, mapDispatchToProps)(Advices);

export default Advices;