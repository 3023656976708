import { Component } from "react";
import { Link } from 'react-router-dom';



class AuthSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let { email } = this.props;



        return (
            <div className="row auth-check-inbox">

                <h1 className="">Success!</h1>
                <p className="margin-top-sm">You are now logged in.</p>
                <div className="auth-success-buttons margin-top-large">
                    <Link to="/jobs" className="btn btn-huge cta btn-success margin-right">View jobs</Link>
                    <Link to="/profile/edit" className="btn btn-xl cta btn-ghost ">Update preferences</Link>
                </div>
            </div>

        );
    }
}

export default AuthSuccess;