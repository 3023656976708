import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import burnoutImg from '../../assets/burnout-graph.png';
import { Link } from 'react-router-dom';

class ProsAndConsEmployee extends React.Component {

    render() {


        return (

            <section className="row hero pros-and-cons">


                <div className='row pro-con-title pro-con-employee'>
                    <h3 className=''>❤️ Pros and Cons for the Employee</h3>

                </div>
                <div className="row pro-con">
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        11.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Burnout Reduces</h4>
                        <p>When <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" rel="noopener noreferrer nofollow" target="_blank" className='btn btn-link'>3k employees switched to a 4 day week</a>, 71% reported lower levels of burnout:</p>

                    

                        <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" rel="noopener noreferrer nofollow" target="_blank">
                            <LazyLoadImage src={burnoutImg} threshold={100} className="pro-con-img" alt="Burnout dropped" />

                        </a>
                        <p className='pro-con-caption'>More rest = lower risk of burnout</p>
                        <Link to="/pro/mental-health" className="btn cta btn-success btn-lg pro-con-more-info">More info...</Link>

                    </div>

                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        12.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Staff Sleep More</h4>
                        <p>More free time = more recovery time.</p>


                        <div className='pro-con-emph blue'>

                            <div className='icon arrow'>
                                ↑
                            </div>
                            <blockquote>
                                During a recent 4 day week trial, <a href="https://static1.squarespace.com/static/60b956cbe7bf6f2efd86b04e/t/6387be703530a824fc3adf58/1669840498593/The+Four+Day+Week-+Assessing+Global+Trials+of+Reduced+Work+Time+with+No+Reduction+in+Pay+%E2%80%93+F+%E2%80%93+30112022.pdf" target="_blank" rel="noopener noreferrer nofollow">
                                    staff slept 16% more</a> on average, vs when they worked 5 days a week.
                            </blockquote>





                        </div>



                    </div>
                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        13.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Staff Exercise More</h4>

                        <div className='pro-con-emph'>

                            <div className='icon arrow'>
                                ■
                            </div>
                            <blockquote>
                                In a recent study, <a href="https://static1.squarespace.com/static/60b956cbe7bf6f2efd86b04e/t/6387be703530a824fc3adf58/1669840498593/The+Four+Day+Week-+Assessing+Global+Trials+of+Reduced+Work+Time+with+No+Reduction+in+Pay+%E2%80%93+F+%E2%80%93+30112022.pdf" rel="noopener noreferrer nofollow" className='btn btn-link' target="_blank">physical exercise increased by ~21%</a>, vs when they worked a 5 day week.
                            </blockquote>





                        </div>



                        <p></p>
                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        14.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Staff are Happier</h4>
                        <p>We are stating the obvious here.</p>
                    </div>
                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        15.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Better Work-life Balance</h4>
                        <p>We are also stating the obvious here.</p>
                    </div>
                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number cols cols-lg ">
                        16.
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>Better for Part Timers</h4>


                        <div className='pro-con-emph green'>

                            <div className='icon arrow'>
                                ⎼
                            </div>
                            <blockquote>
                                When <em>full-time work</em> is reimagined as a <em>4 day week</em> - the playing field is levelled. Working part time hours becomes <em>less of a disadvantage</em> for career progression.
                            </blockquote>





                        </div>

                    </div>


                </div>

                <div className='row pro-con con'>
                    <div className="col-sm-2 cols cols-lg pro-con-number">
                        <span className='tag'>Con</span>
                    </div>
                    <div className="col-sm-10 cols cols-lg pro-con-text">
                        <h4>It's Not for Everyone</h4>
                        <p>It's true - not everyone loves a shorter workweek. As studies have shown, the days are more intense and some people prefer a slower paced, more relaxed 5 day week to get their work done.</p>
                     

                        <div className="pro-con-emph smaller">
                            <div class="icon arrow">■</div>
                            <blockquote>Around 8% of people prefer to work 5 days per week instead of 4.</blockquote>
                            <p className='pro-con-author'>Source: <a href="https://www.qualtrics.com/blog/four-day-work-week/Qualtrics" rel="noopener noreferrer nofollow" target="_blank" className='btn btn-link'>3k employees switched to a 4 day week</a></p>

                        </div>
                    </div>
                </div>


            </section>

        );
    }
}

export default ProsAndConsEmployee;