import React, { Component } from 'react';
import Markdown from 'marked-react';

class MarkdownRenderer extends Component {


    render() {

        return (
            <div className={this.props.className} >
                <Markdown value={this.props.children} />
            </div>
        );
    }
}

export default MarkdownRenderer;