
import React from 'react';
class JobResumeButton extends React.Component {

    render() {
        return (
            <a href="https://aiapply.co/resume-builder?via=philip" target='_blank' rel={"noopener noreferrer nofollow"} className='btn btn-ghost cta btn-lg'>AI Resume Builder</a>
        );
    }
}

export default JobResumeButton;