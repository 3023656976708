import React from 'react';

const ResumeReviewAsSeenOn = () => {
    return (
        <div className="resume-review-as-seen-on">
            <div className="content">
                <h3>Helping job seekers get hired at top companies</h3>
                <div className="as-seen-on-logos">
                    <img src="https://media.fourdayweek.io/files/companies/google.png" alt="Google" />
                    <img src="https://media.fourdayweek.io/files/companies/apple.png" alt="Apple" />
                    <img src="https://media.fourdayweek.io/files/companies/facebook.png" alt="Facebook" />
                    <img src="https://media.fourdayweek.io/files/companies/amazon.png" alt="Amazon" />
                    <img src="https://media.fourdayweek.io/files/companies/microsoft.png" alt="Microsoft" />
                </div>
                <h4>Join thousands of professionals who improved their resumes with our service</h4>
            </div>
        </div>
    );
};

export default ResumeReviewAsSeenOn; 