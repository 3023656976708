import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IsLoggedIn } from '../../utils/user';
import SubscriberForm from './SubscriberForm';

import React from 'react';

class SubscriberModalWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            timeLoaded: new Date().getTime(),
        };
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleScrollPosition = this.handleScrollPosition.bind(this);

    }

    componentDidMount() {
        this.timer = setTimeout(() => this.openModal(), 7500);
        window.addEventListener('mousemove', this.handleMousePosition);
        // window.addEventListener('scroll', this.handleScrollPosition);
        document.addEventListener('keydown', this.handleKeyDown);


    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        window.removeEventListener('mousemove', this.handleMousePosition);
        //      window.removeEventListener('scroll', this.handleScrollPosition);
        document.removeEventListener('keydown', this.handleKeyDown);

    }

    handleKeyDown(event) {
        if (event.key === 'Escape' || event.keyCode === 27) {
            // The Escape key was pressed, perform desired actions here
            this.setState({ modalIsOpen: false });
        }
    }

    handleScrollPosition = () => {
        const scrollPosition = window.pageYOffset;
        const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const halfWayPoint = windowHeight / 2;

        if (scrollPosition > halfWayPoint) {
            this.openModal();
        }
    };


    handleMousePosition = (e) => {
        let curTime = new Date().getTime();
        // Open modal if user has been on page for at least 3 seconds and they move their mouse to the top of the page
        if (curTime - this.state.timeLoaded > 8000 && e.clientY < 40 && e.clientX < 400) {
            this.openModal();
        }

    };

    openModal = () => {
        if (localStorage.getItem('is_subscription_form_active') == 'true') {
            return;
        } else if (localStorage.getItem('has_subscribed') == 'true') {
            return;
        } else if (IsLoggedIn(this.props.user)) {
            return;
        }

        // current time
        let exitPopupShown = localStorage.getItem('shown_exit_popup_time')

        // if exit popup has not been shown before or if it was shown more than 30 days
        if (!this.state.modalIsOpen && (!exitPopupShown || (new Date().getTime() - exitPopupShown) > (10 * 86400000))) {
            this.setState({ modalIsOpen: true });
            // set to current unix time
            localStorage.setItem('shown_exit_popup_time', new Date().getTime());
        }
    };

    render() {

        const { className } = this.props;
        const { modalIsOpen } = this.state;



        return (
            <div className={"modal exit-popup " + (modalIsOpen ? "show" : "")} tabIndex="-1" role="dialog">
                <div className="modal-dialog  modal-md" role="document">

                    <div className="modal-content">
                        <button type="button" className="btn btn-ghost icon-only btn-lg btn-close" data-dismiss="modal" aria-label="Close" onClick={(e) => this.closeModal(e)}>
                            <FontAwesomeIcon icon={faTimes} />
                        </button>
                        <div className="modal-content-inner">

                            <div className={"row subscriber subscriber-popup " + className}>
                                <SubscriberForm {...this.props} />
                            </div>


                            <div className='padded-top padded-bottom'>
                                <button className='margin-top center btn btn-link btn-md' onClick={(e) => this.closeModal(e)}>No thanks...</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.item.user,
    };
};


SubscriberModalWrapper = connect(mapStateToProps, null)(SubscriberModalWrapper);

export default withRouter(SubscriberModalWrapper);
