import React from 'react';

const ResumeReviewUploader = () => {
    return (
        <div className="resume-review-uploader" id="resume-uploader">
            <div className="content">
                <h2>Upload Your Resume For Instant Feedback</h2>
                <h6>Get a free, detailed analysis of your resume in seconds</h6>
                
                <div className="uploader-container">
                    <div className="upload-area">
                        <div className="upload-icon">
                            <i className="fa fa-cloud-upload"></i>
                        </div>
                        <p>Drag & drop your resume here or click to browse</p>
                        <span className="file-types">PDF & DOCX only. Max 2MB file size.</span>
                        <button className="cop-btn cop-btn-pink">Upload Resume</button>
                    </div>
                    <div className="upload-info">
                        <h4>What you'll get:</h4>
                        <ul>
                            <li><i className="fa fa-check"></i> Overall resume score</li>
                            <li><i className="fa fa-check"></i> ATS compatibility analysis</li>
                            <li><i className="fa fa-check"></i> Section-by-section feedback</li>
                            <li><i className="fa fa-check"></i> Keyword optimization tips</li>
                            <li><i className="fa fa-check"></i> Actionable improvement suggestions</li>
                        </ul>
                        <div className="privacy-note">
                            <i className="fa fa-lock"></i> Your privacy is guaranteed. We never share your data.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewUploader; 