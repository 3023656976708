import { COUNTRIES } from '../../utils/filters';
import LabelField from '../fields/LabelField';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class EditCompanyFormFields extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            submitted: false,

        };
    }


    render() {

        var { form, data, values } = this.props;

        const benefits = [
            { id: "benefit_dentalcare", label: "Dentalcare", placeholder: "All times are for CET timezone" },
            { id: "benefit_healthcare", label: "Healthcare", placeholder: "Country dependent?" },
            { id: "benefit_pension", label: "401k / Pension contribution", placeholder: "Does the company offer a 401(k)?" },
            { id: "benefit_retreats", label: "Company Retreats", placeholder: "Where was your last trip?" },
            { id: "benefit_transparent_salaries", label: "Transparent salaries", placeholder: "How does this work?" },
            { id: "benefit_parental_leave", label: "Enhanced Parental leave", placeholder: "How much time off" },
            { id: "benefit_unlimited_pto", label: "Unlimited PTO", placeholder: "Minimum time off?" },
            { id: "benefit_equity", label: "Equity, stock or options?", placeholder: "Is there an option pool?" },
            { id: "benefit_childcare", label: "Childcard support / budget", placeholder: "Contributions for childcare?" },
            { id: "benefit_equipment", label: "Equipment / remote work budget", placeholder: "Contributions for home office?" },
            { id: "benefit_life_disability_insurance", label: "Life / disability insurance", placeholder: "Life / disability insurance" },
            { id: "benefit_professional_development", label: "Training / development budget", placeholder: "Professional development budget?" },
            { id: "benefit_bonuses", label: "Yearly bonuses", placeholder: "Yearly bonuses?" },
            { id: "benefit_sabbaticals", label: "Sabbaticals", placeholder: "Sabbaticals / extended time off?" },
            { id: "benefit_mental_health", label: "Mental health or wellbeing support", placeholder: "Mental health support?" },
            { id: "benefit_few_meetings", label: "Few Meetings or no meetings days", placeholder: "How often are meetings?" },
            { id: "benefit_public_vacation_flexibility", label: "Public Vacation Flexibility", placeholder: "How flexible is vacation?" },
            { id: "benefit_corporate_discounts", label: "Corporate Discounts", placeholder: "What discounts are offered?" },
            { id: "benefit_workations", label: "Workations", placeholder: "Are workations allowed?" },
            { id: "benefit_work_from_anywhere", label: "Work from anywhere in the world", placeholder: "How flexible is the work location?" },
            { id: "benefit_coworking_space", label: "Coworking Space Access / Budget", placeholder: "Contributions for coworking spaces?" },
            { id: "benefit_adoption_or_fertility", label: "Adoption or Fertility Assistance", placeholder: "Support for adoption or fertility?" },
            { id: "benefit_no_overtime", label: "No msgs outside work hours / overtime", placeholder: "How often is overtime?" },
            { id: "benefit_other", label: "Other", placeholder: "Specify other benefits" },
        ];

        const benefitsHtml = benefits.map((benefit) => (
            <div className='row field-row no-padded-bottom' key={benefit.id}>
                <div className='col-sm-6 cols'>
                    <LabelField inputType="checkbox" singleCol id={benefit.id} labelPos="right" label={benefit.label} isRequired={false} />
                </div>
                <div className='col-sm-6 cols'>
                    <LabelField inputType="text" id={`${benefit.id}_label`} isRequired={false} />
                </div>
            </div>
        ));


        return (
            <div className="edit-company-box">

                {/* Basic info */}
                <h3 className='edit-company-heading'>Company Details</h3>


                <div className="row field-row">
                    <LabelField inputType="text" id="name" label={"Company name"} isRequired={true} singleCol={true} placeholder={"Your Company"} autoFocus={true} />
                </div>


                <div className="row field-row">
                    <LabelField inputType="text" id="short_description" label={"Short Description"} isRequired={true} singleCol={true} placeholder={"We are a company that does awesome stuff (max 140 characters)"} />
                </div>


                <div className="row field-row">
                    <LabelField inputType="markdown" id="description_html" label={"Long Description"} isRequired={true} singleCol={true} placeholder="Typically a minimum of 3 sections (Who we are, What we do, Employee benefits) and 500 words." />
                </div>

                <div className="row field-row">
                    <LabelField inputType="file" id="company_logo" label={"Company Logo"} isRequired={false} multiple={false} singleCol={true} accepted={['image/png', 'image/jpg', 'image/jpeg', 'image/gif']} helper="Upload your company logo (PNG, JPG, GIF)" onChange={form.mutators.changeCompanyLogo} />
                    {data && data.company && data.company.logo_url ? <div className="row"><img src={data.company.logo_url} className="company-logo" /></div> : ""}
                </div>



 
                {/* Images */}
                <LabelField inputType="file" multiple={true} id="image_upload" label={"Image uploads"} isRequired={false} onChange={form.mutators.changeImages} accepted={['image/png', 'image/jpg', 'image/webp', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/gif']} singleCol={true} helper="Upload company images (PNG, JPG, SVG, GIF)" />


				{values && values.images && values.images.length > 0 ?
					<div className="row padded-bottom">
						{values.images.map((d) => <div className="col-xs-3 cols">
							<LazyLoadImage src={d} className="thumbnail-image" alt="Company image" />
						</div>)}
					</div> : ""}




                {/* Working hours */}

                <h3 className='edit-company-heading'>Working Hours</h3>
                <div className="row field-row ">
                    <LabelField inputType="radio" id="reduced_hours" label={"Working Policy"} singleCol={true} options={
                        [
                            { value: 'Offered', label: '4 day work week (no salary reduction)' },
                            { value: 'Would consider', label: '4 day work week (80% salary)' },
                            { value: 'Offered every 2nd week', label: '4 day work week every 2nd week' },
                            { value: 'Offered 4.5 days', label: '4.5 day work week' },
                            { value: 'Offered summer', label: '4 day work week during summer months' },
                            { value: '9 day fortnight', label: '9 day fortnight e.g. 9/80 schedule' },
                            { value: 'Flex Fridays', label: '5 day work week with "flexible fridays" i.e. "no meetings + rest if you like"' },
                            { value: 'Flexible hours', label: '5 day work week with fully flexible hours' },
                            { value: 'Generous PTO', label: '5 day work week with 40+ days vacation per year' },
                            { value: 'Part time', label: "Part time" },
                            { value: 'Other', label: 'Other' },

                        ]} isRequired={true} />
                </div>

                {values.reduced_hours == "Other" &&
                    <div className="row field-row">
                        <LabelField inputType="text" id="reduced_hours_description" label={"Type of work week"} isRequired={true} singleCol={true} placeholder={"e.g. 6 x 5hr days"} />
                    </div>
                }


                {(values.reduced_hours == "Offered" || values.reduced_hours == "Would consider") &&
                    <div className="row field-row">
                        <LabelField inputType="radio" id="four_day_off" label={"Which day is off?"} singleCol={true} options={
                            [
                                { label: 'Monday', value: 'Monday' },
                                { label: 'Tuesday', value: 'Tuesday' },
                                { label: 'Wednesday', value: 'Wednesday' },
                                { label: 'Thursday', value: 'Thursday' },
                                { label: 'Friday', value: 'Friday' },
                                { label: 'Any', value: 'Any' },
                                { label: 'It varies', value: 'Varies' },

                            ]} isRequired={true} />
                    </div>
                }

                <div className="row field-row">
                    <LabelField inputType="number" id="hours_full_time" isNumber={true} label={"Hours / week"} max={40} description="How many hours are employees contracted to work in a typical week? This includes lunch and breaks." isRequired={true} singleCol={true} placeholder={"e.g. 32"} />
                </div>

                <div className="row field-row">
                    <div className="col-sm-8 cols cols-sm label-container">
                        <label>Acceptable start and finish times</label>
                        <p>Flexible companies have wide bounds i.e. employees can start early (e.g. 6am) & finish early, or start late and finish late (e.g. 9pm). Please use 24hr format e.g. 6 and 21.</p>
                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_acceptable_from" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"6"} />


                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_acceptable_to" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"21"} />

                    </div>

                </div>


                <div className="row field-row">
                    <div className="col-sm-8 cols label-container">
                        <label>Typical start and finish times</label>
                        <p>When do people typically start and finish work? Please use 24hr format e.g. 9 to 17</p>
                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_typical_from" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"9"} />


                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_typical_to" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"17"} />

                    </div>

                </div>


                <div className="row field-row">
                    <div className="col-sm-8 cols label-container">
                        <label>Core hours</label>
                        <p>Do employees need to be available during certain times of the day? If so, please enter your core hours here using 24hr format e.g. 12 to 15</p>
                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_core_from" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"12"} />


                    </div>
                    <div className="col-sm-2 cols cols-sm">
                        <LabelField inputType="number" id="day_hours_core_to" isNumber={true} label={""} max={24} isRequired={false} singleCol={false} placeholder={"15"} />

                    </div>

                </div>



                <h3 className='edit-company-heading margin-top-large'>Vacation Policy</h3>


                <div className="row field-row">
                    <LabelField inputType="number" id="vacation" isNumber={true} label={"Paid vacation days per year"} description="How many paid vacation days off (including public / bank holidays) do employees get? If unlimited, please leave blank." isRequired={false} singleCol={true} placeholder={"e.g. 30"} />
                </div>


                <div className="row field-row">
                    <LabelField inputType="textarea" id="vacation_description" className="smaller" label={"Vacation policy description"} description="You can include more details about your vacation policy here." isRequired={false} singleCol={true} placeholder={"e.g. We offer 20 days of paid vacation per year plus public holidays. This works out to a total of 30 days per year."} />
                </div>

                {/* Location */}

                <h3 className='edit-company-heading'>Location and Remote Policy</h3>

                <LabelField inputType="select" id="country" singleCol={true} label={"Company Country"} placeholder="i.e. where your company is based / registered" isSearchable={true} isRequired={true} options={COUNTRIES} isClearable={true} />

                <LabelField inputType="select" id="continent" singleCol={true} label={"Company Continent"} placeholder="i.e. the continent your company is based in" isSearchable={true} isRequired={true} options={[
                    { label: 'Africa', value: 'Africa' },
                    { label: 'Asia', value: 'Asia' },
                    { label: 'Europe', value: 'Europe' },
                    { label: 'North America', value: 'North America' },
                    { label: 'South America', value: 'South America' },
                    { label: 'Oceania', value: 'Oceania' },
                    { label: 'Other', value: 'Other' },
                ]} />


                <div className="row field-row">
                    <LabelField inputType="select" isClearable={true} singleCol={true} id="remote_level" label={"Remote level"} options={[
                        { label: '100% remote offered', value: '100% remote' },
                        { label: 'Hybrid remote', value: 'Offers remote' },
                        { label: '100% in-office', value: 'Onsite' },
                    ]
                    } isRequired={true} />
                </div>

                <div className="row field-row">
                    <LabelField inputType="textarea" id="remote_team_description" className="smaller" label={"Remote working policy description"} description="You can include more details about your vacation policy here." isRequired={false} singleCol={true} placeholder={"You can include more details about your remote working policy here."} />
                </div>


                <h3 className='edit-company-heading'>Employee benefits</h3>

                <div className="benefits-container">
                    <div className="row field-row">
                        <div className='col-sm-6 cols'>
                            <h5>Benefit / Perk</h5>
                        </div>
                        <div className='col-sm-6 cols align-right'>
                            <h5>Short description (optional)</h5>
                        </div>
                    </div>
                    {benefitsHtml}
                </div>


                {/* Links */}
                <h3 className='edit-company-heading'>Links and Social</h3>

                <div className="row field-row">
                    <LabelField inputType="url" id="company_url" label={"Website"} isRequired={true} singleCol={true} placeholder="https://yourcompany.com" />
                </div>


                <div className="row field-row">
                    <LabelField inputType="url" id="twitter_url" label={"Twitter"} isRequired={false} singleCol={true} placeholder="https://www.twitter.com/yourcompany" />
                </div>


                <div className="row field-row">
                    <LabelField inputType="url" id="instagram_url" label={"Instagram"} isRequired={false} singleCol={true} placeholder="https://www.instagram.com/yourcompany" />
                </div>

                <div className="row field-row">
                    <LabelField inputType="url" id="linkedin_url" label={"LinkedIn"} isRequired={false} singleCol={true} placeholder="https://www.linkedin.com/company/yourcompany" />
                </div>







            </div>
        );
    }
}

export default EditCompanyFormFields;