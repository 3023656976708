import React from 'react';

const ResumeReviewHero = () => {
    return (
        <div className="resume-review-hero">
            <div className="content">
                <h1>
                    <span>AI Resume Review:</span>
                    <span>Get Your Score Now</span>
                </h1>
                <p>
                    Get your resume reviewed in seconds. Our AI-powered tool will analyze your resume for issues and provide actionable feedback to help you land more interviews.
                </p>
                <a href="#resume-uploader" className="cop-btn">
                    Get Free Resume Review
                </a>
                <div className="privacy-note">
                    <i className="fa fa-lock"></i> Privacy guaranteed
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewHero; 