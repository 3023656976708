import React from 'react';
import { components } from "react-select";

import Creatable from 'react-select/creatable';
import Select from 'react-select';


class FieldMultiSelectInner extends React.Component {


    render() {
        var { input, isCreatable } = this.props;
        if (isCreatable) {
            return (<Creatable isSearchable={false} formatCreateLabel={(inputValue) => <>Search for <strong>{inputValue}</strong></>} {...this.props} />);
        } else {
            return (<Select {...this.props} isSearchable={false} />);
        }
    }
}

const ValueContainer = ({ children, getValue, ...props }) => {
    let [values, Input] = children;
    if (Array.isArray(values)) {
        values = values.map(({ props }) => props.data.label).join(", ");
    }

  //  console.log("ValueContainer", children, values)


    let numValues = 0;

    if (children && Array.isArray(children) && children.length > 0 && (children[0] != null)) {
        numValues = children[0].length;
    }

    return (
        <div className="react-select-multi">
            <div className='react-select-multi-values'>{numValues > 1 ? numValues + " options selected" : values}</div>


            <div className='react-select-multi-input'>{Input}</div>
        </div>
    );
};

// Custom Control
const Control = ({ children, ...props }) => {
    const onMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
        props.selectProps.onMenuOpen();
    };

    return (
        <components.Control {...props} onMouseDown={onMouseDown}>
            {children}
        </components.Control>
    );
};

const Option = (props) => {
    return (
        <div className='react-select-multi-option'>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class FieldMultiSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            optionSelected: this.initializeSelectedOptions(props.input.value)
        };
    }

    initializeSelectedOptions(inputValue) {
        // Here we map initial values to the actual option objects
        const { options } = this.props;
        if (!inputValue) {
            return [];
        }
    
        // Check if inputValue is a string before trying to split it
        let initialValues = typeof inputValue === 'string' ? inputValue.split(",") : [];
    
        const selectedOptions = options.filter(option => initialValues.includes(option.value));
        return selectedOptions;
    }
    componentDidUpdate(prevProps) {
        if (this.props.input.value !== prevProps.input.value) {
            this.setState({
                optionSelected: this.initializeSelectedOptions(this.props.input.value)
            });
        }
    }

    handleChange = (selectedOptions, input) => {
        // Update state with the entire option object
        this.setState({
            optionSelected: selectedOptions
        });

        input.onChange(selectedOptions);
    };

    // the rest of your component...

    render() {

        var { options, input, isClearable } = this.props;

      //  console.log("Rendering...", this.state)


        return (
            <FieldMultiSelectInner  {...input}
                {...this.props}
                isMulti
                isClearable={isClearable === null ? true : isClearable}
                className='react-select-container'
                classNamePrefix="react-select"
                closeMenuOnSelect={false}
                value={this.state.optionSelected}
                onChange={(selected) => this.handleChange(selected, this.props.input)}
                hideSelectedOptions={false}
                components={{
                    Option,
                    ValueContainer, // Add this
                    Control, // Add this
                }}
                styles={this.customStyles}
                allowSelectAll={true}

            />
        );
    }
}

export default FieldMultiSelect;
