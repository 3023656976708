import React, { Component } from 'react';

class JobCopilotJoin extends Component {
  render() {
    return (
      <div className="job-copilot-join">
        <div className="content">
          <h2>
            Join <span className="text-accent-green">100,000+</span> users currently automating their job applications with{' '}
            <span className="text-accent-green">AI.</span>
          </h2>
            <a
              className="cop-btn btn-signup"
              href="https://4dayweek.jobcopilot.com/signup"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try it now
            </a>

            <p className="payment-notice">Weekly and monthly plans available</p>
          </div>
      </div>
    );
  }
}

export default JobCopilotJoin;
