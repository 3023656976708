import React from 'react';

class FieldCheckboxes extends React.Component {

	onChange(d) {
		var list = this.props.input.value ? this.props.input.value.slice() : [];

		if (!this.checkIfExists(d.target.value, list)) {
			list.push({ label: d.target.value, value: d.target.value})
		} else {
			list = list.filter(item => item.value !== d.target.value)
		}

		this.props.onChange(list, this.props.id);
	}

	checkIfExists(value, values) {
		for (var i = 0; i < values.length; i++) {
			if (values[i].value == value) {
				return true;
			}
		}
		return false;
	}

	render() {

		var { id, options, input } = this.props;

		var optionsHtml = options.map((d) => <div className="row">
			<input {...input} {...this.props} id={d.value} type={"checkbox"} name={d.value} value={d.value} name={id} checked={this.checkIfExists(d.value, input.value)} onChange={(e) => this.onChange(e)} />
			<label htmlFor={d.value}>{d.label}</label>
		</div>);


		return (<>
			{optionsHtml}
		</>
		);
	}
}

export default FieldCheckboxes;
