import React, { Component } from 'react';

class JobCopilotHowItWorks extends Component {
  render() {
    return (
      <div className="job-copilot-how-it-works">
        <div className="content">
          <h2>
            How our <span className="text-accent">Job Copilot</span> Works
          </h2>
          <h6>One-time easy setup, then your AI copilot will automatically apply to 4 and 5 day week jobs for you.</h6>
          <div className="steps">
            <div className="step-wrapper">
              <div className="step">
                <h4>1</h4>
                <p>Tell your copilot what jobs you are looking for, using multiple filters and options e.g. location, salary, job type etc.</p>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <h4>2</h4>
                <p>Upload your resume and respond to a few screening questions so our AI can learn more about you.</p>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <h4>3</h4>
                <p>
                  Every day your copilot will find new relevant jobs from <em>across the web</em> and will auto apply to them on your behalf.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default JobCopilotHowItWorks;
