import React from 'react';
import { connect } from 'react-redux';
import Loader from '../misc/Loader';
import PostedJobTile from '../tiles/PostedJobTile';


class PostedJobsList extends React.Component {


    render() {

        var { data} = this.props;


        if (!data || !data.init || data.loading) {
            return <Loader padded text="Fetching your job posts..." className="min-height" />
        } else if (data.init && data.itemIds.length == 0) {
            return <div className='empty-list'>You haven't purchased any job posts yet</div>
        }

        var posts = data.itemIds.map((d) => {
            return (
                    <PostedJobTile data={data.items[d]} key={"post-" + data.items[d].id_str} />
            );
        });

        return (
            <>
                <ul className="row posts-list">
                    {posts}
                </ul>
            </>
        );
    }
}



export default PostedJobsList;
