
import React from 'react';


export default class ApplicationFormSuccessRedirect extends React.Component {

    // After the component is mounted, redirect the user to google.com after 4 seconds
    /*    componentDidMount() {
            setTimeout(() => {
                window.location = this.props.job.apply_url;
            }, 4000);
        }*/

    render() {

        let { apply_url } = this.props.job;

        return (
            <div className='application-form-success'>
                <h4 className='bold no-margin'>Almost there...</h4>
                <p>There's just <span class="pink">one more stage to go</span>.</p>
          
                <a href={apply_url} target="_blank" className='btn btn-success cta btn-huge' rel="noopener noreferrer">Continue to final stage →</a>
                <p className='margin-top ps'>p.s. please mention 4dayweek.io sent you :)</p>
            </div>
        );
    }
}
