import React from 'react';
import {
    EmailShareButton,
    FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton
} from "react-share";

import {
    EmailIcon,
    FacebookIcon, LinkedinIcon, TelegramIcon, TwitterIcon, WhatsappIcon
} from "react-share";

class JobShareButtons extends React.Component {

    shouldComponentUpdate() {
        return false;
    }

render() {


    var { url } = this.props;

    return (

        <div className="job-share-buttons">
            <p className='align-center text-sm no-padding no-margin padded-bottom-small'>Share this job:</p>
            <EmailShareButton url={url} subject="4dayweek.io">
                <EmailIcon size={32} round={true} />
            </EmailShareButton>

            <TwitterShareButton url={url}>
                <TwitterIcon size={32} round={true} />
            </TwitterShareButton>

            <FacebookShareButton url={url}>
                <FacebookIcon size={32} round={true} />
            </FacebookShareButton>

            <LinkedinShareButton url={url} title="4dayweek.io" source="4dayweek.io">
                <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>

            <WhatsappShareButton url={url}>
                <WhatsappIcon size={32} round={true} />
            </WhatsappShareButton>

            <TelegramShareButton url={url}>
                <TelegramIcon size={32} round={true} />
            </TelegramShareButton>
        </div>

    );
}
}

export default JobShareButtons;