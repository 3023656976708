import React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays'
import loadable from '@loadable/component';
const Creatable = loadable(() => import("react-select/creatable"), { ssr: true });


class FieldSelectQualifier extends React.Component {

	render() {
		var { id } = this.props;


		return (
			<>
				<FieldArray name={id}>
					{({ fields }) => (
						<div className='field-array'>
							{fields.map((name, index) => (
								<div key={name} className="row padded-bottom-small">
									<div className='cols'>
										<div className={"cols-sm col-sm-1"}>
											<Field name={`${name}.id`} component="input" placeholder={"ID"} />
										</div>
										<div className={"cols-sm col-sm-3"}>
											<Field name={`${name}.label`} component="input" placeholder={"Label"} />
										</div>

										<div className={"cols-sm col-sm-3"}>

											<Field name={`${name}.options`}>
												{({ input, meta }) => (
													<Creatable
														{...input}
														isMulti
													/>
												)}
											</Field>
										</div>
										<div className={"cols-sm col-sm-3"}>

											<Field name={`${name}.accepted_values`}>
												{({ input, meta }) => (
													<Creatable
														{...input}
														isMulti
													/>
												)}
											</Field>

										</div>

										<div className={"cols-sm col-sm-1"}>
											<Field name={`${name}.order`} component="input" type="number" placeholder={"Order"} />
										</div>


										<div className="cols-sm col-sm-1">
											<button className="btn btn-md btn-default" type="button" onClick={() => fields.remove(index)}>
												×
											</button>
										</div>
									</div>
								</div>
							))}
							<div className="row">
								<button
									type="button"
									className="btn btn-md btn-default"
									onClick={() => fields.push({})}
								>
									Add
								</button>
							</div>
						</div>
					)}
				</FieldArray>
			</>
		);
	}
}

export default FieldSelectQualifier;
