
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';


class CompanyRanking extends React.Component {

    compare(a, b) {
        if (a.points < b.points) {
            return 1;
        }
        if (a.points > b.points) {
            return -1;
        }
        return 0;
    }

    render() {

        var { company: data } = this.props;
        var details = [];

        if (data.reduced_hours == "Part time") {
            return "";
        }


        // Reduced hours
        var reduced = {
            points: 35,
            label: "Part time roles"
        }
        if (data.reduced_hours == "Offered") {
            reduced = {
                points: 52,
                label: data.reduced_hours_description ?  data.reduced_hours_description :`4 days @ 100% salary`,
            }
        } else if (data.reduced_hours == "Offered 4.5 days") {
            reduced = {
                points: 44,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `4.5 day week`,
            }
        } else if (data.reduced_hours == "Offered every 2nd week") {
            reduced = {
                points: 46,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `4 day week every 2nd week`,
            }
        } else if (data.reduced_hours == "Offered summer") {
            reduced = {
                points: 40,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `4 day week during Summer`,
            }
        } else if (data.reduced_hours == "Generous PTO") {
            let vac = data.vacation && data.vacation > 0 ? data.vacation + " days PTO" : "generous PTO";
        
            reduced = {
                points: 30,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `5 day week (${vac})`,
            }
        } else if (data.reduced_hours == "Flex Fridays") {
            reduced = {
                points: 28,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `5 day week (flex fridays)`,
            }
        }else if (data.reduced_hours == "Unlimited PTO") {
            reduced = {
                points: 25,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `5 day week (unlimited PTO)`,
            }
        } else if (data.reduced_hours == "Flexible hours") {
            reduced = {
                points: 28,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `5 day week (very flexible hours)`,
            }
        } else if (data.reduced_hours == "9 day fortnight") {
            reduced = {
                points: 28,
                label: data.reduced_hours_description ?  data.reduced_hours_description : `9 day fortnight`,
            }
        } else if (data.reduced_hours == "Would consider") {
            reduced = {
                points: 45,
                label: data.reduced_hours_description ?  data.reduced_hours_description : "4 days @ 80% salary"
            }
        }

        details.push(reduced);


        // Vacation
        var vacation = {}
        if (data.benefit_unlimited_pto) {
            vacation = {
                points: 8,
                label: "Unlimited PTO"
            }

        } else if (!data.vacation || data.vacation < 10) {
            vacation = {
                points: 5,
                label: "Good vacation policy"
            }
        } else if (data.vacation >= 99) {
            vacation = {
                points: 8,
                label: "Unlimited PTO"
            }
        } else if (data.vacation >= 24) {
            vacation = {
                points: 10,
                label: data.vacation + " days PTO"
            }
        } else if (data.vacation >= 20) {
            vacation = {
                points: 8,
                label: data.vacation + " days PTO"
            }
        } else if (data.vacation >= 15) {
            vacation = {
                points: 6,
                label: data.vacation + " days PTO"
                //   label: ((data.reduced_hours == "Offered" ? 52 : 0) + data.vacation) + " days total PTO"
                //   label: ((data.reduced_hours == "Offered" ? 52 : 0) + data.vacation) + " days total PTO"
            }
        } else if (data.vacation >= 10) {
            vacation = {
                points: 4,
                label: data.vacation + " days PTO"
                //    label: ((data.reduced_hours == "Offered" ? 52 : 0) + data.vacation) + " days total PTO"
            }
        }

        details.push(vacation);

        // Remote
        if (data.remote_level == '100% remote') {
            details.push({
                points: 11,
                label: "100% remote",
            });
        } else if (data.remote_level == 'Offers remote') {
            details.push({
                points: 9,
                label: "Open to remote working"
            });
        }

        // Flexible
        if (data.benefit_flexible_working_hours) {
            details.push({
                points: 8,
                label: "Flexible working hours",
            });
        }

        if (data.benefit_healthcare) {
            details.push({
                points: 5,
                label: data.benefit_healthcare_label ? data.benefit_healthcare_label : "Healthcare coverage",
            });
        }


        if (data.benefit_retreats) {
            details.push({
                points: 2,
                label: data.benefit_retreats_label ? data.benefit_retreats_label : "Company retreats",
            });
        }





        details.sort(this.compare);
        // Other benefits
        var pointsAdded = 0;
        for (var i = 0; i < details.length; i++) {
            pointsAdded += details[i].points;
        }

        if (data.score > pointsAdded) {
            details.push({
                points: data.score - pointsAdded,
                label: "And much more..."
            });
        }




        // HTML
        var detailsHtml = details.map((d) => <li>
            <span className="number">+{d.points}</span>
            <span className="text">{d.label}</span>
        </li>);



        return (
            <div className="row company-side-box company-ranking ">
                <h3>Scorecard</h3>
                <div className="tag"><FontAwesomeIcon icon={"star"} /> Top 5</div>
                <div className="row company-score">
                    {data.score > 100 ? 100 : data.score}
                    <span className="small"> / 100</span>
                </div>
                <ul className="row company-score-details">
                    {detailsHtml}
                </ul>
            </div >
        )
    }
}

export default CompanyRanking;