
import loadable from '@loadable/component';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IsLoggedIn } from '../../utils/user';
import HelmetMeta from '../../components/global/HelmetMeta';
import * as itemActions from '../../store/item/actions';

// Lazy load react-syntax-highlighter
const SyntaxHighlighter = loadable(() => import('react-syntax-highlighter').then(module => ({ default: module.Prism })));

// Import the vs theme

// CSS for proper code block wrapping
const codeBlockStyle = {
    whiteSpace: 'pre-wrap', // Ensures the code wraps within the container
    wordWrap: 'break-word', // Ensures long words break to fit within the container
    width: '100%', // Ensures the code block takes the full width of the container
    overflow: 'auto', // Ensures any overflow is scrollable
};

class CompanyBadge extends React.Component {



    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const updated = queryParams.get('updated');

        if (updated) {
            NotificationManager.success('', 'Profile updated - why not include your score badge on your site?', 12000);
        }

        this.setState({ updated: updated });

    }

    componentDidUpdate(prevProps, prevState) {
        let { user, company } = this.props;

        if (IsLoggedIn(user) && user.response.company_id && !company.requested && !company.loading) {
            this.props.getCompany({ "id": user.response.company_id });
        }
    }

    handleCopyToClipboard = () => {
        const { user, company } = this.props;
        const badgeCode = `<!-- Start of 4dayweek.io badge code -->
<a href='https://4dayweek.io/company/${company.response.slug}/jobs' target='_blank'>
    <img src='https://media.fourdayweek.io/company_badge?id=${user.response.company_id}' />
</a>
<!-- End of 4dayweek.io badge code -->`;
        navigator.clipboard.writeText(badgeCode).then(() => {
            alert('Code copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    }

    render() {
        let { user, company } = this.props;

        if (!user || user.loading || !user.requested || !user.response || !user.response.company_id) {
            return <div></div>
        }

        let metaTitle = "Your company scorecard widget";
        let metaDesc = "Showcase your company's great work-life balance to the world!";

        var metaImg = "https://media.fourdayweek.io/files/social-share.png";
        let metaUrl = "https://4dayweek.io/dashboard/company";
        let canonical = "https://4dayweek.io/dashboard/company";

        const badgeCode = `
<!-- Start of 4dayweek.io badge code -->
<a href='https://4dayweek.io/company/${company.response.slug}/jobs' target='_blank'>
   <img src='https://media.fourdayweek.io/company_badge?id=${user.response.company_id}' />
</a>
<!-- End of 4dayweek.io badge code -->
        `;

        return (
            <div className="company-badge-wrapper">
                <HelmetMeta title={metaTitle} desc={metaDesc + "."} img={metaImg} url={metaUrl} canonical={canonical} />
                <section className="row section-header">
                    <div className='row hero thin-width'>
                        <h1 className="smaller">{metaTitle}</h1>
                        <div className="row">
                            <h2 className="page-subtitle">{metaDesc}</h2>
                        </div>
                    </div>
                </section>

                <section className='row thin-width no-padding'>
                    <div className="company-badge">
                        <p>We created this badge so you can show off your company's great work-life balance.</p>

                        <p>Why not include it inside your site's footer? ✨</p>

                        <a href={"https://4dayweek.io/company/" + company.response.slug + "/jobs"} target="_blank" rel="dofollow noopener noreferrer" className="">
                            <LazyLoadImage src={"https://media.fourdayweek.io/company_badge?id=" + user.response.company_id} />
                        </a>

                        <div className="row badge-code-wrapper">
                            <SyntaxHighlighter language="html" customStyle={codeBlockStyle} className="badge-code">
                                {badgeCode}
                            </SyntaxHighlighter>

                            <div className="row badge-buttons">
                                <div className='col-sm-6 cols primary-btns'>
                                    <button className="btn btn-success btn-lg cta" onClick={this.handleCopyToClipboard}>Copy code</button>
                                </div>
                                <div className='col-sm-6 cols align-right secondary-btns'>
                                    <Link to={"/company/"+company.response.slug+"/jobs"} className="btn btn-ghost btn-lg">View profile</Link>
                                    <Link to="/dashboard/company" className="btn btn-ghost btn-lg">Edit profile</Link>
                                </div>
                            </div>
                        </div>

                        <p>The score is based on various factors captured in your <Link to="/dashboard/company" className="btn btn-link btn-sm">company profile</Link> such as:</p>
                        <ul>
                            <li>Your working hours</li>
                            <li>Your vacation policy</li>
                            <li>Your remote working policy</li>
                            <li>Your flexibility</li>
                            <li>Your benefits</li>
                            <li>And more...</li>
                        </ul>

                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.item.user,
        company: state.item.dashboard_company,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    getCompany: (data) => {
        dispatch(itemActions.getItem("dashboard", data, "dashboard_company", "get_company"));
    },
});

CompanyBadge = connect(mapStateToProps, mapDispatchToProps)(CompanyBadge);

export default withRouter(CompanyBadge);
