
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as URLTools from '../../utils/url';
import LabelField from '../fields/LabelField';
import { FOUR_DAY_OFF_LOOKUP, REDUCED_HOURS_LOOKUP, GenerateLookups, ConvertLookupToOptions, IS_HIRING_LOOKUP, EXTENDED_LOCATIONS_LOOKUP } from '../../utils/filters';

class CompanyFilters extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            lookups: GenerateLookups({
                reduced_hour: REDUCED_HOURS_LOOKUP,
                location: EXTENDED_LOCATIONS_LOOKUP,
                is_hiring: IS_HIRING_LOOKUP,
                four_day_off: FOUR_DAY_OFF_LOOKUP,
            }),
            inputValues: {
                reduced_hours: ConvertLookupToOptions(REDUCED_HOURS_LOOKUP),
                location: ConvertLookupToOptions(EXTENDED_LOCATIONS_LOOKUP),
                is_hiring: ConvertLookupToOptions(IS_HIRING_LOOKUP),
                four_day_off: ConvertLookupToOptions(FOUR_DAY_OFF_LOOKUP),
            }

        }
    }

    
    shouldComponentUpdate(nextProps, nextState) {
        let { location: lastLocation } = this.props;
        let { location: nextLocation } = nextProps;

        return URLTools.HasPageChanged(lastLocation, nextLocation);
    }

    onSubmit(values) {

        if (values.reduced_hour && Array.isArray(values.reduced_hour) && values.reduced_hour.length > 0) {
            values.reduced_hour = values.reduced_hour.map(obj => obj.value).join(',');
        }

        let redir = URLTools.GenerateFiltersURL(values, "/companies");


        this.props.history.push(redir);
    }


    render() {
        var { location } = this.props;
        let { lookups, inputValues } = this.state;
        let defaults = URLTools.GetQueryParams(location.pathname + location.search, lookups);


        // Engineering, Product, Marketing, Data
        return (
            <div className='row'>

                <div className="row filters company-filters">
                    <div className="row search">

                        <Form
                            onSubmit={(values) => this.onSubmit(values)}
                            mutators={{
                                ...arrayMutators,

                            }}
                            initialValues={defaults}
                            render={({ handleSubmit, submitting, submitted, errors, pristine, values, form, form: {
                                mutators: { push, pop }
                            } }) => (
                                <>
                                    <form onSubmit={handleSubmit} data-sparkloop-upscribe='false'>
                                        <div className="form-wrapper">

                                            <div className="row">

                                                <div className="col-sm-10 no-padding">

                                                    <div className='row'>
                                                        <div className="col-sm-7 no-padding company-reduced-hours">
                                                            <LabelField inputType="multiselect" id="reduced_hour"
                                                                isClearable={true} singleCol={true} options={inputValues.reduced_hours}
                                                                isCreatable
                                                                placeholder={"Search by shorter week..."} />

                                                            <FontAwesomeIcon icon={"sun"} className="search-icon" />

                                                            <OnChange name="reduced_hour">
                                                                {(value, previous) => { handleSubmit() }}
                                                            </OnChange>

                                                        </div>

                                                        <div className="col-sm-5 no-padding location">
                                                            <LabelField inputType="select" id="location" label={<FontAwesomeIcon icon={"globe"} />} singleCol={true} isClearable={true} isSearchable={true} options={inputValues.location} placeholder={"Location"} />

                                                            <OnChange name="location">
                                                                {(value, previous) => { handleSubmit() }}
                                                            </OnChange>
                                                        </div>
                                                    </div>

                                                    <div className='row extra-filters'>

                                    
                                                        <LabelField inputType="select" id="is_hiring" singleCol={true} isClearable options={inputValues.is_hiring} placeholder={"Is hiring?"} />
                                                        <OnChange name="is_hiring">
                                                            {(value, previous) => { handleSubmit() }}
                                                        </OnChange>

                                                        <LabelField inputType="select" id="four_day_off" singleCol={true} isClearable options={inputValues.four_day_off} placeholder={"Which day is off"} className="hide-mobile"  />
                                                        <OnChange name="four_day_off">
                                                            {(value, previous) => { handleSubmit() }}
                                                        </OnChange>
                                                    </div>




                                                </div>



                                                <div className="col-sm-2 no-padding buttons">
                                                    <button className="btn btn-success btn-huge cta">Search...</button>
                                                </div>

                                            </div>



                                        </div>


                                        { /* <pre>{JSON.stringify(values, 0, 2)}</pre>*/}
                                    </form>
                                </>
                            )}
                        />

                    </div>


                </div>




            </div >
        );
    }
}





const mapStateToProps = (state) => {
    return {
    };
};


const mapDispatchToProps = (dispatch, ownProps) => ({

});

CompanyFilters = connect(mapStateToProps, mapDispatchToProps)(CompanyFilters);



export default withRouter(CompanyFilters);