
import { faFacebookF, faInstagram, faLinkedin, faReddit, faTelegram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import * as listActions from '../../store/list/actions';
import * as FormatTools from '../../utils/format';
import { CONTINENTS, LEVELS_LOOKUP, REDUCED_HOURS_LOOKUP_ALT } from '../../utils/filters';

class Footer extends React.Component {


	componentDidMount() {

		var { data } = this.props;
		if (!data.init && !data.loading) {
			this.props.getFooterLinks();
		}
	}

	render() {

		var { data } = this.props;

		if (data.loading) {
			return "";
		}

		var jobsHtml = Object.entries(data.items).map(([key, value]) => {


			return (
				<li key={value.url}>
					<Link to={value.url} className="btn btn-link btn-sm light" onClick={() => document.body.scrollTop = 0}>{value.label}</Link>
				</li>
			)
		});


		var continentsHtml = Object.entries(CONTINENTS).map(([key, value]) => {
			return (
				<li key={"Remote Jobs in " + value}>
					<Link to={"/jobs/" + key} className="btn btn-link btn-sm light" onClick={() => document.body.scrollTop = 0}>
						{value}
					</Link>
				</li>
			);
		});


		var countriesJobsHtml = ["Canada", 'UK', "USA"].map((element) => {
			return <li key={"4 day week jobs in the " + element}><Link to={"/jobs/" + FormatTools.Slugify(element)} className="btn btn-link btn-sm light" onClick={() => document.body.scrollTop = 0}>{element}</Link></li>
		});

		var countriesHtml = ["Australia", "Austria", "Canada", "Denmark", "France", "Germany", "India", "Ireland", "Netherlands", "New Zealand", "Norway", "Portugal", "Spain", 'UK', "USA"].map((element) => {
			return <li key={"4 day week companies in the " + element}><Link to={"/companies/" + FormatTools.Slugify(element)} className="btn btn-link btn-sm light" onClick={() => document.body.scrollTop = 0}>{element}</Link></li>
		});


		let companyLinks = [
			{ key: "advertise", external_url: "https://www.passionfroot.me/4dayweek", text: "Advertise" },
			{ key: "add-a-company", url: "/add-company", text: "Add company" },
			{ key: "contact us", url: "/contact", text: "Contact" },
			{ key: "auto-apply", url: "/auto-apply", text: "Auto Apply", className: "padded-top" },
			{ key: "resume-builder", external_url: "https://aiapply.co/resume-builder?via=philip", text: "AI Resume Builder" },
			{ key: "resume-review", external_url: "https://4dayweekresumes.com/", text: "Resume Review", className: "margin-bottom" },
			{ key: "api", external_url: "https://4dayweek.io/api", text: "API", className: "padded-top" },
			{ key: "rss", external_url: "https://4dayweek.io/rss", text: "RSS" },
		]

		let articleLinks = [
			{ key: "blog", url: "/blog", text: "Blog" },
			{ key: "advice", url: "/advice", text: "Advice and Guides" },
			{ key: "work-life-balance", url: "/work-life-balance", text: "Work life balance" },
			{ key: "remote-work", url: "/remote-work", text: "Remote work" },
			{ key: "industries", url: "/industries", text: "Industries" },
			//{ key: "studies", url: "/studies", text: "Studies & Pilots" },
			{ key: "case-studies", url: "/case-studies", text: "Case studies" },
			{ key: "schedules", url: "/schedules", text: "Schedules" },
			{ key: "salaries", url: "/salaries", text: "Salary guides", className: "padded-top" },
			{ key: "interview-processes", url: "/interview-processes", text: "Interview Processes" },
			/*{ key: "job-descriptions", url: "/job-descriptions", text: "Job Descriptions" },*/
			{ key: "interview-questions", url: "/interview-questions", text: "Interview Questions" },
			{ key: "templates", url: "/templates", text: "Templates" },
			{ key: "career-paths", url: "/career-paths", text: "Career Paths" },
			{ key: "career-advice", url: "/career-advice", text: "Career Advice" },
			{ key: "ai-resume-builders", url: "/career-advice/best-ai-resume-builders", text: "Best AI Resume builders", className: "padded-top" },
		]

		let socialLinks = [
			{ key: "twitter", url: "https://twitter.com/4dayweekio", text: "Twitter", icon: faTwitter },
			{ key: "linkedin", url: "https://www.linkedin.com/company/4-day-week/", text: "LinkedIn", icon: faLinkedin },
			{ key: "instagram", url: "https://www.instagram.com/4dayweekio/", text: "Instagram", icon: faInstagram },
			{ key: "tiktok", url: "https://www.tiktok.com/@4dayweekio", text: "TikTok", icon: faTiktok },
			{ key: "facebook", url: "https://www.facebook.com/4dayweekio", text: "Facebook", icon: faFacebookF },
			{ key: "telegram", url: "https://t.me/fourdayweek", text: "Telegram", icon: faTelegram },
			{ key: "reddit", url: "https://www.reddit.com/r/4dayweekjobs/", text: "Reddit", icon: faReddit },
		]


		var pilots = [
			{ key: "country-pilots", url: "/countries", text: "Countries" },
			{ key: "state-pilots", url: "/regions", text: "States and Regions" },
		];

		var reduced = Object.entries(REDUCED_HOURS_LOOKUP_ALT).map(([key, value]) => {
			return {
				key: key,
				url: key,
				text: value // transforming the value to lower case and using space as in examples
			};
		});

		var levels = Object.entries(LEVELS_LOOKUP).map(([key, value]) => {
			return {
				key: key,
				url: key,
				text: value // transforming the value to lower case and using space as in examples
			};
		});



		let footerBottom = [
			{ key: "terms", url: "/terms", text: "Terms and conditions" },
			{ key: "privacy policy", url: "/privacy", text: "Privacy policy" },
		]

		return (
			<section className="footer row">



				<div className="footer-content row">


					<div className="top-row row">

						{/* Left */}
						<div className="col-sm-4 cols triple">
							<h4>Get involved</h4>
							<ul className="bottom-menu">
								{companyLinks.map((element) => {
									if (element.external_url) {
										return <li key={element.key} className={element.className}><a href={element.external_url} className="btn btn-link btn-md light" target="_blank" rel="noopener noreferrer">{element.text}</a></li>
									}

									return <li key={element.key} className={element.className}><Link to={element.url} className="btn btn-link btn-md light">{element.text}</Link></li>
								})}
							</ul>

						</div>

						<div className="col-sm-4 cols triple">
							<h4>Resources</h4>
							<ul className="bottom-menu">
								{articleLinks.map((element) => {
									return <li key={element.key} className={element.className}><Link to={element.url} className="btn btn-link btn-md light">{element.text}</Link></li>
								})}
							</ul>

						</div>

						{/* right */}
						<div className="col-sm-4 cols social-list">

							<ul className=''>

								{/*	<li className="built-in-public" key="build in public">
									<a href="https://twitter.com/philostar" target="_blank" rel="noopener noreferrer nofollow" className={"btn btn-link  btn-lg centered"}>
										<LazyLoadImage
											threshold={100}
											src={meImg}
											height={40}
											width={40}
											className="me"
											alt="Phil McParlane Twitter"
										/> <FontAwesomeIcon className="hide-desktop" icon={faTwitter} /> Built in public by Phil 👋
									</a>
							</li>*/}

								<li key="Social links">

									<ul className='logos'>
										{socialLinks.map((element) => {
											return <li key={element.key}><a href={element.url} alt={element.text} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-ghost btn-huge btn-circle icon-only" aria-label={element.text}><FontAwesomeIcon icon={element.icon} /></a></li>
										})}

									</ul>

								</li>

								<li>
									<div className="product-hunt">
										<a href="https://www.producthunt.com/posts/4-day-week-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-4&#0045;day&#0045;week&#0045;2" target="_blank" rel="noopener noreferrer"><img loading="lazy" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=321018&theme=neutral" alt="4&#0032;Day&#0032;Week - Software&#0032;jobs&#0032;with&#0032;a&#0032;better&#0032;work&#0045;life&#0032;balance | Product Hunt" style={{ width: "250px", height: "54px" }} width="250" height="54" /></a>

									</div>
								</li>

								<li className="jbs">
									<a href="https://jobboardsearch.com/job-boards/4-day-week?ref=featuredbadge&jb=4-day-week" target="_blank" rel="noopener noreferrer"><img src={"assets/jobboardsearch.png"} alt="Job Board Search" /></a>

								</li>

								<li className='address'>
									<strong>Sellframe Ltd</strong><br />
									Made in Scotland 🏴󠁧󠁢󠁳󠁣󠁴󠁿
								</li>



							</ul>


						</div>
					</div>



					<div className="bottom-row">


						{/* left */}
						<div className="col-sm-8 cols hide-mobile">
							<h4>Reduced Hours Jobs by Type</h4>
							<ul className="bottom-menu two-columns">
								{jobsHtml}
								{/*<li key={"all-job-filters"}>
									<Link to={"/job-categories"} className="btn btn-link btn-sm light" onClick={() => document.body.scrollTop = 0}>View all job categories</Link>
									</li> */}
							</ul>

						</div>


						{/* right */}
						<div className="col-sm-4 cols triple">

							<h4>Jobs by Working Schedule</h4>
							<ul className="bottom-menu margin-bottom-large full-width">
								{reduced.map((element) => {
									return <li key={element.key} className={element.className}><Link to={"/jobs/" + element.url} className="btn btn-link btn-md light">{element.text}</Link></li>
								})}
							</ul>

							<h4 className='margin-top-large'>Jobs by Seniority</h4>
							<ul className="bottom-menu margin-bottom-large full-width">
								{levels.map((element) => {
									return <li key={element.key} className={element.className}><Link to={"/jobs/" + element.url} className="btn btn-link btn-md light">{element.text}</Link></li>
								})}
							</ul>


							<h4 className='margin-top-large'>4 Day Work Week Studies</h4>
							<ul className="bottom-menu margin-bottom-large full-width">
								{pilots.map((element) => {
									return <li key={element.key} className={element.className}><Link to={element.url} className="btn btn-link btn-md light">By {element.text}</Link></li>
								})}
							</ul>


							<h4 className='margin-top-large'>Jobs by Location</h4>
							<ul className="bottom-menu margin-bottom-large full-width">
								{continentsHtml}

								{countriesJobsHtml}
							</ul>

							<h4 className='margin-top-large'>Companies by Location</h4>
							<ul className="bottom-menu full-width">
								{countriesHtml}

							</ul>
						</div>


					</div>


				</div>

				<div className="footer-bottom row">
					<ul className='footer-bottom-content'>
						<li>Copyright © {new Date().getFullYear()} Sellframe Ltd. All Rights Reserved.</li>
						{footerBottom.map((element) => {
							return <li key={element.key}><Link to={element.url} className="btn btn-link btn-md light">{element.text}</Link></li>
						})}
					</ul>
				</div>

			</section>

		);
	}
}


const mapStateToProps = (state) => {
	return {
		data: state.list.footer_links,
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	getFooterLinks: () => {
		dispatch(listActions.fetchItems("footer", "links"));
	},
});

Footer = connect(mapStateToProps, mapDispatchToProps)(Footer);


export default withRouter(Footer);



