import * as URL from '../../utils/url';
import {
  apiURL
} from '../vars';
import axios from 'axios';


export const REQUEST_LIST_ITEMS = 'REQUEST_LIST_ITEMS';
export const RECEIVE_LIST_ITEMS = 'RECEIVE_LIST_ITEMS';
export const RESET_LIST_ITEMS = 'RESET_LIST_ITEMS';

const requestItems = (page, id, params, save) => ({
  type: REQUEST_LIST_ITEMS,
  params: params,
  page: page,
  id: id,
  save: save,
});


const receiveItems = (page, id, response, params, save) => ({
  type: RECEIVE_LIST_ITEMS,
  page: page,
  id: id,
  params: params,
  response: response,
  save: save
});


export const resetItems = (page, id) => ({
  type: RESET_LIST_ITEMS,
  page: page,
  id: id,
});

export const fetchItems = (page, id, params = {}, save = null) => {
  return (dispatch, getState) => {

    var lastReq = getState().list;

    let key = page+"_"+id;
    if(save){
      key = save;
    }
    
    if(lastReq[key]){
      lastReq = lastReq[key].lastRequest;
    }


    var options = {
      method: "GET",
    }

    var urlParams = URL.AsParams(params)
    var req = `/data/` + page + `/` + id + `.json?${urlParams}`

    dispatch(requestItems(page, id, params, save));



    return fetch(req, options).then(function (response) {
      return response.json();
    }).then(function (json) {

      dispatch(receiveItems(page, id, json, params, save));
    });
  };
};

export const fetchItemsSSR = (page, id, params = {}) => {
  var options = {
    method: "GET",
  }

  var urlParams = URL.AsParams(params)
  var req = apiURL + page + `/` + id + `.json?${urlParams}`


  var options = {
    method: "GET",
  }

  var ret = axios.get(req, options).then(response => {
    return response.data
  });
  return ret;
};