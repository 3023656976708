import React from 'react';
import SubscriberForm from './SubscriberForm';
import { IsLoggedIn } from '../../utils/user';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class SubscriberInlineWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSubscriptionForm: true,
        };

    }

    componentDidMount() {
        let { alwaysShow, user } = this.props;

        if (alwaysShow) {
            return;
        } else if (localStorage.getItem('has_subscribed') == 'true') {
            this.setState({ showSubscriptionForm: false });
        } else if (IsLoggedIn(user)) {
            this.setState({ showSubscriptionForm: false });
        }

    }

    render() {

        var { className } = this.props;
        let { showSubscriptionForm } = this.state;

        if(!showSubscriptionForm) {
            return null;
        }

        return (
            <div className={"row subscriber " + className}>

                <SubscriberForm {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.item.user,
    };
};


SubscriberInlineWrapper = connect(mapStateToProps, null)(SubscriberInlineWrapper);

export default withRouter(SubscriberInlineWrapper);
