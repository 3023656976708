
import React from 'react';


export default class ApplicationFormFail extends React.Component {
    render() {

        let {job} = this.props;
        let applyUrl = job.apply_email ? "mailto:" + job.apply_email+"?subject="+job.title+" application (4dayweek.io)" : job.apply_url;


        return (
        <div className='application-form-fail padded'>
            <p>Based on the answers you provided, your application would be <em>auto rejected</em> by {job.company ? job.company.name : "the company"} for this role. Typically, applications are automatically rejected for a number of reasons:</p>
            <ul>
                <li>Not having the required location</li>
                <li>Not having the required experience</li>
                <li>Not having the required skills</li>
            </ul>
            <p>If you entered your details incorrectly or you are able to rectify these issues (e.g. moving country), you can still <a href={applyUrl} target="_blank" className='btn btn-link' rel="noopener noreferrer">proceed with your application</a>.</p>
        </div>
        );
    }
}
