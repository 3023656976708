import React, { Component } from 'react';
import JobCopilotTestimonial from './JobCopilotTestimonial';
class JobCopilotPlans extends Component {
  render() {
    return (
      <div className="job-copilot-empower-yourself">
        <div className="content">
          <h2>Start applying on autopilot...</h2>
          <h6>Get 10x more interviews using our JobCopilot.</h6>
          <div className="plans">
            <div className="plan">
              <div className="plan-head">Premium</div>
              <div className="plan-content">
                <ul className="plan-description">
                  <li>1 Copilot</li>
                  <li>Up to 20 Jobs auto-applied per day</li>
                  <li>Job Application Tracker</li>
                  <li>​​ </li>
                </ul>
                <a href="https://4dayweek.jobcopilot.com/signup" className="cop-btn  cop-btn-small cop-btn-pink btn-signup">Get started now...</a>
              </div>
            </div>
            <div className="plan">
              <div className="plan-head">Elite</div>
              <div className="plan-content">
                <ul className="plan-description">
                  <li>3 Copilots</li>
                  <li>Up to 50 Jobs auto-applied per day</li>
                  <li>Advanced Job Matching</li>
                  <li>Job Application Tracker</li>
                </ul>
                <a href="https://4dayweek.jobcopilot.com/signup" className="cop-btn cop-btn-small cop-btn-pink btn-signup">Get started now...</a>
              </div>
            </div>
          </div>


          <JobCopilotTestimonial
            quote={<>
              I found a remote accounting position very fast <b>without the hassle of combing through job descriptions and lengthy applications forms</b>. Highly recommended!
            </>}
            authorName="Alexandria Chambers"
            authorPosition="Accountant"
            authorImage="https://media.fourdayweek.io/files/alexandria.jpg"
          />
        </div>
      </div>
    );
  }
}

export default JobCopilotPlans;
