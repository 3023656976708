import React from 'react';

class JobLocationWarning extends React.Component {

    shouldComponentUpdate() {
        return false;
    }


    render() {
        var { is_remote, location_city, location_country, location_continent, company, loc } = this.props;


        var apply_location = "";

        // Location
        if (is_remote && location_city) {
            apply_location = location_city + " only";
        } else if (is_remote && location_country) {
            apply_location = location_country + " only";
        } else if (is_remote && location_continent) {
            apply_location = location_continent + " only";
        }

        if (company && company.remote_relocation_budget) {
            apply_location = apply_location.replace(" only", "") + " / relocate";
        }

        if (apply_location) {
            apply_location = <span className='apply-location'>{apply_location}</span>
        }


        // Location
        var loc = "";
        var locPrefix = !location_city ? ["USA", "UK", "United Kingdom"].indexOf(location_country) > -1 ? "the " : "" : "";

        if (location_city) {
            loc = location_city;
            if (location_country) {
                loc += ", " + location_country;
            }
        } else if (location_country) {
            if (location_country == "United Kingdom") {
                loc = "UK";
            } else {
                loc = location_country;
            }
        } else if (location_continent) {
            loc = location_continent;
        }

        // Location warning
        if (loc) {
            return <p className='apply-warning'>Only considering candidates eligible to work in {locPrefix}<strong>{loc}</strong> ⚠️</p>;
        } else {
            return "";
        }
    }

}

export default JobLocationWarning;