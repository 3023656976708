
const encodeURIComp = (comp) => (
  comp ? encodeURIComponent(comp) : ''
);

export function AsParams(obj) {
  if (!obj) {
    return '';
  }
  var params = Object.keys(obj).reduce((prev, key, i) => (
    `${prev}${i !== 0 ? '&' : ''}${key}=${encodeURIComp(obj[key])}`
  ), '');
  return params;
};

export function IsValidURL(urlString) {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  let out = !!urlPattern.test(urlString);
  return out;
}


export function ExtractHostname(url) {
  var hostname;

  if (!url || url == "") {
    return "";
  }
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("://") > -1) {
    hostname = url.split('/')[2];
  }
  else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];

  return hostname.replace("www.", "");
}


// Splits out the url (e.g. /jobs/data/north-america/fully-remote) into {location: "north-america", remote: "fully-remote", "filter": "data"}
// The order of the filters is not important
export function GetQueryParams(url, filtersLookup, defaultFilter = "") {

  //console.log("Get query params", url, filtersLookup, defaultFilter)

  let urlParams = {};

  if (url.startsWith("remote-jobs") || url.startsWith("/remote-jobs")) {
    urlParams["remote_option"] = "remote";
  } else if (url.startsWith("hybrid-jobs") || url.startsWith("/hybrid-jobs")) {
    urlParams["remote_option"] = "hybrid";
  } else if (url.startsWith("onsite-jobs") || url.startsWith("/onsite-jobs")) {
    urlParams["remote_option"] = "onsite";
  } else if (url.startsWith("jobs") && urlParams["remote_option"]) {
    delete urlParams["remote_option"];
  }


  url = url.split("?")[0];

  url = url.replace(/^\/+|\/+$/g, ""); // remove leading and trailing slashes

  let spl = url.split(/[/,]+/);

  // Remove the first element
  spl.shift();

  // Filter out empty elements
  spl = spl.filter(part => part);


  if (spl.length == 0) {
    return urlParams;
  }

  for (let s of spl) {
    let cur = filtersLookup[s];
    if (cur) {
      // if key already exists, append to it
      if (urlParams[cur.key]) {
        urlParams[cur.key] += "," + s;
      } else {
        urlParams[cur.key] = s;
      }
    } else if (defaultFilter) {
      urlParams[defaultFilter] = s
    }
  }



  return urlParams;
}



export function HasPageChanged(oldLocation, newLocation) {
  return (oldLocation.pathname + oldLocation.search) != (newLocation.pathname + newLocation.search);
}


// Takes in the form values, the 2x main input names and the URL prefix and splits out the URL it should redirect to
export function GenerateFiltersURL(params, prefix = "/companies", ignore = []) {

  //var paramsString = "/" + Object.values(params).map(value => value && encodeURIComponent(value).replace(/%20/g, "-").toLowerCase()).join("/");
  let paramsString = "/" + Object.entries(params).map(([key, value]) => {

    if (value && !ignore.includes(key)) {
      // Encode the value, but preserve commas
      value = encodeURIComponent(value).replace(/%2C/g, ',');
      // Replace spaces with dashes
      value = value.replace(/%20/g, '-');
      return value.toLowerCase();
    }
  }).join("/");

  let finalUrl = (prefix + paramsString).replace(/\/$/, "");

  return finalUrl.replace("//", "/");
}