import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as URLTools from '../../utils/url';
import { AddUTM } from '../../utils/format';

import { faLinkedin, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

class CompanyLinks extends React.Component {

    render() {
        var { company: companyData, info } = this.props;

        if(!info || info.industry == "staffing & recruiting"){
            return "";
        }

        var company_url = AddUTM(companyData.company_url);

        var contact_url = "/contact?message=" + encodeURIComponent("Hi there,\n\nUnfortunately there is incorrect data on " + companyData.name + "'s company profile...\n\nFill in the rest of your message here\n\ne.g. they don't actually have a 4 day work week")
        var add_jobs_url = "/contact?message=" + encodeURIComponent("Hi there,\n\nI'd like to add jobs to " + companyData.name + "'s company page\n\nWe are currently hiring for:\n\n- Software Engineer (add link to job posting)\n- Data Scientist (add link to job posting)\n\n...")

        return (
            <div className="row company-links margin-top align-center ">


                {companyData.company_url ?
                    <div className="row margin-bottom">
                        <a onClick={(e) => e.stopPropagation()} href={company_url} target="_blank" className="btn btn-link btn-md" rel="noopener noreferrer nofollow" aria-label="Website">Visit {URLTools.ExtractHostname(companyData.company_url)}  <FontAwesomeIcon icon={"external-link-alt"} /></a>
                    </div>
                    : ""}

                <ul className="company-social">
                    {companyData.twitter_url ?
                        <li><a onClick={(e) => e.stopPropagation()} href={companyData.twitter_url} target="_blank" rel="noopener noreferrer nofollow" className="btn btn-primary circle btn-md icon-only" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a></li>
                        : ""}

                    {companyData.linkedin_url ?
                        <li><a onClick={(e) => e.stopPropagation()} href={companyData.linkedin_url} className="btn btn-primary circle icon-only btn-md" target="_blank" rel="noopener noreferrer nofollow" aria-label="Linkedin"><FontAwesomeIcon icon={faLinkedin} /></a></li>
                        : ""}


                    {companyData.instagram_url ?
                        <li><a onClick={(e) => e.stopPropagation()} href={companyData.instagram_url} className="btn btn-primary circle icon-only btn-md" target="_blank" rel="noopener noreferrer nofollow" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a></li>
                        : ""}

                </ul>

                <p className="row margin-top-large align-center company-extra-links">
                    <Link to={contact_url} target="_blank" rel="noindex nofollow" className="btn btn-link btn-sm">Report incorrect data</Link>
                </p>
                <p className="row margin-top align-center">
                    <Link to={add_jobs_url} target="_blank" rel="noindex nofollow" className="btn btn-link btn-sm">Add jobs to this page</Link>
                </p>
            </div>
        )
    }
}

export default CompanyLinks;