import CompanyTile from '../tiles/CompanyTile';
import Loader from '..//misc/Loader';
import React from 'react';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import { Link } from 'react-router-dom';
import CompanyAdvertTile from '../tiles/CompanyAdvertTile';
import SubscriberInlineWrapper from '../subscriber-form/SubscriberInlineWrapper';


class CompaniesList extends React.Component {



    shouldComponentUpdate() {
        return true;
    }

    render() {

        var { data, max, countrySlug, adverts, hideAdvert } = this.props;


        if (!data || !data.init || data.loading) {
            return <Loader padded text="Fetching 4 day work week companies..." className="min-height" />
        } else if (data.init && data.itemIds.length == 0) {
            return <div className='empty-list'>Unfortunately no companies fit this criteria - check back soon</div>
        }


        var i = 0;
        let j = 0;
        var companies = data.itemIds.map((d) => {
            i += 1;

            if (max && i > max) {
                return "";
            }

            let ad = "";
            if (!hideAdvert && (i == 4 || ((i % 10) == 0)) && adverts.init && !adverts.loading) {
                ad = <CompanyAdvertTile page="companies" pos={j} />
                j += 1;
                if (j > adverts.itemIds.length - 1) {
                    j = 0;
                }
            }

            // Subscribe form
            let subscribe = "";
            if ((i == 9) || (i % 40 == 0)) {
                subscribe = <SubscriberInlineWrapper className="subscriber-green" />
            }



            return (
                <>
                    {subscribe ? subscribe : ad}
                    <CompanyTile data={data.items[d]} key={"company-" + data.items[d].id_str} />

                </>
            );

        });

        return (
            <>
                <ul className="row companies-list">
                    {companies}

                </ul>

                {max && data.itemIds.length > max ? <Link to={"/companies/" + (countrySlug ? countrySlug : "")} className="btn btn-lg btn-default">See all {data.itemIds.length} companies</Link> : ""}
            </>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        adverts: state.list.adverts_list_home,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

CompaniesList = connect(mapStateToProps, mapDispatchToProps)(CompaniesList);

export default CompaniesList;
