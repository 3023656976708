
import React from 'react';
import MarkdownRenderer from '../misc/MarkdownRenderer';



class JobDescription extends React.Component {

    render() {
        var { description, location_country } = this.props;

        var parsedDescription = description;

        var highlight = ["4 day week", "4 day work week", "4-day week", "4-day workweek", "4 day work-week", "four day work week", "four day week", "four-day week", "32hrs", "32 hours", "4 days", "4 day", "four days", "four day", "pto"]

        for (var i = 0; i < highlight.length; i++) {
            var curH = highlight[i];

            var temp = parsedDescription.replace(curH, "***" + curH + "***");
            if (temp != parsedDescription) {
                parsedDescription = temp;
                break;
            }
        }

        return (

            <div className="row job-description-text">
                <MarkdownRenderer>{parsedDescription}</MarkdownRenderer>

                

                {location_country && parsedDescription.length < 500 ? <p className='margin-top'>Please only apply for this position if you are legally allowed to work in the country and have the skills required. If you aren't legally allowed, you'll likely be rejected automatically by the company's application tracking system so you'll be wasting your time.</p> : ""}
            </div>

        );
    }
}

export default JobDescription;