
import React from 'react';
import { Link } from 'react-router-dom';
import { Slugify } from '../../utils/format';

class JobTags extends React.Component {

    shouldComponentUpdate() {
        return false;
    }



    render() {
        var data = this.props;
        // Offer tag

        const tags = [];
        let hours = this.props.hours || this.props.company_hours_full_time || null;

        // Reduced hours
        if (data.company?.reduced_hours_description) {
            tags.push(<li key="reduced-hours" className='reduced-hours'>{data.company.reduced_hours_description}</li>);
        } else {


            let vac = data.company.vacation && data.company.vacation > 0 ? data.company.vacation + " days vacation" : "generous PTO";

            const reducedHoursType = {
                "Part time": ({ text: 'Part time job', link: '/jobs/part-time' }),
                "Offered": ({ text: `4 ${hours ? " x " + Math.round(hours / 4.0) + "hr" : ""} days`, link: '/jobs/4-day-week' }),
                "Offered summer": ({ text: '4 day week during Summer', link: '/jobs/summer-fridays' }),
                "Offered 4.5 days": ({ text: '4.5 day week', link: '/jobs/half-day-fridays' }),
                "Offered every 2nd week": ({ text: '4 day week every 2nd week', link: '/jobs/rotating-4-day-week' }),
                "Would consider": ({ text: `4 ${hours ? " x " + Math.round(hours / 4.0) + "hr" : ""} days`, link: '/jobs/4-day-week-pro-rata' }),
                "Generous PTO": ({ text: `5 day week (${vac})`, link: '/jobs/generous-pto' }),
                "Unlimited PTO": ({ text: '5 day week (unlimited PTO)', link: '/jobs/unlimited-pto' }),
                "9 day fortnight": ({ text: hours == 40 ? "9/80 Schedule" : '9 day fortnight', link: '/jobs/9-day-fortnight' }),
                "Flex Fridays": ({ text: '5 day week (flex fridays)', link: '/jobs/flex-fridays' }),
                "Flexible hours": ({ text: '5 day week (very flexible hours)', link: '/jobs/flexible-hours' }),
            };

            const reducedHours = reducedHoursType[data.reduced_hours];

            if (reducedHours) {
                const { text, link } = reducedHours;
                tags.push(
                    <li key={data.reduced_hours.replace(/ /g, '-')} className={data.reduced_hours.replace(/ /g, '-').toLowerCase()}>
                        <Link to={link} onClick={() => document.body.scrollTop = 0}>
                            <span className="reduced-hours">{text}</span>
                        </Link>
                    </li>
                );
            }
        }

        // Salaries
        if (!data.company || !data.company.reduced_hours_description) {
            if (data.salary_lower && data.salary_upper && !data.salary_estimated && data.salary_period && data.salary_currency) {
                let lower = ""
                let upper = "";
                if (data.salary_period.indexOf("per year") > -1) {
                    lower = parseInt(data.salary_lower / 1000) + "k";
                    upper = parseInt(data.salary_upper / 1000) + "k";
                } else {
                    lower = parseInt(data.salary_lower) + "";
                    upper = parseInt(data.salary_upper) + "";
                }

                let period = "";
                if(data.salary_period && data.salary_period != "per year"){
                    period = " " + data.salary_period;
                }


                var salaryString = data.salary_lower != data.salary_upper ? <>{data.salary_currency}{lower} - {upper}{period}</> : <>{data.salary_currency}{lower}{period}</>;
                tags.push(<li key="salary" className='tile-salary'>{salaryString}</li>);
            } else if (data.reduced_hours == "Offered" || data.reduced_hours == "Offered summer" || data.reduced_hours == "Offered every 2nd week" || data.reduced_hours == "Offered 4.5 days" || data.reduced_hours == "Flex Fridays" || data.reduced_hours == "Flexible hours") {
                tags.push(<li key="salary" className='salary-80'>100% salary</li>)
            } else if (data.reduced_hours == "Would consider") {
                tags.push(<li key="salary" className='salary-80'>80% salary</li>)
            }
        }



        // Location
        if (data.is_remote && data.location_city) {
            tags.push(<li key="remote-city">Hybird Remote, {data.location_city}</li>);
        } else if (data.is_remote && !data.location_continent && !data.location_country) {
            tags.push(<li key="100-remote" className='remote-100'>Remote, Worldwide</li>);
        } else if (data.is_remote && data.location_country) {
            tags.push(<li key="remote-country"><Link to={"/remote-jobs/"+Slugify(data.location_country)}>Remote, {data.location_country} only</Link></li>);
        } else if (data.is_remote && data.location_continent) {
            tags.push(<li key="remote-continent"><Link to={"/jobs/" + Slugify(data.location_continent)} onClick={() => document.body.scrollTop = 0} >Remote, {data.location_continent} only</Link></li>);
        } else if (data.location_city && data.location_country && !data.is_remote) {
            tags.push(<li key="onsite">Onsite - {data.location_city}, {data.location_country}</li>);
        }

        if (data.company && data.company.remote_relocation_budget) {
            tags.push(<li key="reloecation-budget" className='hide-mobile'>Offers relocation</li>);
        }


        /* Category
        if (data.role) {
            tags.push(<li key="role" className="hide-mobile">
                <Link to={"/jobs/" + Slugify(data.role)}><FontAwesomeIcon icon={faBriefcase} /> {data.role}</Link>

            </li>);
        }*/


        // Equity
        var equityHtml = "";
        if (data.equity) {
        //    equityHtml = <li key="equity">{data.equity}</li>;
        }

        return (

            <ul className="job-tags tags">
                {tags}
                {equityHtml}
            </ul>

        );
    }
}

export default JobTags;