import { getDefaultItemsState } from './item/reducer';
import {  getDefaultListsState } from './list/reducer';

export function getDefaultState(){
    return {
        item: getDefaultItemsState(),
        list: getDefaultListsState()
    }
}

export const apiURL = `http://localhost:3001/data/`

