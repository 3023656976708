import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import environmentImg from '../../assets/environment.png';
import { Link } from 'react-router-dom';

class ProsAndConsWorld extends React.Component {


    render() {


        return (

            <section className="row hero pros-and-cons">


                <div className='row pro-con-title pro-con-world'>
                    <h3 className=''>🌍 Pros and Cons for the World</h3>

                </div>
                <div className="row pro-con">
                    <div className="col-sm-2 pro-con-number ">
                        17.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Reduced Traffic Congestion</h4>
                        <p>A 4 day week means 20% less workplace traffic.</p>


                    </div>

                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        18.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Better for the Environment</h4>
                        <p className='margin-bottom'>Less travel + office energy = lower emissions</p>
                        <div className='padded-top margin-bottom'>
                            <a href="https://www.theguardian.com/environment/2021/may/27/four-day-working-week-would-slash-uk-carbon-footprint-report" rel="noopener noreferrer nofollow" target="_blank" className='margin-top-large'><LazyLoadImage
                                alt={"A 4 day work week can reduce carbon emissions by more than 20%"}
                                className='pro-con-img'
                                src={environmentImg} /></a>
                        </div>
                        <Link to="/pro/environment" className="btn cta btn-success btn-lg pro-con-more-info">More info...</Link>

                    </div>
                </div>

                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        19.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Reduced Healthcare Pressure</h4>
                        <p>A healthier society = lower burden to public services.</p>
                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        20.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>More Time to Volunteer</h4>
                        <p>A win for society.</p>
                    </div>
                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        21.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Supports Aging Population</h4>
                        <p>More time = better support for the elderly.</p>
                    </div>
                </div>



                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        22.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Increased Parental Involvement</h4>
                        <p>More time = more engaged parents.</p>
                    </div>
                </div>


                <div className='row pro-con'>
                    <div className="col-sm-2 pro-con-number ">
                        23.
                    </div>
                    <div className="col-sm-10 pro-con-text">
                        <h4>Boost to Local Economy</h4>
                        <p>More leisure time = more local spending.</p>
                    </div>
                </div>


            </section>

        );
    }
}

export default ProsAndConsWorld;