import React from 'react';

class CompanyTypicalDay extends React.Component {

    renderTime(hourNum) {
        // If the number isn't whole, format it as a string with 2 decimal places e.g. 6.3 -> 6:30
        if (hourNum % 1 !== 0) {
            var decimal = hourNum % 1;
            var minutes = decimal * 60;
            // Round the minutes
            minutes = Math.round(minutes);
            return Math.floor(hourNum) + ":" + minutes;
        }
        return hourNum;
    }

    render() {


        var { company: companyData } = this.props;


        var {             
            day_hours_acceptable_from,
            day_hours_acceptable_to,
            day_hours_typical_from,
            day_hours_typical_to,
            day_hours_core_from,
            day_hours_core_to } = companyData;

        if (!day_hours_typical_from) {
            return "";
        }




        return (
            <div className="company-side-box company-typical-day ">
                <h3>Our working Day</h3>
                <ul>
                    {day_hours_acceptable_from && day_hours_acceptable_to ?
                        <li className="range">
                            <div className="day-from">{this.renderTime(day_hours_acceptable_from)}am</div>
                            <div className="day-bar">Work between</div>
                            <div className="day-to">{this.renderTime(day_hours_acceptable_to - 12)}pm</div>
                        </li> : ""}

                    {day_hours_typical_from && day_hours_typical_to ?
                        <li className="typical">
                            <div className="day-from">{this.renderTime(day_hours_typical_from)}am</div>
                            <div className="day-bar">Typical hours</div>
                            <div className="day-to">{this.renderTime(day_hours_typical_to - 12)}pm</div>
                        </li> : ""}


                    {day_hours_core_from && day_hours_core_to ?
                        <li className="core">
                            <div className="day-from">{this.renderTime(day_hours_core_from)}pm</div>
                            <div className="day-bar">Core</div>
                            <div className="day-to">{this.renderTime(day_hours_core_to - 12) }pm</div>
                        </li> : ""}
                </ul>
            </div>

        )
    }
}

export default CompanyTypicalDay;