import React from 'react';

class FieldTextInput extends React.Component {

	render() {
		var { isNumber, isEmail, input, inputType} = this.props;


		var inpType = "text";
		if(isNumber || inputType == "number"){
			return <input type="number" {...input}  {...this.props} />
		} else if(isEmail || inputType == "email"){
			return <input type="email" {...input}  {...this.props} maxlength="254"/>
		}

		return (
			<>
				<input type={inpType} {...input}  {...this.props} />
			</>
		);
	}
}

export default FieldTextInput;
