import React from 'react';
import { Link } from 'react-router-dom';

class CountryHeader extends React.Component {

    render() {

        var { country, jobs, companies } = this.props;

        var { response: countryData } = country;




        return (
            <div className="row  country-header">


                <div className="col-sm-9 cols country-header-left cols-sm">

                    <h1 className="country-name">{countryData.name}</h1>

                    {countryData.description ? <h2 className="country-desc">{countryData.description}</h2> : ""}


                    <ul className='tags tags-lg'>
                        <li className='success'>{countryData.policy}</li>
                        <li>{countryData.continent}</li>
                    </ul>



                </div >

                <div className="col-sm-3 cols country-header-right cols-sm">

                    {companies.itemIds.length > 0 ?
                        <Link to={"/companies/"+countryData.slug} className="btn btn-success btn-huge cta">View {companies.itemIds.length} companies</Link> : ""}
                </div>


            </div>

        )
    }
}

export default CountryHeader;