import JobApplyButton from '../../job-page/JobApplyButton';
import React from 'react';

class JobTileApplyMiddle extends React.Component {

    render() {

        return (<JobApplyButton {...this.props} />);
    }

}

export default JobTileApplyMiddle;