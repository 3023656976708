import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import ProsAndConsCompany from '../../components/pros-and-cons-page/ProsAndConsCompany';
import ProsAndConsEmployee from '../../components/pros-and-cons-page/ProsAndConsEmployee';
import ProsAndConsWorld from '../../components/pros-and-cons-page/ProsAndConsWorld';
import FourDayWeekGraph from '../../assets/4-day-week-pilot-results.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import hireSvg from '../../assets/hire.svg';

class ProsAndCons extends React.Component {

	render() {


		var metaTitle = "Pros and Cons of the 4 Day Work Week";
		var metaDesc = "The pros-and-cons of a shorter week may surprise you. Here are 23 pros-and-cons of a 4 day week to the company, the employee and the world.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/pros-and-cons";

		let contactMsg = "/contact?message=" + encodeURIComponent("Hi there,\n\nMy company is interested in switching to a 4 day work week and I'd like to learn more / get advice...\n\n")

		return (
			<div className="main-container pros-and-cons-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />


				<div className="pros-and-cons row">




					<section className="row thin padded-bottom margin-bottom">
						<div className="hero center">
							<h1 className='hero-text huge'>{metaTitle} 👇</h1>
							<h3><em>Pssst...</em> 4 day work week = 32hrs with no drop in salary</h3>
						{/*<h5 className='hide-mobile'><em>Pssst...</em> 4 day work week = 32hrs with no drop in salary</h5>*/}
						</div>

					


					</section>


					<ProsAndConsCompany />

					<ProsAndConsEmployee />

					<ProsAndConsWorld />

					<section className="row hero pros-and-cons pros-and-cons-extra">


						<div className='row pro-con-title pro-con-extra'>
							<h3 className=''>Still not convinced?</h3>

						</div>

						<div className="row pro-con">
							<div className="row pro-con-text center">
								<h4>Here's what happened when <a href="https://autonomy.work/wp-content/uploads/2023/02/The-results-are-in-The-UKs-four-day-week-pilot.pdf" rel="noopener noreferrer nofollow" target="_blank" className='btn btn-link'>61 companies piloted</a> a 4 day week:</h4>



								<LazyLoadImage src={FourDayWeekGraph} alt="Hire faster" threshold={100} className="hero-img" alt="4 day week pilot results" />

							</div>

						</div>
					</section>



					<section className="row pros-and-cons pros-and-cons-cta">
						<h4>Help us spread the word...</h4>
						<div className='row pro-con-buttons'>

							<a href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//4dayweek.io/pros-and-cons" target="_blank" rel="noopener noreferrer nofollow" className="btn btn-linkedin btn-huge cta"><FontAwesomeIcon icon={faLinkedin} /> Share on LinkedIn</a>
							<a href="https://twitter.com/intent/tweet?url=25%20pros-and-cons%20of%20the%204%20day%20work%20week%20👇%20https://4dayweek.io/pros-and-cons&text=" target="_blank" rel="noopener noreferrer nofollow" className="btn btn-twitter btn-huge cta"><FontAwesomeIcon icon={faTwitter} /> Share on Twitter</a>
							<a href="https://www.facebook.com/sharer/sharer.php?u=https%3A//4dayweek.io/pros-and-cons" target="_blank" rel="noopener noreferrer nofollow" className="btn btn-facebook btn-huge cta"><FontAwesomeIcon icon={faTwitter} /> Share on Facebook</a>




						</div>

						{/*<h4>Is your company considering a 4 day week?</h4>
						<div className='row pro-con-buttons'>
							<Link to={contactMsg}  className="btn btn-contact btn-huge cta"><FontAwesomeIcon icon={faEnvelope} /> Get in touch</Link>
						</div>*/}



					</section>




				</div>





			</div >
		);
	}
}

export default ProsAndCons;