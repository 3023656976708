import React from 'react';
import LabelField from '../fields/LabelField';
import * as FormatTools from '../../utils/format';

class ApplicationFormQualifiers extends React.Component {
    render() {
        const { job } = this.props;
        if (!job || !job.application_template) return null;

        const template = job.application_template;
        if (!template.boolean_qualifiers && !template.select_qualifiers && !template.num_qualifiers) {
            return null;
        }

        // Boolean qualifiers
        const booleanQualifiers = template.boolean_qualifiers
            ? template.boolean_qualifiers.map((d) => {
                const slug = FormatTools.Slugify(d.label);
                const savedValue = localStorage.getItem("field_" + slug) || "";
                return (
                    <LabelField
                        key={d.id}
                        inputType="radio"
                        id={d.id}
                        label={d.label}
                        name={d.id}
                        isRequired={true}
                        defaultValue={savedValue}
                        options={[
                            { value: "Yes", label: "Yes" },
                            { value: "No", label: "No" },
                        ]}
                        inlineCol={true}
                        singleCol={true}
                    />
                );
            })
            : null;

        // Number qualifiers
        const numQualifiers = template.num_qualifiers
            ? template.num_qualifiers.map((d) => {
                const slug = FormatTools.Slugify(d.label);
                const savedValue = localStorage.getItem("field_" + slug) || "";
                return (
                    <LabelField
                        key={d.id}
                        inputType="number"
                        id={d.id}
                        label={d.label}
                        name={d.id}
                        placeholder={d.placeholder}
                        defaultValue={savedValue}
                        isRequired={true}
                        inlineCol={true}
                        singleCol={true}
                    />
                );
            })
            : null;

        // Select qualifiers
        const selectQualifiers = template.select_qualifiers
            ? template.select_qualifiers.map((d) => {
                const slug = FormatTools.Slugify(d.label);
                const savedValue = localStorage.getItem("field_" + slug) || "";
                return (
                    <LabelField
                        key={d.id}
                        inputType="select"
                        id={d.id}
                        label={d.label}
                        name={d.id}
                        defaultValue={savedValue}
                        isRequired={true}
                        options={d.options}
                        inlineCol={true}
                        singleCol={true}
                    />
                );
            })
            : null;

        return (
            <div className="row margin-top">
                {booleanQualifiers}
                {numQualifiers}
                {selectQualifiers}
            </div>
        );
    }
}

export default ApplicationFormQualifiers;
