import timeago from 'epoch-timeago';
import React from 'react';

class JobTilePosted extends React.Component {

    shouldComponentUpdate(){
        return false;
    }


    render() {
        var { data } = this.props;
        var posted_time = timeago(data.posted * 1000);

        if (data.featured) {
            switch (data.featured) {
                case "gold":
                    posted_time = "3 days ago"
                    break;
                case "silver":
                    posted_time = "1 week ago"
                    break
                case "bronze":
                    posted_time = "2 weeks ago"
                    break
                default:
                // code block
            }
            posted_time = "New";
        }

        return (

            <p className="job-tile-time"><span className="hide-mobile"></span>{posted_time}</p>

        );
    }
}

export default JobTilePosted;