
import React from 'react';
import loading from '../../assets/loading.gif';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const Loader = ({ text, size, className }) => {
	var cl = 'loader' + (text ? ' text' : '');
	cl += ' '+className
  return (
		<div className={cl + " " + size}>
			{size !== "small" ? <LazyLoadImage src={loading} alt="Loading..." /> : ""}
			{text ? (
				<div className='loader-msg'>
					{text}
				</div>
			) : ''}
		</div>
  );
};

//Loader.propTypes = { text: PropTypes.string, size: PropTypes.string };
Loader.defaultProps = { text: '', size: ''};

export default Loader;
