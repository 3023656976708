import React from 'react';
import { Link } from 'react-router-dom';

class JobTileTitle extends React.Component {

    shouldComponentUpdate() {
        return true;
    }


    render() {
        var { data } = this.props;

        var link = "";

        if (data.url) {
            link = <Link className="btn btn-link dark btn-lg" to={data.url} onClick={(e) => e.stopPropagation()}>{data.title}</Link>;
        } else {
            link = <Link className="btn btn-link dark btn-lg" to={"#"}>{data.title}</Link>;
        }



        return (
            <div className="row job-tile-title">
                <h3>
                    {link}
                </h3>
            </div>
        );


    }
}

export default JobTileTitle;