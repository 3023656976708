import timeago from 'epoch-timeago';
import React from 'react';

class JobPosted extends React.Component {

    shouldComponentUpdate(){
        return false;
    }


    render() {
        var { posted, featured } = this.props;
        var posted_time = timeago(posted * 1000);

        if (featured) {
            switch (featured) {
                case "gold":
                    posted_time = "3 days ago"
                    break;
                case "silver":
                    posted_time = "1 week ago"
                    break
                case "bronze":
                    posted_time = "2 weeks ago"
                    break
                default:
                // code block
            }
        }

        return (

            <p className="job-posted">Posted {posted_time}</p>

        );
    }
}

export default JobPosted;