import React from 'react';
import { Link } from 'react-router-dom';
import { CATEGORIES_GOOD_LOOKUP, REDUCED_HOURS_LOOKUP_ALT } from '../../utils/filters';
import { withRouter } from 'react-router-dom';

import { IsLoggedIn } from '../../utils/user';


class MenuDesktop extends React.Component {
    constructor() {
        super();
        this.menuWrapperRef = React.createRef();
        this.state = {
            expanded: {}
        };

    }

    onToggle(key) {
        var { expanded } = this.state;
        expanded[key] = !expanded[key];
        this.setState({ expanded });
    }

    onToggleSet(key, value) {
        var { expanded } = this.state;
        expanded[key] = value;
        this.setState({ expanded });
    }

    goToTop() {
        document.body.scrollTop = 0
    }

    renderDropdownMenu = (items, includeSeperator = false) => (
        <ul className="dropdown-menu">
            {items.map((item, index) => {
                // Handle header items
                if (item.isHeader) {
                    return (
                        <li 
                            key={item.title} 
                            className={`${item.className || ''} ${item.separator ? 'separator' : ''}`}
                        >
                            {item.title}
                        </li>
                    );
                }
                
                // Handle regular menu items
                return (
                    <li 
                        key={item.slug || item.title} 
                        className={item.separator || (includeSeperator && index === items.length - 2) ? 'separator' : item.className || ''}
                    >
                        {item.externalLink ? (
                            <a href={item.url} target='_blank' rel="noopener noreferrer nofollow">{item.title}</a>
                        ) : (
                            <Link 
                                to={item.url} 
                                onClick={item.onClick || this.goToTop} 
                                onBlur={item.onBlur} 
                                className={item.className || ''}
                            >
                                {item.title || item.text}
                            </Link>
                        )}
                    </li>
                );
            })}
        </ul>
    )

    render() {
        var path = this.props.location.pathname.trim()
        var spl = path.split("/");
        var page = spl[spl.length - 1];

        var { expanded } = this.state;
        let { user } = this.props;

        // Build jobs dropdown items
        const jobsDropdownItems = [
            ...Object.keys(CATEGORIES_GOOD_LOOKUP).map(slug => ({
                slug,
                text: CATEGORIES_GOOD_LOOKUP[slug],
                url: `/jobs/${slug}`,
                onClick: this.goToTop
            })),
            { 
                title: "4 Day Work Week", 
                url: "/jobs/4-day-week", 
                onClick: this.goToTop, 
                onBlur: () => this.setState({ jobsCollapsed: true }),
                separator: true
            },
            { 
                title: "Part time", 
                url: "/jobs/part-time", 
                onClick: this.goToTop, 
                onBlur: () => this.setState({ jobsCollapsed: true }) 
            }
        ];

        // Build companies dropdown items
        const companiesDropdownItems = [
            ...Object.keys(REDUCED_HOURS_LOOKUP_ALT).map(slug => ({
                slug,
                text: REDUCED_HOURS_LOOKUP_ALT[slug],
                url: `/companies/${slug}`,
                onClick: this.goToTop
            })),
            { 
                title: "All companies", 
                url: "/companies", 
                onClick: this.goToTop, 
                onBlur: () => this.setState({ jobsCollapsed: true }),
                separator: true
            }
        ];
        
        // Tools dropdown items
        const toolsDropdownItems = [
            { title: "Auto-apply", url: "/auto-apply" },
            { 
                title: "AI Resume Builder", 
                url: "https://aiapply.co/resume-builder?via=philip", 
                externalLink: true 
            },
            { 
                title: "AI Cover Letter Builder", 
                url: "https://4dayweek.jobcopilot.com/cover-letter-builder", 
                externalLink: true 
            },
            { 
                title: "AI Resume Reviewer", 
                url: "https://4dayweekresumes.com/", 
                externalLink: true 
            }
        ];
        
        // Guides dropdown items
        const guidesDropdownItems = [
            { title: "Advice", url: "/advice" },
            { title: "Case Studies", url: "/case-studies" },
            { title: "Countries", url: "/countries" },
            { title: "Working Schedules", url: "/schedules" },
            { 
                title: "Pros and Cons", 
                url: "/pros-and-cons", 
                className: "hide-desktop" 
            },
            { 
                title: "Submit a company", 
                url: "/add-company", 
                separator: true 
            }
        ];
        
        // Main menu items
        const mainMenuItems = [
            { 
                title: "Jobs", 
                url: "/jobs", 
                dropdown: this.renderDropdownMenu(jobsDropdownItems) 
            },
            { 
                title: "Companies", 
                url: "/companies", 
                dropdown: this.renderDropdownMenu(companiesDropdownItems) 
            },
            { 
                title: "Pros and Cons", 
                url: "/pros-and-cons", 
                className: "hide-tablet" 
            },
            {
                title: "Tools",
                url: "#",
                dropdown: this.renderDropdownMenu(toolsDropdownItems)
            },
            {
                title: "Guides",
                url: "#",
                dropdown: this.renderDropdownMenu(guidesDropdownItems)
            }
        ];

        // Logged in or not
        let rightMenu = "";

        if (IsLoggedIn(user)) {
            // Logged in 

            let userData = user && user.requested && !user.loading && user.response && !user.response.error ? user.response : null;


            let userDropdownItems = [{ title: "Edit profile", url: "/profile/edit", className: "btn btn-success btn-lg" }]


            if (userData && userData.company_id) {
                userDropdownItems.push({ title: "Edit company profile", url: "/dashboard/company", className: "btn btn-primary btn-lg", separator: true });
                userDropdownItems.push({ title: "View company score", url: "/dashboard/company/widget", className: "btn btn-primary btn-lg" });
            }


            userDropdownItems.push({ title: "Post a job", url: "/post-a-job", className: "btn btn-primary btn-lg" })

            if (userData.role == "company") {
                userDropdownItems.push({ title: "My jobs", url: "/dashboard/jobs", className: "btn btn-primary btn-lg" });
            }

            userDropdownItems.push({ title: "Logout", url: "/logout", className: "btn btn-ghost btn-lg", separator: true })


            rightMenu = <li className={"user-menu  " + (expanded["user"] ? "open" : "")} onMouseOver={() => this.onToggleSet("user", true)} onMouseOut={() => this.onToggleSet("user", false)} onFocus={() => this.onToggleSet("user", true)} >
                <Link to={"/profile/edit"} className="btn btn-ghost btn-lg" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">My Account</Link>

                <ul className="dropdown-menu" >
                    {userDropdownItems.map((item) => {
                        return <li key={item.title} className={item.separator ? 'separator' : ""}><Link to={item.url} className={"" + (page == item.url ? ' active' : "")}>{item.title}</Link></li>
                    })}

                </ul></li>;
        } else if (!user.loading) {
            // Logged out
            rightMenu = <>
                <li className='first-right-menu'><Link to="/post-a-job" className={"btn btn-success btn-lg cta " + (page == "post-a-job" ? ' active' : "")}>Post a job</Link></li>
                {/*   <li className='logged-out'><Link to="/add-company" className={"btn btn-light btn-lg " + (page == "add-company" ? ' active' : "")}>Add a company</Link></li> */}
                <li><Link to="/login" className={"btn btn-light btn-lg cta " + (page == "login" ? ' active' : "")}>Login</Link></li>
                <li><Link to="/sign-up" className={"btn btn-ghost btn-lg cta " + (page == "login" ? ' active' : "")}>Sign up</Link></li>
            </>

        } else {
            // Loading
            rightMenu = <li className='user-loading prevent-jumping'>Loading...</li>
        }

        return (
            <ul className={"nav navbar-nav desktop-menu "} style={{ maxHeight: 52 }}>
                {mainMenuItems.map((item) => {
                    let pPage = page.replace(/^\/+|\/+$/g, '');
                    let iUrl = item.url.replace(/^\/+|\/+$/g, '');
                    let extraClass = "";
                    if (pPage == iUrl) {
                        extraClass = " active"
                    }
                    if (item.dropdown) {
                        return <li key={item.title} className={"more-menu " + (expanded[item.title] ? "open" : "")} onMouseOver={() => this.onToggleSet(item.title, true)} onMouseOut={() => this.onToggleSet(item.title, false)} onFocus={() => this.onToggleSet(item.title, true)} >
                            <Link to={item.url} className={"btn btn-ghost btn-lg " + extraClass} data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">{item.title}</Link>
                            {item.dropdown}
                        </li>
                    }
                    return <li key={item.title} className={" " + (item.className ? item.className : "")}><Link to={item.url} className={"btn btn-ghost btn-lg " + extraClass}>{item.title}</Link>{item.dropdown}</li>
                })}

                {rightMenu}
            </ul>
        );
    }
}

export default withRouter(MenuDesktop);




