
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import socialLogo from '../../assets/4dw-social-logo.png';
import Loader from '../misc/Loader';
import SubscriberInlineWrapper from '../subscriber-form/SubscriberInlineWrapper';
import ArticleRelatedTile from './ArticleRelatedTile';


class ArticleSideBar extends React.Component {


    render() {

        var { related, advert } = this.props;


        

        // Related articles
        var rel = "";

        if (related && related.loading) {
            rel = <Loader />
        } else if (related && related.init && related.items && related.itemIds.length > 0) {

            rel = related && related.init && !related.loading && related.items ? related.itemIds.map((d) =>
                <li className='article-tile'>
                    <ArticleRelatedTile data={related.items[d]} />
                </li>) : "";


            // Add "see more" button
            rel.push(<li className='article-tile'>
                <Link to={"/"+this.props.topic} className="btn btn-lg btn-default margin-top">
                    See all articles...
                </Link>
            </li>);

            rel = <div className="article-related-articles ">

                <h4>Related articles</h4>
                <ul className='related-articles'>{rel}</ul>
            </div>
        }

        // ADvert
        var ad = "";
        if (advert && advert.loading) {
            ad = <Loader />
        } else if(!advert || !advert.response || !advert.requested){
            ad = "";
        } else if (advert && !advert.loading && advert.requested) {

            ad =
                <a href={advert.response.url} target="_blank" className='article-sponsored-by' rel="noopener sponsored noreferrer nofollow" onClick={(e) => this.props.onClickAdvert(e)} >

                    <h4>Sponsored by {advert.response.company_name}</h4>
                    <div className='tile-text'>

                        <h3>{advert.response.title}   <LazyLoadImage
                            height={40}
                            alt={advert.response.title}
                            src={advert.response.image_url}
                        /></h3>
                        <p>{advert.response.description}</p>
                        <button className='btn btn-success btn-md'>{advert.response.cta}</button>
                    </div>

                </a>


        }

        return (
            <div className="article-side-bar">

                <div className="article-about">
                    <Link to="/" className='article-about-top'>
                        <LazyLoadImage
                            alt={"4 day week logo"}
                            src={socialLogo}
                            height={60}
                            width={60}
                            className="about-logo"
                        />
                        <h3>4dayweek.io</h3>
                        <p>Remote Jobs with a 4 day work week</p>

                    </Link>

                    {/*}  <Link to="/jobs" className="btn btn-lg btn-default margin-top">
                        View 4 day week jobs
        </Link>*/}


                </div>

                <SubscriberInlineWrapper className='subscriber-green hide-mobile hide-tablet' />

                {rel}


            </div>
        )
    }
}

export default ArticleSideBar;