import React from 'react';
import MarkdownRenderer  from '../misc/MarkdownRenderer';


class CompanyLocation extends React.Component {

    render() {


        var { company: companyData } = this.props;
        var { employee_locations, remote_level, country, employees, remote_team_description } = companyData;

        // Type
        var title = ""
        var desc = "";
        if (!remote_level) {
            return "";
        }

        if (employees) {
            desc = " and are a team of " + employees;
        }


        if (country) {
            desc += " and mostly based in " + country;
        }

        if(desc){
            desc += ". ";
        }



        if (remote_level == "100% remote") {
            title = "We work 100% remotely";
            desc = "We work fully remotely "+ desc
        } else if (remote_level == "Offers remote") {
            title = "We work hybrid remote";
            desc = "We offer remote working (but require some time in the office) "+ desc
        } else{
            desc = "We offer remote working (with some time in the office) "+ desc
            title = "We offer Hybrid Working"
        }


        title = "Remote Working Policy"; // seo




        // Locations
        var locations = "";

        if (employee_locations && employee_locations.length > 0) {
            desc += " Our team works from:";
            locations = <ul>{employee_locations.map((d) =>
                <li>
                    <div className="remote-bar" style={{ width: ((d.num / employee_locations[0].num) * 100) +"%" }}></div>
                    <div className="remote-title">{d.title}</div>
                    <div className="remote-number">{d.num}</div>
                </li>
            )}</ul>;
        }




        if(remote_team_description){
            desc =   <MarkdownRenderer keepInternal={true}>{remote_team_description}</MarkdownRenderer>
        }

     

        return (
            <div className="company-side-box company-remote ">
                <h3>{title}</h3>
                <p>{desc}</p>

                {locations}
            </div>

        )
    }
}


export default CompanyLocation;