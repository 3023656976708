import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as itemActions from '../../store/item/actions';
import { IsLoggedIn } from '../../utils/user';
import Loader from '../misc/Loader';
import EditCompanyFormFields from './EditCompanyFormFields';


class EditCompanyForm extends React.Component {

    initValues(){
        let { user, data } = this.props;

        if (user.requested && !user.loading) {
            console.log("dataaa", data, user)
            if (!IsLoggedIn(user)) {
                // Not logged in
                this.props.history.push("/login?unauthorized=true");
                return;
            } else if (!user.response.company_id || user.response.company_id == 0) {
                // Not their company
                this.props.history.push("/error");
                return
            } else if (!data || (!data.requested && !data.loading)) {
                this.props.getCompany({ "id": user.response.company_id });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.initValues();
    }

    componentDidMount() {
        this.initValues();
    }



    onSubmit(values) {
        console.log("Submitting");
        console.log(values);


        this.props.setCompany(values);
        this.props.history.push("/dashboard/company/widget?updated=true");
    }



    render() {
        let { data } = this.props;

        if (!data || !data.requested || data.loading) {
            return <Loader />
        }

        let content = "";

        content = <Form
            onSubmit={(values) => this.onSubmit(values)}
            mutators={{
                ...arrayMutators,
                changeCompanyLogo: (args, state, utils) => {
                    utils.changeValue(state, 'company_logo_upload', () => args.length > 0 ? args[0] : "");
                },

            }}
            initialValues={data.response}
            render={({
                handleSubmit,
                submitting,
                submitted,
                errors,
                pristine,
                values,
                form,
                form: {
                    mutators: { push, pop },
                },
            }) => (
                <form onSubmit={handleSubmit} className="box-theme" data-sparkloop-upscribe='false'>
                    <EditCompanyFormFields values={values} form={form} data={data} />

                    <div className="row padded-top edit-company-submit">
                        <button
                            type="submit"
                            disabled={submitting || Object.keys(errors).length > 0}
                            className="btn btn-huge btn-success cta"
                        >
                            Save
                        </button>
                    </div>

                    <pre>{JSON.stringify(values, 0, 2)}</pre>
                </form>
            )}
        />



        return (
            <div className="company-edit-form">
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.item.dashboard_company,
        user: state.item.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    setCompany: (data) => {
        dispatch(itemActions.setItem("dashboard", data, "set_company"));
    },
    getCompany: (data) => {
        dispatch(itemActions.getItem("dashboard", data, "dashboard_company", "get_company"));
    },

});

EditCompanyForm = connect(mapStateToProps, mapDispatchToProps)(EditCompanyForm);

export default withRouter(EditCompanyForm);
