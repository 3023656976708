
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import { GetNextAdvertId } from '../../utils/user';
import { IsLoggedIn } from '../../utils/user';
import Loader from '../misc/Loader';
import posthog from 'posthog-js';
import * as Fathom from 'fathom-client';

class CompanyAdvertTile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hovering: false,
            viewPosition: 0,
            
        };
    }
    componentDidMount() {
        this.setState({ viewPosition: GetNextAdvertId(this.props.adverts.itemIds.length) });
    }


    handleApply(e) {
        e.stopPropagation();

        let { adverts, user } = this.props;
        let {viewPosition} = this.state;
        let { itemIds, items } = adverts;
        let advert = items[itemIds[viewPosition]];

        let clickData = { 'page': this.props.page, 'action': 'click_advert', 'item_id': advert.id_str};

        if (IsLoggedIn(user)) {
            clickData['user_id'] = user.response.id_str;
        }

        this.props.newClick(clickData);
		Fathom.trackEvent('Advert');
		posthog.capture('Advert');


    }

    render() {
        let { adverts, pos } = this.props;
        let { init, items, itemIds, loading } = adverts;

        let { hovering } = this.state;

        if (loading || !init) {
            return <Loader />
        } else if (itemIds.length == 0) {
            return "";
        }
        var data = items[itemIds[pos]];

        if(!data){
            return "";
        }

        let htmlTags = [];

        if (data && data.tags && data.tags.length > 0) {
            htmlTags = data.tags.map((tag) => {
                return <li className='success'>{tag.label}</li>
            });
        }


        return (
            <>
                <a className={"notcompanytavert-tile-wrapper "} key={"advert-" + data.id_str} href={data.url} onClick={(e) => this.handleApply(e)} rel="noopener sponsored noreferrer nofollow" target="_blank"

                    onMouseEnter={() => this.setState({ hovering: true })}
                    onMouseLeave={() => this.setState({ hovering: false })}
                    onFocus={() => this.setState({ hovering: true })}>


                    <div className="inner-triangle"></div>

                    <a href={data.url} onClick={(e) => this.handleApply(e)} target="_blank" rel="noopener sponsored noreferrer nofollow nottavert-logo">
                        <LazyLoadImage
                            //  style={{ backgroundImage: `url(${data.company.logo_url})` }} 
                            className="nottavert-tile-image"
                            alt={`${data.title} logo`}
                            threshold={100}
                            height={50}
                            width={50}
                            src={data.image_url} // use normal <img> attributes as props
                        />
                    </a>



                    <div className="row nottavert-tile">
                        <div className='row'>
                            <div className="nottavert-tile-left cols cols-sm col-sm-9">
                                <div className="row nottavert-tile-top">

                                    <h5><a href={data.url} onClick={(e) => this.handleApply(e)} target="_blank" rel="noopener sponsored noreferrer nofollow" className="btn btn-link dark btn-md">{data.company_name}</a>
                                    </h5>

                                    <div className="nottavert-tile-title  row">{data.title}</div>


                                </div>

                            </div>
                            <div className='nottavert-tile-right cols cols-sm col-sm-3'>



                                {hovering ?
                                    <a target="_blank" rel="noopener noreferrer sponsored nofollow" className={"btn nottavert-tile-cta btn-success " + " btn-md "} onClick={(e) => this.handleApply(e)} href={data.url}>{data.cta}</a> : <span className='nottavert-tile-time'>Promoted</span>}



                            </div>

                        </div>


                        <div className="nottavert-tile-description row">{data.description}</div>
                        <ul className="nottavert-tile-tags">
                            {htmlTags}
                        </ul>
                        <div className="nottavert-tile-promoted hide-desktop hide-tablet">
                            Promoted
                        </div>

                        <div className="nottavert-tile-cta hide-desktop">
                        <a target="_blank" rel="noopener noreferrer sponsored nofollow" className={"btn nottavert-tile-cta btn-success " + " btn-md "} onClick={(e) => this.handleApply(e)} href={data.url}>{data.cta}</a>
                        </div>

                    </div>

                </a>

            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        adverts: state.list.adverts_list_home,
        user: state.item.user,
    };
};


const mapDispatchToProps = (dispatch, ownProps) => ({
    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },



});

CompanyAdvertTile = connect(mapStateToProps, mapDispatchToProps)(CompanyAdvertTile);

export default CompanyAdvertTile;