import { GoogleLogin } from '@react-oauth/google';
import * as Fathom from 'fathom-client';
import arrayMutators from 'final-form-arrays';
import posthog from 'posthog-js';
import React from 'react';
import { Form } from 'react-final-form';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import handDrawnArrow from '../../assets/hand-drawn-arrow.png';
import AuthCheckInbox from '../../components/auth/AuthCheckInbox';
import AuthSuccess from '../../components/auth/AuthSuccess';
import Loader from '../../components/misc/Loader';
import { GoogleClientId } from '../../config';
import * as itemActions from '../../store/item/actions';
import { AddRef, CleanValues } from '../../utils/form';
import LabelField from '../fields/LabelField';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { OnChange } from 'react-final-form-listeners';

class SubscriberForm extends React.Component {

    constructor(props) {
        super(props);


        this.submitButtonRef = React.createRef();

        // Random 10 char id
        let formId = Math.random().toString(36).substring(2, 15)
            + Math.random().toString(36).substring(2, 15);

        this.state = {
            isSubmitted: false,
            // Treating this as our Sparkloop modal open state
            isSparkloopModalOpen: false,
            isResumeCollapsed: true,
            formId: formId,
            resumeUpload: null,
            isGoogleLogin: false,

            // Global states
            isSubscriptionFormActive: false,
            hasSubscribed: false,
        }
    }

    // Called after successfully fetching Google user
    handleGoogleLoginSuccess = async (googleValues, form) => {
        // 1) Programmatically set the email in your react-final-form
        form.change('email', googleValues.email);
        form.change('full_name', googleValues.full_name);
        form.change('google_id', googleValues.google_id);

        this.setState({ isGoogleLogin: true });
        // 2) Imitate the user clicking the submit button
        if (this.submitButtonRef.current) {
            this.submitButtonRef.current.click();
        }

    }

    // Submit handler
    onSubmit = (v) => {
        console.log("Submitting...", v);
        let { formId, isGoogleLogin } = this.state;

        let values = Object.assign({}, v);
        values = CleanValues(values, formId);
        values = AddRef(values);

        // If we're using Google login, set the auth type to google
        if (isGoogleLogin) {
            values.auth_type = "google";
        } else {
            values.auth_type = "email";
        }

        // Kick off authentication or submission
        this.props.authBegin(values);

        // If we want to show the Sparkloop modal, handle that here
        let newState = {
            isSubmitted: true,
            email: v.email,
            isSparkloopModalOpen: true,
            isSubscriptionFormActive: true
        };

        // If user wants a resume review and provided a file
        if (values && values.get_resume_review && values.resume_upload && values.resume_upload.length) {
            newState.resumeUpload = v.resume_upload;
        }

        this.setState(newState);

        // Store in localStorage if you need cross-page or repeated-check reference
        localStorage.setItem('has_subscribed', true);
        localStorage.setItem('is_subscription_form_active', true);

        // Logging
        Fathom.trackEvent('Subscribe');
        posthog.capture('Subscribe');

        if (this.props.onSubmit) {
            this.props.onSubmit();
        }

    }

    // Mark form as "active" for UI/analytics
    onFocus() {
        this.setState({ isSubscriptionFormActive: true });
        localStorage.setItem('is_subscription_form_active', true);
    }

    onBlur() {
        this.setState({ isSubscriptionFormActive: false });
        localStorage.setItem('is_subscription_form_active', false);
    }

    // Close Sparkloop modal
    componentDidMount() {
        document.addEventListener('click', this.handleModalClick);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleModalClick);
    }

    // For example, if the user clicks a "skip" link or close button in Sparkloop’s modal
    handleModalClick = (event) => {
        if (event.target.classList.contains('sl-skip-link')
            || event.target.classList.contains('sl-button')) {
            this.setState({
                isSparkloopModalOpen: false,
                isSubscriptionFormActive: false
            });
            localStorage.setItem('is_sparkloop_modal_open', false);
            localStorage.setItem('is_subscription_form_active', false);
        }
    }

    // Watch for changes in auth or critique states (resume uploading, etc.)
    componentDidUpdate(prevProps, prevState) {
        let { resumeUpload: newResumeUpload } = this.state;
        let { auth: newAuth, critique: newCritique } = this.props;
        let { auth: oldAuth, critique: oldCritique } = prevProps;

        // If we finished auth and a resume was provided, start the critique
        if (oldAuth.loading && !newAuth.loading && newResumeUpload
            && newAuth.response && newAuth.response.data) {
            this.props.newCritique({
                email: newAuth.response.data.email,
                resume_upload: newResumeUpload,
            });
        }
        // Once critique is done, store the critique id
        else if (oldCritique.loading && !newCritique.loading
            && newCritique.response && newCritique.response.data
            && !localStorage.getItem("critique_id")) {
            localStorage.setItem("critique_id", newCritique.response.data.data.critiqueId);
        }

        // If we finished auth and the auth was successful and was Google login, get the user
        if (oldAuth.loading && !newAuth.loading
            && newAuth.response && newAuth.response.data && !newAuth.response.data.error
            && this.state.isGoogleLogin) {
            console.log("Finished auth and was Google login, getting user...");
            this.props.getUser();
        }
    }

    render() {
        var { companyName, autoFocus, cta, description, h1, h2 } = this.props;
        let { isSubmitted, formId, isResumeCollapsed, email, isSparkloopModalOpen, isGoogleLogin } = this.state;

        // If we already submitted and the Sparkloop modal is closed, 
        // show some confirmation UI
        if (isSubmitted && !isSparkloopModalOpen) {
            if (isGoogleLogin) {
                return <AuthSuccess />;
            } else {
                return <AuthCheckInbox email={email} />;
            }
        }
        // If isSubmitted but modal is still open, show loading or partial screen
        else if (isSubmitted) {
            return (
                <div className='subscriber-form-loading'>
                    Loading... <Loader />
                </div>
            );
        }

        // CTA button text
        var ctaBtn = cta ? cta : "Sign up for free";

        // Description line
        var desc = "Get weekly alerts for 4 day week jobs. That's 32hrs @ 100% pay 🧘‍♂️";
        if (description) {
            desc = description;
        } else if (companyName) {
            desc = "Get weekly alerts for jobs at " + companyName
                + " & other 4 day week (32hr) companies.";
        }

        let socials = ["temp2", "d_studzinski", "temp1", "rrmdp"];

        return (
            <>
                {h1 ? <h1 className="subscriber-h1">{h1}</h1> : ""}
                {h2 ? <h2 className="subscriber-h2">{h2}</h2> : ""}
                {!h1 && !h2 ? (
                    <h2 className="subscriber-h2">
                        Would you like a 4 day work week?
                    </h2>
                ) : ""}

                <Form
                    onSubmit={this.onSubmit}
                    mutators={{
                        ...arrayMutators,
                        changeResume: (args, state, utils) => {
                            utils.changeValue(
                                state,
                                'resume_upload',
                                () => args.length > 0 ? args[0] : []
                            );
                        },
                    }}
                    render={({ handleSubmit, values, form }) => (
                        <form
                            onSubmit={handleSubmit}
                            className={"subscriber-form "}
                        >
                            <div className='row'>
                                <div className="subscriber-form-desc">
                                    {desc}
                                </div>
                            </div>

                            <div className="row relative subscriber-email">
                                <LabelField
                                    inputType="email"
                                    id={"email"}
                                    singleCol={true}
                                    isRequired={true}
                                    hideErrors={true}
                                    isEmail={true}
                                    autoFocus={autoFocus}
                                    className="lg"
                                    placeholder={"Enter your email..."}
                                    onFocus={() => this.onFocus()}
                                    onBlur={() => this.onBlur()}
                                />

                                <input type="hidden" name="google_id" />
                                <input type="hidden" name="full_name" />
                            </div>

                            <div
                                className={
                                    'subscriber-resume row '
                                    + (isResumeCollapsed ? "resume-collapsed" : "")
                                }
                            >
                                <LabelField
                                    inputType="checkbox"
                                    type="checkbox"
                                    className=" checkbox-left"
                                    id={"get_resume_review_" + formId}
                                    singleCol={true}
                                    label="Get a free resume review"
                                    onFocus={() => this.onFocus()}
                                    onBlur={() => this.onBlur()}
                                />
                                {values["get_resume_review_" + formId] ? (
                                    <LabelField
                                        inputType="file"
                                        multiple={false}
                                        id={"resume_upload_" + formId}
                                        isRequired={false}
                                        singleCol={true}
                                        onChange={form.mutators.changeResume}
                                        accepted={[
                                            'application/pdf'
                                            // 'application/msword',
                                            // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                            // 'application/rtf',
                                            // 'application/vnd.oasis.opendocument.text'
                                        ]}
                                        helper="Upload your resume (PDF)"
                                        icon={faFileUpload}
                                    />
                                ) : ""}

                                <OnChange name={"get_resume_review_" + formId}>
                                    {(value, previous) => {
                                        this.setState({
                                            isResumeCollapsed: !value
                                        });
                                    }}
                                </OnChange>
                            </div>
                            <div className='row subscriber-cta'>
                                <button

                                    ref={this.submitButtonRef}
                                    type="submit"
                                    className="btn btn-success cta btn-huge mobile-cta"
                                >
                                    {ctaBtn}
                                </button>
                            </div>

                            {/*}

                            <div className="row">
                                <p className='text-center'>Or</p>

                                <GoogleLogin
                                    clientId={GoogleClientId}
                                    useOneTap={false}
                                    text='signup_with'
                                    size='large'
                                    onSuccess={(credentialResponse) => {
                                        const { credential } = credentialResponse;
                                        // ...call your /data/auth/google backend, then:
                                        fetch('/data/auth/google', {
                                            method: 'POST',
                                            headers: { 'Content-Type': 'application/json' },
                                            body: JSON.stringify({ token: credential }),
                                        })
                                            .then((res) => res.json())
                                            .then(async (data) => {
                                                if (data.error) {
                                                    console.error(data.error);
                                                } else {
                                                    // Once you have the user email from Google...
                                                    this.handleGoogleLoginSuccess(data.data, form);

                                                }
                                            })
                                            .catch((err) => console.error(err));
                                    }}
                                    onFailure={() => {
                                        console.log("Google login failure");
                                    }}
                                />
                            </div>
                            */}




                            <div className='row subscriber-social-proof'>
                                <div className='subscriber-social-proof-images'>
                                    {socials.map((social) => {
                                        return (
                                            <img
                                                src={"/assets/social-images/" + social + ".jpg"}
                                                className='subscriber-social-proof-img'
                                                key={"img-" + social}
                                                alt={social}
                                                loading="lazy"
                                            />
                                        );
                                    })}
                                </div>
                                <div className='subscriber-social-proof-text'>
                                    Join 400k job seekers getting weekly alerts...
                                </div>
                                <LazyLoadImage
                                    src={handDrawnArrow}
                                    className='subscriber-social-proof-arrow'
                                    alt="hand drawn arrow"
                                />
                            </div>
                        </form>
                    )}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.item.auth,
        critique: state.item.critique,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    authBegin: (data) => {
        dispatch(
            itemActions.newItem("auth", data, "auth", "begin")
        );
    },
    newCritique: (data) => {
        dispatch(
            itemActions.newItem("critiques", data, "critique")
        );
    },
    newSubscriber: (data) => {
        dispatch(
            itemActions.newItem("subscriber", data)
        );
    },
    getUser: () => {
        return dispatch(itemActions.getItem("users", {}, "user"));
    },
});

SubscriberForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriberForm);

export default withRouter(SubscriberForm);
