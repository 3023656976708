import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

class JobTileListingTitle extends React.Component {
    shouldComponentUpdate() {
        return false;
    }


    render() {

        // Job listing
        var { title, company, url, posted, reduced_hours, is_expired } = this.props;

        var cur_employees = "";

        if (company.employees) {

            if (company.employees > 1000) {
                cur_employees = "1,000+";
            } else if (company.employees > 500) {
                cur_employees = "500-1,000";
            } else if (company.employees > 500) {
                cur_employees = "100-500";

            } else if (company.employees > 100) {
                cur_employees = "100-500";

            } else if (company.employees > 50) {
                cur_employees = "50-100";

            } else if (company.employees >= 10) {
                cur_employees = "10-50";

            } else if (company.employees >= 7) {
                cur_employees = "~10";
            } else {
                cur_employees = "";
            }
        }

        var fdw = "";
        if (company.hours_full_time) {
            if (company.reduced_hours_description) {
                fdw = <><FontAwesomeIcon icon="check-circle" /> {company.reduced_hours_description}</>
            } else if (reduced_hours == "Offered") {
                fdw = <><FontAwesomeIcon icon="check-circle" /> {`4 x ${Math.round(company.hours_full_time / 4.0)} hour days`}</>
            } else if (reduced_hours == "Would consider") {
                fdw = <span className="title-salary-80">{`4 x ${Math.round(company.hours_full_time / 4.0)}hr days @ 80% salary`}</span>;

            }
        }

        var companyLink = "";
        if (company.reduced_hours == "Part time" && !company.featured) {
            companyLink = <div className="title-company no-link"><h5>{company.name}</h5></div>
        } else if (company.slug) {
            companyLink = <Link to={"/company/" + company.slug + "/jobs"} className="btn btn-link success btn-lg title-company" >{company.name}</Link>;
        } else {
            companyLink = <Link to={"#"} className="btn btn-link success btn-lg title-company" >{company.name}</Link>;

        }

        return (

            <div className="title-content">
                {/*} <div className='hide-mobile'><div className="title-fdw">{fdw}</div></div>*/}
                <h3>{title}</h3>


                {companyLink}
                {cur_employees ? <div className="title-employees">{cur_employees} employees</div> : ""}

            </div>
        );
    }
}


export default JobTileListingTitle;