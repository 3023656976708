import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import { IsLoggedIn } from '../../utils/user';


class Menu extends React.Component {
	constructor() {
		super();
		this.menuWrapperRef = React.createRef();
		this.state = {
			isMobileExpanded: false,
		};

	}

	onToggleMobile() {
		this.setState({ isMobileExpanded: !this.state.isMobileExpanded });
	}

	render() {
		let { isMobileExpanded } = this.state;
		let { user } = this.props;

		let logoLink = "/";

		if(IsLoggedIn(user)){
		//	logoLink = "/jobs";
		}

		return (
			<div className={`row header-container`}> {/* Update this line */}

				<nav className="navbar">


					<div className="container-fluid">

						<div className="navbar-header">
							<Link to={logoLink} className="navbar-brand">

								<img
									src={'assets/logo-2023.png'}
									className="logo"
									alt="4 day week logo"
								/>

							</Link>
							<button type="button" className="navbar-toggle mobileCollapsed" aria-label="toggle" data-toggle="collapse" data-target="#main-menu" aria-expanded="false" onClick={() => this.onToggleMobile()}>
								<span className="sr-only"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>

						</div>

						<div className={(isMobileExpanded ? "" : "collapse") + " navbar-collapse"} id="main-menu" >
							<MenuMobile onToggleMobile={()=> this.onToggleMobile()} user={user} />
							<MenuDesktop user={user} />
						</div>
					</div>
				</nav >
			</div >
		);
	}
}

export default withRouter(Menu);




