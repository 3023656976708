import React from 'react';
import Creatable from 'react-select/creatable';
import Select from 'react-select';


class FieldSelectInner extends React.Component {


	render() {
		var { input, isCreatable, isSearchable } = this.props;
		if (isCreatable) {
			return (<Creatable formatCreateLabel={(inputValue) => <>Search for <strong>{inputValue}</strong></>} isClearable={true} {...this.props} isSearchable={true}/>);
		} else {
			return (<Select {...this.props} isSearchable={true}  />);
		}
	}
}

class FieldSelect extends React.Component {


	render() {

		var { options, input, isClearable, isMulti, isSearchable } = this.props;

		if (isMulti) {
			return (
				<FieldSelectInner  {...input} {...this.props} isMulti isClearable={isClearable === null ? true : isClearable} className='react-select-container' classNamePrefix="react-select" isSearchable={isSearchable}/>
			);
		}

		

		return (
			<FieldSelectInner  {...input} {...this.props} isClearable={isClearable === null ? true : isClearable} className='react-select-container' classNamePrefix="react-select"
				value={options ? options.filter(option => option.value && option.value === input.value) : input.value}
				onChange={value => input.onChange(value && value.value ? value.value : null)}
				onBlur={() => input.onBlur(input && input.value ? input.value : null)} isSearchable={isSearchable} />
		);

	}
}

export default FieldSelect;