import React from 'react';
import HelmetMeta from '../components/global/HelmetMeta';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import CountryHeader from '../components/country/CountryHeader';
import CompaniesList from '../components/list/CompaniesList';
import Loader from '../components/misc/Loader';
import MarkdownRenderer from '../components/misc/MarkdownRenderer';
import SubscriberInlineWrapper from '../components/subscriber-form/SubscriberInlineWrapper';
import * as itemActions from '../store/item/actions';
import * as listActions from '../store/list/actions';
import * as vars from '../store/vars';
import * as FormatTools from '../utils/format';
import NotFound from './NotFound';
import SubscriberModalWrapper from '../components/subscriber-form/SubscriberModalWrapper';
import ArticleAuthor from '../components/article-page/ArticleAuthor';


class Country extends React.Component {


	/* SSR start */
	static page = "countries"
	static schema = "country";
	static loadDataSSR(url) {
		var slug = url.trim("/").split("/")

		slug = FormatTools.StripTrailingSlash(slug[slug.length - 1])
		return itemActions.getItemSSR(Country.page, { slug: slug, live: false });
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.item.get = {
			requested: true,
			loading: false,
			response: data
		};
		return s;
	}

	/* SSR end */

	componentDidMount() {
		// lol

		if (!this.props.country.requested || !this.props.country.response || (this.props.match.params.slug != this.props.country.response.slug)) {
			this.props.getCountry({ slug: this.props.match.params.slug, live: false }); // meh, just show the page (so backlinks dont break) but dont show companies
		}
		this.props.getCompanies({ location: this.props.match.params.slug });
	}

	componentWillUnmount() {
		this.props.reset();
	}


	render() {
		var { country, companies } = this.props;


		var { response: countryData, loading: countryLoading, requested: countryRequested } = country;
		var { loading: companiesLoading, init: companiesRequested } = companies;

		if (!countryRequested) {
			return <Loader />;
		} else if (countryLoading || countryData.country) {
			return <Loader />
		} else if (countryRequested && countryData && countryData.error) {
			return <NotFound />
		}



		// Meta tags
		var metaTitle = `4 Day Work Week ${countryData.name} (${(new Date().getFullYear())})`;
		var metaDesc = countryData.name + " is hiring on a 4 day work week. Are you ready to start your career at " + countryData.name + "? Submit your company application today.";// descSpl.slice(0, Math.min(descSpl.length, 3)).join("\n\n");;
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/country/" + countryData.slug;


		// Companies html
		var companiesHtml = <div className="row country-companies">
			<div className="row">
				<h3>4 Day Week Companies in {countryData.name}</h3>
			</div>
			<div className="row  margin-top">
				<CompaniesList data={companies} noResultsMsg="Sorry, we aren't hiring at the moment." page={"country"} max={5} hideAdvert={true} countrySlug={countryData.slug} />
				{companies.itemIds.length == 0 ? <p>Unfortunately, there are no 4 day week companies from {countryData.name} on the platform yet...</p> : ""}

			</div>
		</div>

		if (companies.itemIds.length == 0) {
			companiesHtml = "";
		}


		var html = countryData.html ? countryData.html : "";
		return (
			<div className="country-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				{/* Left column */}


				<div className="row country-header-wrapper">



					<div className="row country-header-content">


						{countryData.flag_url ?
							<LazyLoadImage
								threshold={100}
								height={60}
								width={60}
								alt={countryData.name}
								src={countryData.flag_url}
								className="country-image"
							/>
							: ""}

						<CountryHeader {...this.props} />



					</div>
				</div>


				<div className="row country-content">

					<div className='row'>
						<div className='col-sm-8 cols'>
							<div className="row">

								<SubscriberModalWrapper showForDelay={true} showForMouse={true} showForScroll={true} />

								<div className="country-four-day-week-description">
									<MarkdownRenderer doFollow={true}>{countryData.four_day_week_description}</MarkdownRenderer>
								</div>

								<SubscriberInlineWrapper className="subscriber-green" />

								<div className="country-description">
									<MarkdownRenderer doFollow={true}>{html}</MarkdownRenderer>
								</div>


							</div>

							<ArticleAuthor />


						</div>



						<div className='col-sm-4 cols'>
							{companiesHtml}
						</div>


					</div>


					<div className='row'>


						<div className='margin-top-large col-sm-8 cols'>

							<SubscriberInlineWrapper className="subscriber-green" />
						</div>
					</div>


				</div>




			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		companies: state.list.countries_list_companies,
		country: state.item.get,
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({
	getCompanies: (params) => {
		dispatch(listActions.fetchItems("countries", "list_companies", params));
	},
	getCountry: (data) => {
		dispatch(itemActions.getItem("countries", data));
	},

	reset: (data) => {
		dispatch(itemActions.resetItem());
		dispatch(listActions.resetItems("companies", "country"));
	},
});

Country = connect(mapStateToProps, mapDispatchToProps)(Country);

export default Country;