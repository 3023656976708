import HelmetMeta from '../components/global/HelmetMeta';
import React from 'react';
import JobCopilotHero from '../components/job-copilot/JobCopilotHero';
import JobCopilotAsSeenOn from '../components/job-copilot/JobCopilotAsSeenOn';
import JobCopilotHowItWorks from '../components/job-copilot/JobCopilotHowItWorks';
import JobCopilotWhyUse from '../components/job-copilot/JobCopilotWhyUse';
import JobCopilotFeatures from '../components/job-copilot/JobCopilotFeatures';
import JobCopilotPlans from '../components/job-copilot/JobCopilotPlans';
import JobCopilotJoin from '../components/job-copilot/JobCopilotJoin';

class JobCopilot extends React.PureComponent {


    constructor(props) {
        super(props);


        this.state = {

        };
    }



    render() {


        var metaTitle = "Automate your Job Applications with AI";
        var metaDesc = `Get 10x more Job Interviews with AI`;
        var metaImg = "https://media.fourdayweek.io/files/social-share.png";
        var metaUrl = "https://4dayweek.io/auto-apply";


        return (
            <div className="main-container  job-copilot-landing">
                <HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

                <JobCopilotHero />
                <JobCopilotAsSeenOn />
                <JobCopilotHowItWorks />
                <JobCopilotWhyUse />
                <JobCopilotFeatures />
                <JobCopilotPlans />
                <JobCopilotJoin />
            </div >
        );
    }
}

export default JobCopilot;



