import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import * as URLTools from '../../utils/url';

import { AddUTM, GetFirstSentence} from '../../utils/format';

class CompanyProfile extends React.Component {

    render() {

        var { company: companyData, info} = this.props;


        var company_url = AddUTM(companyData.company_url);

        if(!companyData && !info){
            return "";
        }

        if(info && info.industry == "staffing & recruiting"){
            return "";
        }

        // Company name
        let companyName = "";

        if(companyData.name){
            companyName = companyData.name;
        } else if (info && info.name){
            companyName = info.name;
        }

        // Description 
        let description = "";
        
        if(companyData.short_description){
            description = companyData.short_description;
        }else if (info && info.seo_description){
            description = GetFirstSentence(info.seo_description);
        } else if (info && info.short_description){
            description =  GetFirstSentence(info.short_description);
        }

        return (
            <><div className="company-side-box  company-profile ">


                {companyData.logo_url ? 
                <Link to={"/company/" + companyData.slug + "/jobs"} ><LazyLoadImage
                    threshold={100}
                    height={30}
                    alt={companyData.name}
                    src={companyData.logo_url}
                    className="company-logo"
                /></Link> : ""}
                <Link to={"/company/" + companyData.slug + "/jobs"} className="btn btn-link btn-lg row bold">{companyName}</Link>
                {companyData.company_url ?
                    <a onClick={(e) => e.stopPropagation()} aria-label="company-link"  href={company_url} target="_blank" className="btn btn-link btn-sm website-link" rel="noopener noreferrer nofollow">{URLTools.ExtractHostname(companyData.company_url)}</a>
                    : ""}

                <p>{description}</p>
                <div className="row apply-btn margin-top">

                    
                    <Link to={"/company/" + companyData.slug + "/jobs"} className="btn btn-default btn-sm">View all jobs</Link>


                </div>


            </div>
            </>
        )
    }
}

export default CompanyProfile;