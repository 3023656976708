import { faBriefcase, faSearch, faDesktop, faFileAlt, faFileWord, faUserTie, faHandshake, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

class JobCopilotFeatures extends Component {
  render() {
    return (
      <div className="job-copilot-features">
        <div className="content">
          <h2>Job Copilot Features</h2>
          <h6>Eight must-have job search tools bundled into 1 solution.</h6>

          <div className="jobcopilot-features-items">
            {/* First Row */}
            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faSearch} />
              </div>
              <div className="info">
                <h4>Job Search Automation</h4>
                <p>Use your copilot to automatically search for new jobs daily.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faBriefcase} />
              </div>
              <div className="info">
                <h4>Job Application Automation</h4>
                <p>Configure your copilot to automatically apply for jobs on your behalf.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faDesktop} />
              </div>
              <div className="info">
                <h4>Job Application Tracker</h4>
                <p>Manage the progress of your job applications on one simple dashboard.</p>
              </div>
            </div>



            {/* Second Row */}
            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faFileAlt} />
              </div>
              <div className="info">
                <h4>AI Resume Builder</h4>
                <p>Create a standout resume and tailor it for any job with AI.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faFileWord} />
              </div>
              <div className="info">
                <h4>AI Cover Letter Builder</h4>
                <p>Generate professional Cover Letters tailored for different jobs.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faUserTie} />
              </div>
              <div className="info">
                <h4>AI Mock Interviews</h4>
                <p>Practice with your own Job Interview roleplaying chatbot.</p>
              </div>
            </div>

            {/* Third Row */}
            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faHandshake} />
              </div>
              <div className="info">
                <h4>AI Offer & Salary Negotiation</h4>
                <p>Get advice on how to negotiate the best offer.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faLightbulb} />
              </div>
              <div className="info">
                <h4>AI Career Advisors</h4>
                <p>Get advice on what jobs are fit for you based on your skills, experience and personality.</p>
              </div>
            </div>

            <div className="jobcopilot-features-item">
              <div className="img-mask">
                <FontAwesomeIcon icon={faUserTie} />
              </div>
              <div className="info">
                <h4>AI Job Interview Coach</h4>
                <p>Practice with your own Job Interview roleplaying chatbot.</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default JobCopilotFeatures;
