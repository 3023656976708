import React from 'react';

const ResumeReviewTrust = () => {
    return (
        <div className="resume-review-trust">
            <div className="content">
                <h2>Trusted By Thousands of Job Seekers</h2>
                
                <div className="testimonial-wrapper">
                    <div className="testimonial">
                        <div className="quote">
                            "I had been applying to jobs for months without getting callbacks. After using this resume review tool and implementing the suggestions, I landed three interviews in the first week!"
                        </div>
                        <div className="author">
                            <div className="avatar">
                                <img src="https://media.fourdayweek.io/files/testimonials/user1.jpg" alt="User testimonial" />
                            </div>
                            <div className="info">
                                <h5>Sarah J.</h5>
                                <p>Software Developer</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="trust-features">
                    <div className="trust-feature">
                        <div className="trust-icon">
                            <i className="fa fa-shield"></i>
                        </div>
                        <div className="trust-info">
                            <h4>Secure & Private</h4>
                            <p>Your resume data is encrypted and never shared with third parties</p>
                        </div>
                    </div>
                    
                    <div className="trust-feature">
                        <div className="trust-icon">
                            <i className="fa fa-bolt"></i>
                        </div>
                        <div className="trust-info">
                            <h4>Instant Results</h4>
                            <p>Get comprehensive feedback in seconds, not days</p>
                        </div>
                    </div>
                    
                    <div className="trust-feature">
                        <div className="trust-icon">
                            <i className="fa fa-brain"></i>
                        </div>
                        <div className="trust-info">
                            <h4>AI-Powered</h4>
                            <p>Leveraging the latest AI models for accurate analysis</p>
                        </div>
                    </div>
                </div>
                
                <div className="final-cta">
                    <h3>Ready to improve your resume?</h3>
                    <a href="#resume-uploader" className="cop-btn">Get Your Free Resume Review</a>
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewTrust; 