import HelmetMeta from '../components/global/HelmetMeta';
import React from 'react';
import CoverLetterHero from '../components/cover-letter-generator/CoverLetterHero';
import CoverLetterForm from '../components/cover-letter-generator/CoverLetterForm';
import CoverLetterFeatures from '../components/cover-letter-generator/CoverLetterFeatures';
import CoverLetterHowItWorks from '../components/cover-letter-generator/CoverLetterHowItWorks';
import CoverLetterTestimonial from '../components/cover-letter-generator/CoverLetterTestimonial';

class CoverLetterGenerator extends React.PureComponent {
    constructor(props) {
        super(props);
        
        // Check for URL parameter
        const urlParams = new URLSearchParams(window.location.search);
        const jobUrl = urlParams.get('url') || '';
        
        this.state = {
            jobUrl: jobUrl,
            jobDescription: '',
            resumeText: localStorage.getItem('userResumeText') || '',
            generatedCoverLetter: '',
            isGenerating: false,
            resumeFile: null
        };
        
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleResumeUpload = this.handleResumeUpload.bind(this);
        this.generateCoverLetter = this.generateCoverLetter.bind(this);
    }
    
    handleInputChange(event) {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }
    
    handleResumeUpload(event) {
        const file = event.target.files[0];
        if (!file) return;
        
        this.setState({ resumeFile: file });
        
        // Parse resume content
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            // Store in localStorage
            localStorage.setItem('userResumeText', text);
            this.setState({ resumeText: text });
        };
        reader.readAsText(file);
    }
    
    generateCoverLetter(event) {
        event.preventDefault();
        this.setState({ isGenerating: true });
        
        // Simulate API call to generate cover letter
        setTimeout(() => {
            const sampleCoverLetter = `Dear Hiring Manager,

I am writing to express my interest in the [Position] role at [Company]. With my background in [relevant experience] and passion for [industry/field], I believe I would be a valuable addition to your team.

Based on the job description, I understand you're looking for someone with experience in [key requirement]. During my time at [Previous Company], I successfully [relevant achievement that matches job requirements]. Additionally, I [another relevant accomplishment].

What particularly excites me about this opportunity is [something specific from job posting]. I am confident that my skills in [relevant skills] align perfectly with what you're seeking.

I would welcome the opportunity to discuss how my background, skills and experiences would benefit [Company]. Thank you for considering my application.

Sincerely,
[Your Name]`;
            
            this.setState({
                generatedCoverLetter: sampleCoverLetter,
                isGenerating: false
            });
        }, 2000);
    }

    render() {
        const metaTitle = "AI Cover Letter Generator - Create Perfect Cover Letters in Seconds";
        const metaDesc = "Generate customized, professional cover letters instantly with our AI-powered tool. Tailored to your resume and job description.";
        const metaImg = "https://media.fourdayweek.io/files/social-share.png";
        const metaUrl = "https://4dayweek.io/cover-letter-generator";

        return (
            <div className="main-container cover-letter-landing">
                <HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

                <CoverLetterHero />
                <CoverLetterForm 
                    jobUrl={this.state.jobUrl}
                    jobDescription={this.state.jobDescription}
                    resumeText={this.state.resumeText}
                    generatedCoverLetter={this.state.generatedCoverLetter}
                    isGenerating={this.state.isGenerating}
                    handleInputChange={this.handleInputChange}
                    handleResumeUpload={this.handleResumeUpload}
                    generateCoverLetter={this.generateCoverLetter}
                />
                <CoverLetterFeatures />
                <CoverLetterHowItWorks />
                <CoverLetterTestimonial />
            </div>
        );
    }
}

export default CoverLetterGenerator; 