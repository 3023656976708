
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from '../misc/Loader';
import courseRatingImg from '../../assets/course-rating.png';

class ArticleAdvertTile extends React.Component {

    render() {

        let { advert } = this.props;


        if (!advert || !advert.requested || !advert.response) {
            return "";
        } else if (advert && advert.loading) {
            return <Loader />
        }
        var data = advert.response;


        let htmlTags = [];

        if (data && data.tags && data.tags.length > 0) {
            htmlTags = data.tags.map((tag) => {
                return <li>{tag.label}</li>
            });
        }


        return (
            <>
                <a className={"article-notad-tile-wrapper "} key={"course-ad-" + data.id_str} href={data.url} onClick={(e) => this.props.handleClick(e)} rel="noopener sponsored noreferrer nofollow" target="_blank">


                    <div className='article-notad-tile-image-wrapper'>
                        <LazyLoadImage
                            //  style={{ backgroundImage: `url(${data.company.logo_url})` }} 
                            className="article-notad-tile-image"
                            alt={`${data.title} logo`}
                            threshold={100}

                            src={data.image_url} // use normal <img> attributes as props
                        />
                        <div className='article-notad-meta'>
                            <LazyLoadImage src={courseRatingImg} className='article-notad-rating' />
                            <ul className="article-notad-tile-tags">
                                {htmlTags}
                            </ul>
                        </div>
                    </div>



                    <div className="article-notad-tile-content-wrapper">
                        <h4>{data.company_name}</h4>
                        <h5>{data.title}</h5>
                        <p>{data.description}</p>

                        <div className='row padded-top'>
                            <a href={data.url} className='article-notad-tile-link btn btn-success cta btn-lg' rel="noopener sponsored noreferrer nofollow" target="_blank">{data.cta}</a>
                            <h6>Limited time offer</h6>
                        </div>
                    </div>









                </a >

            </>
        );
    }
}



export default ArticleAdvertTile;