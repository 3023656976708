Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

/* Computes X Days ago for a timestamp */
export function TimeMinutesAgoShort  (previous) {
	var cDate = new Date(),
		current = cDate.getTime(),
		msPerMinute = 1000 * 60,
		elapsed = current - previous;

	return Math.round(elapsed / msPerMinute);
};


/* Computes X Days ago for a timestamp */
export function TimeAgoShort  (previous, full) {
	var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		cDate = new Date(),
		previous = Number(previous),
		current = cDate.getTime(),
		msPerMinute = 1000 * 60,
		msPerHour = msPerMinute * 60,
		msPerDay = msPerHour * 24,
		msPerMonth = msPerDay * 30,
		elapsed = current - previous,
		oDate = new Date(previous);

	let extra = "";

	if (elapsed < msPerMinute) {
		if (full) {
			extra = " secs ago";
		} else {
				extra = "s";
		}
		return Math.round(elapsed/1000)+ extra;
	} else if (elapsed < msPerHour) {
		if (full) {
			extra = " mins ago";
		} else {
				extra = "m";
		}
		return Math.round(elapsed / msPerMinute)  + extra;
	} else if (elapsed < msPerDay) {
		if (full) {
			extra = " hrs ago";
		} else {
				extra = "h";
		}
		return Math.round(elapsed / msPerHour) + extra;
	} else if (elapsed < msPerMonth) {
		if (full) {
			extra = " days ago";
		} else {
				extra = "d";
		}
		return Math.round(elapsed / msPerDay) + extra;
	} else {
		return monthNames[oDate.getMonth()] + ' ' + (oDate.getDate() + 1);
	}
};


export function FullDateTime  (d) {
	var date = new Date(parseInt(d)),
		hours = date.getHours(),
		minutes = date.getMinutes();


	minutes = minutes < 10 ? '0' + minutes : minutes;

	var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	return hours + ':' + minutes + ' - ' + date.getDate() + ' ' + monthNames[date.getMonth()];// + ' \'' + date.getFullYear().toString().substr(-2);
};


export function FullDate  (d, full = true) {
	var date = new Date(parseInt(d));

	var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	if (full) {
		return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear();
	} else {
		return date.getDate() + ' ' + monthNames[date.getMonth()];
	}
};

export function DateStringUnix (str) {
	return Math.floor(new Date(str).getTime());
}


export function Unix () {
	return Math.floor(Date.now() / 1000)
}

export function EpochToDateString(utcSeconds){
	var unixEpochTime = new Date(0); // The 0 there is the key, which sets the date to the epoch
	unixEpochTime.setUTCSeconds(utcSeconds);


	// Create a new JavaScript Date object and set its time to the Unix epoch time
	const date = new Date(unixEpochTime);

	// Get the time zone offset in minutes
	const timeZoneOffset = date.getTimezoneOffset();

	// Convert the time zone offset to the format "+hhmm" or "-hhmm"
	const timeZoneString = (timeZoneOffset > 0 ? "-" : "+")
	+ ("0" + Math.abs(timeZoneOffset / 60)).slice(-2)
	+ ("0" + Math.abs(timeZoneOffset % 60)).slice(-2);

	// Use the toISOString() method to convert the date to a string in the specified format
	const dateString = date.toISOString().replace(/\.\d{3}Z$/, timeZoneString);
	return dateString;
}