
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import WorkLifeBalancesList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class WorkLifeBalances extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_work_life_balances"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(WorkLifeBalances.page, WorkLifeBalances.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(WorkLifeBalances.page + "_" + WorkLifeBalances.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: WorkLifeBalances.page,
			id: WorkLifeBalances.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "Work Life Balance Guides";
		var metaDesc = "Striving for a balanced professional life? Our in-depth guides shed light on work-life dynamics, benefits, and the nuances of maintaining equilibrium in today's work culture.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/work-life-balance";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<WorkLifeBalancesList data={data} settings={settings} />
				</section>




				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[WorkLifeBalances.page + "_" + WorkLifeBalances.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(WorkLifeBalances.page, WorkLifeBalances.id));
	},


});

WorkLifeBalances = connect(mapStateToProps, mapDispatchToProps)(WorkLifeBalances);

export default WorkLifeBalances;