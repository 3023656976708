import React from 'react';

const ResumeReviewWhyUse = () => {
    return (
        <div className="resume-review-why-use">
            <div className="content">
                <h2>Why Use Our AI Resume Review?</h2>
                <h6>Stand out from the competition with an optimized resume</h6>

                <div className="why-use-jobcopilot-items">
                    <div className="why-use-jobcopilot-item">
                        <div className="info">
                            <h4>Recruiters spend less than <span className="text-accent">7 seconds</span> reviewing a resume</h4>
                            <p>In today's competitive job market, your resume needs to make an immediate impact. Our AI analyzes what makes a resume stand out and helps you optimize yours for maximum impact.</p>
                        </div>
                    </div>

                    <div className="why-use-jobcopilot-item">
                        <div className="info">
                            <h4><span className="text-accent-green">75%</span> of resumes are rejected by ATS before a human sees them</h4>
                            <p>Many qualified candidates get filtered out by Applicant Tracking Systems (ATS). Our tool checks if your resume is ATS-friendly and helps you optimize it to pass through these digital gatekeepers.</p>
                        </div>
                    </div>

                    <div className="why-use-jobcopilot-item">
                        <div className="info">
                            <h4>Most job seekers don't know what makes a resume <span className="text-accent">effective</span></h4>
                            <p>Our AI has analyzed thousands of successful resumes from people who got hired at top companies. We'll help you implement the same winning strategies in your resume.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumeReviewWhyUse; 