import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
class CompanyTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hovering: false,
            expanded: false,
        };
    }

    onClick(e) {
        this.setState({ expanded: !this.state.expanded });
    }


    render() {
        var { data } = this.props;
        var { hovering } = this.state;


        var url = "";

        // Logo
        var img = "";

        if (data.logo_url && data.logo_url != "") {
            img = <LazyLoadImage
                className="company-tile-logo"
                alt={`${data.name} logo`}
                height={50}
                src={data.logo_url} // use normal <img> attributes as props
                width={50} />
        } else {
            img = <div className="company-tile-missing-logo">{data.name.substr(0, 1)}</div>
        }

        // Desc
        var desc = "";

        if (data.short_description) {
            desc = data.short_description;
        } else if (data.description) {
            var spl = data.description.split(". ")
            desc = spl[0];
        }

        if (desc && (typeof desc.trimEnd === 'function') && !desc.trimEnd().endsWith(".")) {
            desc = desc.trimEnd() + ".";
        }

        var url = "/company/" + data.slug + "/jobs";


        // Company size
        var companySize = data.company_size ? data.company_size.replace("SIZE_", "").replace("_", "-") : "";

        if (data.company_size && data.company_size.indexOf("10000") > -1) {
            companySize = "10,000+";
        }


        // Location / remote
        var location = "";



        if (data.hiring_city) {

            if (data.remote_level && data.remote_level == "Offers remote") {
                location = "Hybrid Remote, " + data.hiring_city
            } else if (data.remote_level && data.remote_level == "Onsite") {
                location = "Onsite, " + data.hiring_city
            } else {
                location = "Remote, " + data.hiring_city;
            }

        } else if (data.hiring_country) {

            if (data.remote_level && data.remote_level == "Offers remote") {
                location = "Hybrid Remote, " + data.hiring_country
            } else if (data.remote_level && data.remote_level == "Onsite") {
                location = "Onsite, " + data.hiring_country
            } else {
                location = "Remote, " + data.hiring_country;
            }

        } else if (data.hiring_continent) {

            if (data.remote_level && data.remote_level == "Offers remote") {
                location = "Hybrid Remote, " + data.hiring_continent
            } else if (data.remote_level && data.remote_level == "Onsite") {
                location = "Onsite, " + data.hiring_continent
            } else {
                location = "Remote, " + data.hiring_continent;
            }

        } else if ((data.num_fully_remote_jobs && data.num_fully_remote_jobs == data.num_live_jobs)) {
            location = "100% Remote";
        } else {

            if (data.remote_level && data.remote_level == "Offers remote") {
                location = "Hybrid Remote";
            } else if (data.remote_level && data.remote_level == "Onsite") {
                location = "Onsite";
            } else {
                location = "Remote"
            }

        }



        // Hours
        var reduced = "";
        let fourDayWeekText = "";


        if (data.reduced_hours_description) {
            reduced = <li className={""}>{data.reduced_hours_description}</li>
            if (data.four_day_reference_text) {
                fourDayWeekText = data.four_day_reference_text;
            } else if (data.reduced_hours_description) {
                fourDayWeekText = data.reduced_hours_description;
            }
        } else if (data.reduced_hours == "Offered") {
            reduced = <li className={"company-reduced offered"}>4 {data.hours_full_time ? " x " + Math.round(data.hours_full_time / 4.0) + "hr" : ""} days @ 100% salary</li>
            fourDayWeekText = "We work 4 normal length days at full salary."
        } else if (data.reduced_hours == "Offered 4.5 days") {
            reduced = <li className={"company-reduced offered-other"}>4.5 day week</li>
            fourDayWeekText = "We work 4.5 days at full salary."

        } else if (data.reduced_hours == "Offered every 2nd week") {
            reduced = <li className={"company-reduced offered-other"}>4 days week every 2nd week</li>
            fourDayWeekText = "We work 4 days every 2nd week at full salary."

        } else if (data.reduced_hours == "Offered summer") {
            reduced = <li className={"company-reduced offered-other"}>4 day week during Summer</li>
            fourDayWeekText = "We work 4 days per week during the Summer months."

        } else if (data.reduced_hours == "Generous PTO") {

            if (data.vacation) {
                reduced = <li className={"company-reduced offered-other"}>5 day week ({data.vacation} days PTO)</li>
                fourDayWeekText = "We work 5 days per week but offer " + data.vacation + " days PTO per year."
            } else {
                reduced = <li className={"company-reduced offered-other"}>5 day week (amazing vacation policy)</li>
                fourDayWeekText = "We work 5 days per week but have an amazing vacation policy."
            }
        } else if (data.reduced_hours == "Unlimited PTO") {
            reduced = <li className={"company-reduced offered-other"}>5 day week (unlimited PTO)</li>;
            fourDayWeekText = "We work 5 days per week but have an unlimited vacation policy."
        } else if (data.reduced_hours == "Flex Fridays") {
            reduced = <li className={"company-reduced offered-other"}>Flex Fridays</li>;
            fourDayWeekText = "We offer 'Flex Fridays' - a flexible day, with no meetings, designed to help you catch up on work or take a long weekend."
        } else if (data.reduced_hours == "Flexible hours") {
            reduced = <li className={"company-reduced offered-other"}>5 day week (work your own hours)</li>;
            fourDayWeekText = "We work 5 days per week, but are fully flexible with our hours. We have a culture of 'as long are you get the work done' - we don't mind which hours you work."
        }  else if (data.reduced_hours == "9 day fortnight") {
            reduced = <li className={"company-reduced offered-other"}>9 Day Fortnight</li>;

            if (data.hours_full_time) {
                fourDayWeekText = `We work a 9 day fortnight. That's 9 days of work (${data.hours_full_time * 2} hours) over 2 weeks, with every second Friday off.`
            } else {
                fourDayWeekText = `We work a 9 day fortnight. That's 9 days of work (compressed hours) over 2 weeks, with every second Friday off.`
            }


        } else if (data.reduced_hours == "Would consider") {
            reduced = <li className={"company-reduced offered-other"}>4 days @ 80% salary</li>
            fourDayWeekText = "We work 4 days per week at 80% salary."
        }

        return (
            <li>
                <Link to={url} id={data.slug} className={"company-tile-wrapper " + (hovering ? "hovering" : "")} onClick={(e) => this.onClick(e)} onMouseEnter={(e) => this.setState({ hovering: true })} onMouseLeave={(e) => this.setState({ hovering: false })}>

                    {img}


                    <div className={"row company-tile show-hover " + " " + (data.featured ? "" : "")}>

                        <div className="inner-triangle"></div>



                        {/*data.featured ?
                            <div className="company-featured">
                                Featured
                        </div>
                        : ""*/}
                        <div className="company-tile-top">


                            <h3 className="company-tile-title">

                                {/*}     <div className={"company-tile-ranking " + (data.featured ? "green" : "")}>{/*data.rank}{data.featured ? <span className="small">Featured</span> : ""}</div> : ""*/}
                                {data.name}</h3>
                            <div className="company-features">

                                <div className="company-score">

                                    <div className="company-score-bar">
                                        <div className="company-score-tube" style={{ width: `${data.score}%` }}></div>
                                    </div>


                                    <div className="company-score-number" data-tip="Work-life balance score based on criteria at top">
                                        {data.score > 100 ? 100 : data.score}<span className="small">/ 100</span>


                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className='company-tile-middle'>
                            <p className='company-name-desc'>{desc}</p>
                            <p className='reduced_hours'>{fourDayWeekText}</p>
                        </div>



                        <div className="company-tile-bottom">
                            <div className="company-tile-left">
                                <ul className='row company-details'>


                                    {reduced}
                                    {data.benefit_unlimited_pto ? <li className='pto'>Unlimited PTO</li> : ""}
                                    {!data.benefit_unlimited_pto && data.vacation ? <li className='pto'>{data.vacation} days PTO</li> : ""}
                                    {location ? <li className='company-location'>{location}</li> : ""}
                                    {companySize ? <li className='company-num-employees'>{companySize} employees</li> : ""}
                                </ul>
                            </div>

                            <div className="company-tile-right">
                                <div className="company-roles">
                                    {data.num_live_jobs ? <>{data.num_live_jobs} {data.num_live_jobs > 1 ? "open jobs" : "open job"}</> : ""}
                                </div>
                            </div>

                        </div>



                    </div>



                </Link>
            </li>
        );
    }
}

export default CompanyTile;