import React, { Component } from 'react';

class JobCopilotAsSeenOn extends Component {
  render() {
    return (
      <div className="job-copilot-as-seen-on">
        <div className="content">
          <h3>
            The world’s leading <span className="text-accent">AI Job Application Automation</span> tool
          </h3>
          <h4>As seen on</h4>
          <div className="as-seen-on-logos">
            <img
              alt="Yahoo finance logo"
              src="https://media.fourdayweek.io/files/yahoo-finance.png"
            />
            <img
              alt="Business Insider logo"
              src="https://media.fourdayweek.io/files/business-insider.png"
            />
            <img
              alt="Product Hunt logo"
              src="https://media.fourdayweek.io/files/producthunt.png"
            />
            <img
              alt="Market Watch logo"
              src="https://media.fourdayweek.io/files/marketwatch.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default JobCopilotAsSeenOn;
