import MarkdownRenderer from '../misc/MarkdownRenderer';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
class CountryTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggled: false,
        };
    }


    render() {
        var { data } = this.props;
        var { isToggled } = this.state;


        var img = "";

        if (data.logo_url != "") {
            img = <Link to={"/country/" + data.slug} > <LazyLoadImage
                className="country-flag"
                alt={`${data.name} logo`}
                height={50}
                src={data.flag_url} // use normal <img> attributes as props
                width={50} />
            </Link>
        } else {
            img = <div className="country-tile-missing-logo">{data.name.substr(0, 1)}</div>
        }


        return (
            <li>
                <div className="country-tile-wrapper" onClick={() => this.setState({ isToggled: !isToggled })}>

                    {/*}     <Link to={"/country/"+data.slug} className="country-tile-wrapper">*/}
                    {img}


                    <div className={"row country-tile " + " " + (isToggled ? "show-hover" : "")}>



                        {/*data.featured ?
                            <div className="country-featured">
                                Featured
                        </div>
                        : ""*/}
                        <div className="country-tile-top">



                            <div className="country-tile-title">
                                <Link to={"/country/" + data.slug} >  <h2><span className="country-rank">{data.rank}.</span> {data.name}</h2></Link>


                                <div className='country-policy'>
                                    <p>{data.policy}</p>
                                </div>
                            </div>

                            <div className="country-features">

                                <div className="country-cta">
                                    <Link to={"/country/" + data.slug} className="btn btn-success btn-sm">More info...</Link>


                                </div>

                            </div>

                        </div>



                        <div className="country-tile-bottom">
                            <div className="">
                                <p>{data.description}</p>
                            </div>


                            {/*} <div className="country-tile-right">
                            <div className="country-fdw">
                                {data.continent}
                            </div>
                    </div>*/}

                        </div>

                    </div>
                </div>
                {data.four_day_week_description ?
                    <div className={"country-fdw-description " + (isToggled ? "display" : "hidden")}>

                        <div className='country-fdw-description-markdown'>
                            <MarkdownRenderer keepInternal={true} doFollow={true}>{data.four_day_week_description}</MarkdownRenderer>
                        </div>
                        <Link to={"/country/" + data.slug} className="btn btn-huge cta btn-success">More info...</Link>
                    </div>


                    : ""}

            </li>
        );
    }
}

export default CountryTile;