import React from 'react';

import * as FormatTools from '../../utils/format';


class ArticleRelatedTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        var { data } = this.props;

        return (
            <a href={`/${FormatTools.Slugify(data.topic)}/${data.slug}`} target="_blank" rel="noopener noreferrer">
                <div className='tile-text'>
                    <h3>{data.title}</h3>
                </div>

            </a>
        );
    }
}

export default ArticleRelatedTile;