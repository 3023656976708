import Loader from '..//misc/Loader';
import React from 'react';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import JobTile from '../tiles/JobTile';
import JobAdvertTile from '../tiles/JobAdvertTile';
import CritiqueNotificationTile from '../tiles/CritiqueNotificationTile';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';

class JobsList extends React.Component {


    render() {

        var { data, noResultsMsg, page, adverts } = this.props;


        if (!data || !data.init || data.loading) {
            return <Loader padded text="Fetching 4 day work week jobs..." className="min-height" />
        }

        var i = 0;
        let j = 0;
        var jobs = data.itemIds.map((d) => {
            i += 1;

            // Adverts
            let ad = "";
            if ((i == 4 || ((i % 10) == 0)) && adverts.init && !adverts.loading) {
                ad = <JobAdvertTile page="jobs" pos={j} />
                j += 1;
                if (j > adverts.itemIds.length - 1) {
                    j = 0;
                }
            }

            // WriteSea
            let writeSea = "";
            if (i == 1) {
                writeSea = <CritiqueNotificationTile />
            }

            // Subscribe form
            let subscribe = "";
            if ((i == 9) || (i % 40 == 0)) {
                subscribe = <SubscriberInlineWrapper className="subscriber-green" />
            }



            return (
                <>
                    {subscribe ? subscribe : ad}
                    {writeSea}

                    <JobTile data={data.items[d]} newClick={(d) => this.props.newClick(d)} page={page} />
                </>
            );

        });



        return (

            <div className="row jobs-list">
                {jobs.length == 0 ? <p className="no-jobs">{noResultsMsg ? noResultsMsg : "Sorry, no jobs exist for your search query..."}</p> : jobs}
            </div>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        adverts: state.list.adverts_list_home,
        user: state.item.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

JobsList = connect(mapStateToProps, mapDispatchToProps)(JobsList);

export default JobsList;
