import CareerPathTile from '../article-page/CareerPathTile';
import React from 'react';
import { connect } from 'react-redux';
import * as itemActions from '../../store/item/actions';
import Loader from '../misc/Loader';

class CareerPathsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        var { data } = this.props;

        if (!data || data.loading) {
            return <Loader padded text="Fetching latest updates..." className="min-height" />
        }

        var articles = data.itemIds.map((d, index) => <CareerPathTile data={data.items[d]} />);

        return (
            <ul className="row career-paths-list">
                {articles}
            </ul>

        );
    }
}


const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({

    newClick: (data) => {
        dispatch(itemActions.newItem("clicks", data));
    },

});

CareerPathsList = connect(mapStateToProps, mapDispatchToProps)(CareerPathsList);

export default CareerPathsList;
