import React from 'react';

class FieldCheckbox extends React.Component {

	render() {

		var { input } = this.props;

		return (
				<input {...input}  {...this.props}  type={"checkbox"} checked={input.value} />
		);
	}
}

export default FieldCheckbox;
