import arrayMutators from 'final-form-arrays';
import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as itemActions from '../../store/item/actions';
import EditJobFormApply from './EditJobFormApply';
import EditJobFormCompany from './EditJobFormCompany';
import EditJobFormJobDescription from './EditJobFormJobDescription';
import EditJobFormPreview from '../post-job/PostJobPreview';
import Loader from '../misc/Loader';
import { IsLoggedIn } from '../../utils/user';


class EditJobForm extends React.Component {


    getDefaultValues(job) {

        if (!job) {
            return {};
        }

        let defaults = job ? job : {};

        if (defaults.apply_email) {
            defaults.apply_method = "email";
        } else {
            defaults.apply_method = "redirect";
        }

        if (job.company) {
            defaults.remote_level = job.company.remote_level;
        }

        return defaults
    }

    componentDidMount() {
        this.props.getJob({ "id": this.props.match.params.id });

    }

    componentDidUpdate(prevProps, prevState) {
        let { user, data } = this.props;

        if (user.requested && !user.loading && !IsLoggedIn(user)) {
            this.props.history.push("/login?unauthorized=true");
            return;
        }


    }
    onSubmit(values) {
        console.log("Submitting");
        console.log(values);


        if (Array.isArray(values.company_logo_upload) && values.company_logo_upload.length > 0) {
            values.company_logo_upload = values.company_logo_upload[0];
        }

        this.props.setJob(values);
        this.props.history.push("/dashboard/jobs?updated=true");
    }



    render() {
        let { data } = this.props;

        if (!data || !data.requested || data.loading) {
            return <Loader />
        }

        let content = "";



        content = <Form
            onSubmit={(values) => this.onSubmit(values)}
            mutators={{
                ...arrayMutators,
                changeCompanyLogo: (args, state, utils) => {
                    utils.changeValue(state, 'company_logo_upload', () => args.length > 0 ? args[0] : "");
                },

            }}
            initialValues={this.getDefaultValues(data.response)}
            render={({
                handleSubmit,
                submitting,
                submitted,
                errors,
                pristine,
                values,
                form,
                form: {
                    mutators: { push, pop },
                },
            }) => (
                <form onSubmit={handleSubmit} className="box-theme" data-sparkloop-upscribe='false'>
                    <EditJobFormJobDescription values={values} data={data} />
                    <EditJobFormCompany values={values} form={form} data={data} />
                    <EditJobFormApply values={values} data={data} />

                    <EditJobFormPreview values={values} />
                    <div className="row padded-top edit-post-submit">
                        <button
                            type="submit"
                            disabled={submitting || Object.keys(errors).length > 0}
                            className="btn btn-huge btn-success cta"
                        >
                            Save
                        </button>
                    </div>
                </form>
            )}
        />



        return (
            <div className="post-job-form">
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.item.dashboard_job,
        user: state.item.user,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    setJob: (data) => {
        dispatch(itemActions.setItem("dashboard", data, "set_job"));
    },
    getJob: (data) => {
        dispatch(itemActions.getItem("dashboard", data, "dashboard_job", "get_job"));
    },

});

EditJobForm = connect(mapStateToProps, mapDispatchToProps)(EditJobForm);

export default withRouter(EditJobForm);
