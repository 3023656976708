
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import IndustriesList from '../../components/list/ArticlesList';
import Loader from '../../components/misc/Loader';
import * as listActions from '../../store/list/actions';
import * as listReducer from '../../store/list/reducer';
import * as vars from '../../store/vars';

class Industries extends React.Component {

	/* SSR start */
	static page = "articles"
	static id = "list_industries"

	static loadDataSSR(url) {
		return listActions.fetchItemsSSR(Industries.page, Industries.id);
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.list = listReducer.receiveListItems(Industries.page + "_" + Industries.id, s.list, {
			type: listActions.RECEIVE_LIST_ITEMS,
			page: Industries.page,
			id: Industries.id,
			response: data
		});
		return s;

	}
	/* SSR end */

	componentDidMount() {
		var { data } = this.props;

		if (!data.init) {
			this.props.loadData();
		}
	}

	render() {

		var { data, settings } = this.props;

		if (!data.init || data.loading) {
			return <Loader />
		}


		// Meta
		var metaTitle = "The 4 Day Week by Industry";
		var metaDesc = "Explore the 4 day week by industry. Dive into articles exploring diverse work schedules, offering fresh perspectives on how the modern workweek can be reimagined.";
		var metaImg = "https://media.fourdayweek.io/files/social-share.png";
		var metaUrl = "https://4dayweek.io/industries";


		return (
			<div className="main-container  medium-width articles-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} />

				<section className="row thin">
					<h1 className='smaller center padded-top'>{metaTitle}</h1>
					<h2 className='center page-subtitle'>{metaDesc}</h2>
				</section>

				<section className="row ">
					<IndustriesList data={data} settings={settings} />
				</section>


				<section className="row thin">
					<SubscriberInlineWrapper className="subscriber-green"/>
				</section>

			</div>
		);
	}
}



const mapStateToProps = (state) => {
	return {
		data: state.list[Industries.page + "_" + Industries.id],
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	loadData: () => {
		dispatch(listActions.fetchItems(Industries.page, Industries.id));
	},


});

Industries = connect(mapStateToProps, mapDispatchToProps)(Industries);

export default Industries;