
import React from 'react';
import HelmetMeta from '../../components/global/HelmetMeta';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../components/misc/Loader';
import SubscriberInlineWrapper from '../../components/subscriber-form/SubscriberInlineWrapper';
import * as itemActions from '../../store/item/actions';
import * as listActions from '../../store/list/actions';
import * as vars from '../../store/vars';
import * as FormatTools from '../../utils/format';
import * as TimeTools from '../../utils/time-tools';
import { IsLoggedIn } from '../../utils/user';


import ArticlesSideBar from '../../components/article-page/ArticleSideBar';
import MarkdownRenderer from '../../components/misc/MarkdownRenderer';
import SubscriberModalWrapper from '../../components/subscriber-form/SubscriberModalWrapper';
import NotFound from '../NotFound';

class Blog extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
		};
	}


	onClickAdvert(e) {
		e.stopPropagation();

		let {advert, user} = this.props;

		let clickData ={ 'page': 'blog', 'action': 'click_advert', 'item_id': advert.response.id_str };
       
		if (IsLoggedIn(user)) {
			clickData['user_id'] = user.response.id_str;
		}
		
		this.props.newClick(clickData);
	}

	/* SSR start */
	static page = "articles"
	static loadDataSSR(url) {
		var slug = url.split("/blog/")
		slug = FormatTools.StripTrailingSlash(slug[slug.length - 1])
		return itemActions.getItemSSR(Blog.page, { slug: slug, live: true, topic: "blog"  });
	}

	static parseDataSSR(data) {
		var s = vars.getDefaultState();
		s.item.get = {
			requested: true,
			loading: false,
			response: data
		};
		return s;
	}
	/* SSR end */



	componentDidMount() {

		if (!this.props.data.requested || !this.props.data.response || (this.props.match.params.slug != this.props.data.response.slug)) {
			this.props.getBlog({ slug: this.props.match.params.slug, live: true, topic: "blog"  });
		}
		

		this.props.getRelatedArticles({ slug: this.props.match.params.slug, topic: "blog" });

		
	}



	componentWillUnmount() {
		this.props.reset();
	}

	render() {
		var { response: data, requested, loading } = this.props.data;
		var featured = this.props.featured;

		if (!requested || !data) {
			return <Loader />
		} else if (loading) {
			return <Loader />
		} else if (requested && data && data.error) {
			return <NotFound />
		}



		// Meta
		var metaTitle = data.title;// + " · 4 Day Week Jobs";
		var metaDesc = data.sub_title;
		var metaImg = data.header_image;
		var metaUrl = "https://4dayweek.io/blog/" + data.slug;

		var content = data.content.trim();
		var delim = "\n#";
		var temp = content.split(delim);
		var numSections = temp.length;


		var cutoff1 = parseInt(Math.max(((numSections) * 0.45) - 1, 0));
		var cutoff2 = parseInt(Math.max(((numSections) * 0.85) - 1, 0));

		if (cutoff2 >= numSections) {
			cutoff2 = numSections;
		}

		var topContent = temp.slice(0, cutoff1).join(delim)
		var middleContent = delim + temp.slice(cutoff1, cutoff2).join(delim)
		var bottomContent = delim + temp.slice(cutoff2, numSections).join(delim)



		// Convert unix to date in format 2019-10-21T00:00:00-0600
		var publishDate = TimeTools.EpochToDateString(data.posted);
		var updateDate = TimeTools.EpochToDateString(data.updated);



		return (
			<div className="main-container medium-width article-page">
				<HelmetMeta title={metaTitle} desc={metaDesc} img={metaImg} url={metaUrl} date={publishDate}/>




				<section className="row article-wrapper">


					<SubscriberModalWrapper showForDelay={true} showForMouse={true} />


					<div className='article-content-wrapper'>
						<div className="row article-title">


							<h1>{data.title}</h1>
							<h2 className="page-subtitle">{data.sub_title}</h2>
							<div className="article-info">
								<h3>{data.read_time}</h3>
								<h3>{TimeTools.FullDate(data.posted * 1000)}</h3>
								<h3><Link to="/blog" className="tag margin-left">Blog</Link></h3>
							</div>



							{/*<div style={{ backgroundImage: `url(${data.header_image})` }} className="article-image" />*/}
							<img src={data.header_image} className="article-image" height="450" width="750" alt={data.title} fetchpriority="high"/>


							<div className="article-content" height="4000">

								<MarkdownRenderer keepInternal={true} doFollow={true} className="article-content-inner">{topContent}</MarkdownRenderer>


								<div className="article-top">
									<SubscriberInlineWrapper className="subscriber-green"/>

								</div>


								<MarkdownRenderer keepInternal={true} doFollow={true} className="article-content-inner">{middleContent}</MarkdownRenderer>

								{/*}		<div className="article-bottom">

							</div>*/}
								<MarkdownRenderer keepInternal={true} doFollow={true} className="article-content-inner">{bottomContent}</MarkdownRenderer>

								<div className="article-bottom">
									<SubscriberInlineWrapper className="subscriber-green"/>
									{/*<div className="row align-center article-cta">
								<Link to='/jobs' className="btn btn-default btn-huge cta margin-top margin-bottom">View 4 day week jobs...</Link>
						</div>*/}

								</div>
							</div>
						</div>
					</div>


					<div className="article-sidebar-wrapper ">
						<ArticlesSideBar {...this.props} onClickAdvert={(e) => this.onClickAdvert(e)} topic="blog" />
					</div>

				</section>





			</div >
		);
	}

}

const mapStateToProps = (state) => {
	return {
		data: state.item.get,
        user: state.item.user,
		related: state.list.articles_related,
	};
};


const mapDispatchToProps = (dispatch, ownProps) => ({

	getBlog: (data) => {
		dispatch(itemActions.getItem(Blog.page, data));
	},

	

	

	getRelatedArticles: (params) => {
		dispatch(listActions.fetchItems("articles", "related", params));
	},


	reset: (data) => {
		dispatch(itemActions.resetItem());
	},
	newClick: (data) => {
		dispatch(itemActions.newItem("clicks", data));
	},
});

Blog = connect(mapStateToProps, mapDispatchToProps)(Blog);

export default Blog;