import * as actions from './actions';

/*
Used to create and test items
*/


export function getDefaultItemsState() {
	return {
		new: {
			requested: false,
			loading: false,
			response: {}
		},
		get: {
			requested: false,
			loading: false,
			response: {}
		},
		set: {
			requested: false,
			loading: false,
			response: {}
		},
		advert: {
			requested: false,
			loading: false,
			response: {}
		},
		user: {
			requested: false,
			loading: false,
			response: {}
		},
		auth: {
			requested: false,
			loading: false,
			response: {}
		},
		critique: {
			requested: false,
			loading: false,
			response: {}
		},
		dashboard_company: {
			requested: false,
			loading: false,
			response: {}
		},
	};
}



const items = (state, action) => {
	var temp = Object.assign({}, state);
	let curData = {}

	switch (action.type) {

		// New
		case actions.NEW_ITEM_REQUEST:
			curData = {
				requested: false,
				loading: true,
				response: {}
			};
			if (action.save) {
				temp[action.save] = curData
			} else {
				temp.new = curData;
			}
			return temp;

		case actions.NEW_ITEM_RESPONSE:
			curData = {
				requested: true,
				loading: false,
				response: action.response
			};

			if (action.save) {
				temp[action.save] = curData
			} else {
				temp.new = curData;
			}
			return temp;

			// Get
		case actions.GET_ITEM_REQUEST:
			curData = {
				requested: false,
				loading: true,
				response: {}
			};

			if (action.save) {
				temp[action.save] = curData
			} else {
				temp.get = curData;
			}
			return temp;

		case actions.GET_ITEM_RESPONSE:

			curData = {
				requested: true,
				loading: false,
				response: action.response
			};

			if (action.save) {
				temp[action.save] = curData
			} else {
				temp.get = curData;
			}
			return temp;

			// Set
		case actions.SET_ITEM_REQUEST:
			temp.set = {
				requested: false,
				loading: true,
				response: {}
			};
			return temp;

		case actions.SET_ITEM_RESPONSE:
			let setKey = action.save ? action.save : "set";
			temp[setKey] = {
				requested: true,
				loading: false,
				response: action.response
			};
			return temp;

		case actions.RESET_ITEM_REQUEST:
			var newState = Object.assign({}, state);
			newState[action.endpoint] = {
				requested: false,
				loading: false,
				response: {}
			};

			return newState


		default:
			return state ? state : getDefaultItemsState();
	}
};

export default items;