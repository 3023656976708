import { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import gmailLogo from '../../assets/gmail.png';
import outlookLogo from '../../assets/outlook.png';
import yahooLogo from '../../assets/yahoo.png';



class AuthCheckInbox extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        let { email, message } = this.props;

        let btns = [
            { label: "Gmail", url: `https://mail.google.com/mail/u/0/#search/4dayweek.io`, img: <LazyLoadImage src={gmailLogo} alt="Gmail" /> },
            { label: "Outlook", url: `https://outlook.live.com/mail/search?q=4dayweek.io`,  img: <LazyLoadImage src={outlookLogo} alt="Outlook" /> },
            { label: "Yahoo!", url: `https://mail.yahoo.com/d/search/keyword=4dayweek.io`,  img: <LazyLoadImage src={yahooLogo} alt="Yahoo!" /> },
        ]

        let btnHtml = btns.map((btn) => {
            return (
                <a href={btn.url} target="_blank" className="btn btn-light btn-lg" rel="noopener noreferrer">{btn.img} {btn.label}</a>
            )
        });

        return (
            <div className="row auth-check-inbox">

                <h2>Check your inbox to finish login 👈</h2>
                <p className=''>We emailed a login link to <strong>{email}</strong></p>
                <div className="inbox-links">
                    <p>p.s. use any of these services? Click to quickly go to your inbox...</p>
                    {btnHtml}
                </div>
            </div>

        );
    }
}

export default AuthCheckInbox;